import styled from "styled-components"
import { mediaQueries } from "@styles"

export const Section = styled.header`
  position: relative;
  z-index: 1;
  overflow: hidden;

  h1 {
    min-height: 86px;
  }
  height: 582px;
  @media ${mediaQueries.medium} {
    height: 920px;
  }

  > div {
    clip-path: url(#solution-header-mobile);
    background-color: ${props => props.theme.colors.primaryLight};
    height: 100%;

    @media ${mediaQueries.medium} {
      position: absolute;
      z-index: -1;

      padding: 420px 0 0;
      top: -420px;

      left: 50%;
      transform: translateX(-50%);
      clip-path: url(#solution-header);

      height: 1308px;
      width: 1995px;
    }
  }
`

export const Content = styled.div`
  padding: 104px 0 0;
  @media ${mediaQueries.medium} {
    padding: 190px 0 0;
  }
`

const gutter = 8

export const Block = styled.div`
  flex: 0 0 auto;
  padding-left: ${gutter}px;
  width: 200px;

  @media ${mediaQueries.medium} {
    width: 415px;
  }
`

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% + ${gutter}px);
  margin-left: -${gutter}px;
`
