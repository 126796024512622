import styled, { keyframes } from "styled-components"

import {
  mediaQueries,
  Filters,
  P0,
  P1,
  Container,
  Image as ImageWrapper,
  variables,
  T2,
  Grid,
} from "@styles"

/**
 * Link
 */
const Platform = styled.a`
  font-size: 14px;
  line-height: 16px;
  font-family: "stolz";
  color: ${props => props.theme.colors.grey100};
  padding-bottom: 2px;
  border-bottom: 2px solid;
  margin-left: 8px;
  display: inline-flex;
  &:hover {
    border: none;
  }
`

/**
 * Media Player
 */
const MediaPlayer = styled.div`
  display: flex;
  align-items: center;

  ${props =>
    props.marginBottom
      ? `
    margin-bottom: ${props.marginBottom};
  `
      : ""}

  > div:nth-child(2),
  > div:last-child {
    flex: 0 0 auto;
  }

  > div:nth-child(3) {
    flex: 1 1 auto;
    margin: 0 12px;
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;

  border-width: 2px;
  border-color: #fff transparent;
  border-radius: 100%;
  border-style: solid;

  left: 11px;
  top: 11px;
  animation: ${rotate} 2s linear infinite;

  @media ${mediaQueries.medium} {
    ${props =>
      props.featured
        ? ` width: 24px; height: 24px; top: 16px; left: 16px;`
        : ""}
  }
`

const Track = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;

  height: 10px;
  background-color: ${variables.lightBlue};
  border-radius: 5px;

  .progress {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    border-radius: 6px;

    width: 0;
    height: 100%;
    top: 0;
    left: 0;

    background-color: ${variables.blue};
  }
`

const Play = styled.button`
  position: relative;
  display: block;
  cursor: pointer;
  width: 38px;
  height: 38px;
  border-radius: 16px;
  background-color: ${variables.blue};
  border: none;
  outline: none;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;

    background-position: center;
    transform: translate(-50%, -50%);

    ${props =>
      props.active
        ? `
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI1LjUgNUgyMWExIDEgMCAwMC0xIDF2MjBhMSAxIDAgMDAxIDFoNC41YTEgMSAwIDAwMS0xVjZhMSAxIDAgMDAtMS0xek0xMiA1SDcuNWExIDEgMCAwMC0xIDF2MjBhMSAxIDAgMDAxIDFIMTJhMSAxIDAgMDAxLTFWNmExIDEgMCAwMC0xLTF6IiBmaWxsPSIjZmZmIi8+PC9zdmc+);
      background-size: 20px;
        `
        : `
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyLjIzMyA2LjI2OUwyLjE2MyAxLjA2MmExLjI3MyAxLjI3MyAwIDAwLS41NjItLjEzOSAxLjI4MyAxLjI4MyAwIDAwLS41NjguMTIxIDEuMDU1IDEuMDU1IDAgMDAtLjQxOS4zNDcuODM4LjgzOCAwIDAwLS4xNTMuNDc5djEwLjQxNGEuODQuODQgMCAwMC4xNTQuNDc5Yy4xLjE0NS4yNDUuMjY1LjQxOC4zNDcuMTczLjA4Mi4zNy4xMjQuNTY4LjEyLjE5OS0uMDAzLjM5My0uMDUuNTYzLS4xMzhsMTAuMDctNS4yMDdhMS4wNCAxLjA0IDAgMDAuMzkyLS4zNDUuODM2LjgzNiAwIDAwLjE0My0uNDYzLjgzNi44MzYgMCAwMC0uMTQzLS40NjMgMS4wNCAxLjA0IDAgMDAtLjM5My0uMzQ1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
      background-size: 12px;
    `}
  }

  @media ${mediaQueries.medium} {
    ${props =>
      props.featured
        ? `
      width: 56px;
      height: 56px;
    `
        : ""}

    span {
      ${props =>
        props.featured
          ? props.active
            ? `background-size: 28px;`
            : `background-size: 20px;`
          : ""}
    }
  }
`

const Duration = styled.div`
  color: ${variables.grey};
`

/**
 * Grid podcast
 */

const PodcastGrid = styled(Grid)`
  @media ${mediaQueries.preMedium} {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
  }
`

const Podcast = styled.article`
  position: relative;
  background: #ffffff;
  padding: 24px;

  @media ${mediaQueries.medium} {
    transition: 0.3s box-shadow;
    &:hover {
      box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.08);
    }
  }

  @media ${mediaQueries.preMedium} {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      z-index: 1;
      left: 32px;
      right: 32px;
      height: 1px;
      background: ${props => props.theme.colors.grey25};
    }
  }
`
const RegularPodcast = styled(Podcast)`
  div + p {
    margin-top: 16px;
  }

  div:last-child > &::after {
    content: none;
  }

  @media ${mediaQueries.medium} {
    border-radius: 40px;
    padding: 32px calc(20% - 32px) ${32 + 38 + 24}px 32px;
    height: 100%;
  }
`

const RegularPodcastFooter = styled.div`
  margin-top: 24px;

  @media ${mediaQueries.medium} {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 32px 32px;
  }
`

const PodcastHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 0 16px;

  > div:first-child {
    flex: 0 0 auto;
    width: 74px;
  }

  > div:last-child {
    flex: 1 1 auto;
    padding-left: 16px;
  }

  p,
  span {
    display: block;
    font-weight: 700;
    font-family: "stolz";
  }
  span,
  h3 {
    margin: 0 0 4px;
  }

  p {
    color: ${variables.orange};
  }

  @media ${mediaQueries.medium} {
    > div:first-child {
      width: 112px;
    }
  }
`

/**
 * Featured podcast
 */
const FeaturedPodcast = styled(Podcast)`
  @media ${mediaQueries.preMedium} {
    display: none;
  }

  @media ${mediaQueries.medium} {
    border-radius: 60px;
    padding: 48px 10%;
    transform: translateY(-50%);
    margin-top: 60%;
    margin-bottom: -35%;
  }

  @media ${mediaQueries.large} {
    margin-top: 40%;
    margin-bottom: -25%;
  }

  h3 {
    margin-bottom: 16px;
    @media ${mediaQueries.preMedium} {
      padding-left: ${74 + 16}px;
    }
  }

  h4 {
    margin-bottom: 16px;
  }

  h5 {
    margin-bottom: 4px;
  }
`

const FeaturePodcastContent = styled(P0)`
  margin-bottom: 32px;
`

const AuthorFlex = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 0 8px;

  > div:first-child {
    flex: 0 0 auto;
    width: 68px;
  }

  > div:last-child {
    flex: 1 1 auto;
    padding-left: 16px;
  }
`

const AuthorImage = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;

  clip-path: url(${props => props.clip});

  &::after {
    content: "";
    padding-bottom: 100%;
    display: block;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const PodcastFlex = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: calc(100% + 24px);
  margin-left: -24px;
  > div {
    padding-left: 24px;
  }
`

const PodcastFooter = styled(PodcastFlex)`
  margin-top: 48px;
`

const FooterTitle = styled(P1)`
  text-transform: uppercase;
  font-weight: 700;
`

const PodcastImage = styled(ImageWrapper)`
  width: 74px;

  img {
    border-radius: 28px;
  }

  &::after {
    padding-bottom: 100%;
  }

  @media ${mediaQueries.medium} {
    width: 112px;
  }
`

const FeaturedPodcastImage = styled(PodcastImage)`
  @media ${mediaQueries.preMedium} {
    float: left;
    margin-top: 8px;
  }

  @media ${mediaQueries.medium} {
    width: 160px;
    margin-top: -96px;
    margin-bottom: 24px;

    img {
      border-radius: 40px;
    }
  }
`

/**
 * Section
 */

const Section = styled.section`
  padding: 80px 0 154px;
  h2 {
    text-align: center;
    margin-bottom: 40px;
  }
`

const Background = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;

  background-color: ${props => props.theme.colors.skinMedium};
  border-radius: 54px;

  height: 100%;
`

const Wrapper = styled.div`
  ${props => (props.padding ? "padding: 0 0 224px;" : "")}

  margin: 0 auto;
  @media ${mediaQueries.large} {
    width: ${(100 * 10) / 12}%;
  }
`

const ThisFilters = styled(Filters)`
  margin-bottom: 48px;
  text-align: center;
  @media ${mediaQueries.medium} {
    margin-bottom: 64px;
  }
`

const ThisContainer = styled(Container)`
  @media ${mediaQueries.preMedium} {
    padding: 0;
    width: 100%;
  }
`

const Title = styled(T2)`
  text-align: center;
  @media ${mediaQueries.medium} {
    font-size: 58px;
  }

  span {
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 4px;
      left: -12px;
      width: 48px;
      height: 48px;

      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjYiIGhlaWdodD0iNjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yOS4yODIgNTAuMjI4Yy0xLjExIDEuMDQ4LTIuMjc5IDEuOTkxLTMuMjU0IDMuMTAzLTIuMzc3IDIuNzExLTIuMjk0IDYuNjkuMDk0IDkuMzEyIDIuMzYxIDIuNTkzIDYuNTY5IDMuMjM2IDkuMjk1Ljk0NCA5LjM3My03Ljg4MiAxOC42MzctMTUuODkyIDI3Ljg2Mi0yMy45NDUgMi4yMTQtMS45MzMgMi42OTMtNS41NzYgMS40NDktNy45MS0xLjYyLTMuMDM3LTQuNTM0LTQuNDgyLTguMDMtMy44MjMtMi40NjQuNDY0LTQuODkzIDEuMTEtNy41MDggMS43MTQuMTYzLTEuNzQ1IDEuNDMtMi4xMzQgMi4xODMtMi45MjUgMi4zNzItMi40OTIgMy4wMzctNS44NzggMS42MTgtOC40NzgtMS43MDctMy4xMjUtNC4zNDMtNC4zMi04LjEwNS0zLjYxLTEuMjU3LjIzNi0yLjQ3OC42NTItMy43MzUuODg2LTEuMjI3LjIyOC0yLjM4My45MTktMy45OTguNTkyLjUxNy0xLjMxMyAxLjY5NS0xLjg3NCAyLjUyMy0yLjcwNSAyLjk2LTIuOTcyIDMuMTkyLTYuOTkuNTUzLTEwLjAwMS0yLjUxMi0yLjg2OC02LjYzOS0zLjM4Ni05Ljc2LS43NjktOS4wNTQgNy41OTctMTguMDEyIDE1LjMxMi0yNi45MDcgMjMuMDk0Qy42NjggMjguMjM4LjIyNyAzMS42NiAxLjkyOSAzNC42NDljMS42NzQgMi45NDQgNC43NDcgNC4wOTQgOC41NzkgMy4yMiAxLjQ1LS4zMyAyLjktLjY2OCA0LjM1OS0uOTQ3LjI3OC0uMDUzLjYwNS4xNDguOTE3LjIzNC0uMDY5IDEuMTM1LS43MDUgMS45Mi0xLjM2NCAyLjc0OC0yLjA1MiAyLjU3Ny0yLjQ4NSA1LjM3OC0uNzE5IDguMjY2IDEuODA4IDIuOTU2IDQuNTE0IDMuOTA1IDguMzM1IDMuMDQgMS44NzMtLjQyNiAzLjcyNy0uOTMyIDUuNTk1LTEuMzc4LjU3Mi0uMTM2IDEuMjMtLjQ2MyAxLjY1MS4zOTZ6IiBmaWxsPSIjRkZBQURGIi8+PC9zdmc+);
      background-repeat: no-repeat;
      background-size: 100%;

      @media ${mediaQueries.medium} {
        top: 12px;
        left: -24px;
        width: 66px;
        height: 66px;
      }
    }
  }
`

export {
  Section,
  Background,
  Wrapper,
  ThisFilters,
  FeaturedPodcast,
  AuthorFlex,
  AuthorImage,
  PodcastFlex,
  PodcastFooter,
  FooterTitle,
  ThisContainer,
  FeaturedPodcastImage,
  PodcastImage,
  RegularPodcast,
  PodcastHeader,
  MediaPlayer,
  Track,
  Play,
  Loader,
  PodcastGrid,
  RegularPodcastFooter,
  FeaturePodcastContent,
  Duration,
  Title,
  Platform,
}
