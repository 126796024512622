import styled from "styled-components"
import { mediaQueries } from "@styles"

export const Section = styled.section`
  padding: 48px 0;
  @media ${mediaQueries.medium} {
    padding: 80px 0;
  }
`

export const Image = styled.div`
  @media ${mediaQueries.preMedium} {
    display: none;
  }
`
