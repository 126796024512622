import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Stores from "@components/stores"

import { Image } from "./style"
import { GridCentered, GridItem, T6, P00, P0 } from "@styles"

const Left = props => {
  const primary = props.primary || {}
  const items = props.items || []
  const title = primary.title || ""
  const subtitle = primary.subtitle || ""
  const content = primary.structContent || primary.content || {}
  const image = getImage(primary.image)

  return (
    <GridCentered>
      <GridItem col={{ md: 5, lg: 5 }}>
        <Image>
          <GatsbyImage
            image={image}
            sizes={`50vw, (min-width: 1200px) 600px`}
            alt={title}
          />
        </Image>
      </GridItem>
      <GridItem col={{ md: 6, lg: 6 }} push={{ md: 1, lg: 1 }}>
        <T6 as="h2" mb={3}>
          {title}
        </T6>
        <P00
          as="div"
          mb={6}
          dangerouslySetInnerHTML={{ __html: content.html }}
        />
        <P0 as="h3" mb={3}>
          {subtitle}
        </P0>
        <Stores stores={items} />
      </GridItem>
    </GridCentered>
  )
}

export default Left

export const query = graphql`
  fragment InsurancesSectionLeft on PrismicInsurancesSectionLeft {
    id
    variation
    slice_type
    version
    primary {
      title
      subtitle
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
      structContent: content {
        html
      }
    }
    items {
      logo {
        gatsbyImageData(layout: FIXED, imgixParams: { q: 100 }, height: 52)
      }
      link {
        url
      }
    }
  }
`
