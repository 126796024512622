import styled from "styled-components"
import { mediaQueries, variables } from "@styles"

export const Wrapper = styled.footer`
  padding: 68px 0 40px;
  margin-top: auto;
  @media ${mediaQueries.footer} {
    padding: 68px 0 40px;
  }
`

export const Title = styled.h6`
  font-family: "stolz";
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 24px;

  font-weight: 700;
`

export const Submenu = styled.ul`
  li {
    margin: 0 0 16px;
    font-size: 18px;
    line-height: 22px;

    a {
      color: ${props => props.theme.colors.grey100};

      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        transform: scaleX(0);
        transform-origin: 0 0;
        transition: 250ms transform;

        height: 1px;
        background-color: ${props => props.theme.colors.grey100};
      }
      &.active::after,
      &:hover::after {
        transform: scaleX(1);
      }
    }
  }
`

export const MenuItem = styled.div`
  margin: 0 0 32px;
`

export const Stores = styled.ul`
  li {
    margin: 0 0 12px;
    a {
      cursor: pointer;
      display: block;
      transition: 0.3s transform;
    }

    a:hover {
      transform: scale(0.95);
    }
  }
`

export const Networks = styled.ul`
  display: flex;
  align-items: flex-end;
  transition: all 0.2s;
  li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;

    a {
      display: block;
      transition: all 0.2s;
    }
    a:hover {
      transform: scale(1.25);
    }
  }
`

export const Hr = styled.hr`
  height: 1px;
  margin: 0px;
  border-width: 0px;
  background-color: ${variables.grey25};
  width: 100%;
`

const gutter = 16

export const Grid = styled.div`
  width: calc(100% + ${gutter}px);
  margin-left: -${gutter}px;
  margin-bottom: 26px;

  > div {
    padding-left: ${gutter}px;
    flex: 1 1 auto;
  }

  @media ${mediaQueries.footer} {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 26px;
  }
`

export const Legal = styled.div`
  margin-top: 12px;
  .menu > li a {
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.colors.grey100};

    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      transform: scaleX(0);
      transform-origin: 0 0;
      transition: 250ms transform;

      height: 1px;
      background-color: ${props => props.theme.colors.grey100};
    }
    &.active::after,
    &:hover::after {
      transform: scaleX(1);
    }
  }

  .menu > li {
    margin: 0 0 8px;
  }

  > div:first-child {
    margin-bottom: 48px;
  }

  @media ${mediaQueries.footer} {
    display: flex;
    flex-flow: row-reverse;
    align-items: center;

    > div:last-child {
      flex: 1 1 auto;
    }

    > div:first-child {
      flex: 0 0 auto;
      margin-bottom: 0;
    }

    .menu > li {
      display: inline-block;
      vertical-align: middle;
      margin-left: 24px;
      margin-bottom: 0;
    }
  }
`

export const LangList = styled.ul`
  position: absolute;
  bottom: 40px;
  left: 0;

  border: 1px solid rgba(23, 23, 23, 0.3);
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  background-color: #fff;

  ${props => (props.active ? "" : "display: none;")}

  @media ${mediaQueries.footer} {
    bottom: 56px;
    border-radius: 20px;
    border: none;
    background-color: ${props => props.theme.colors.primaryLight};
  }
`

export const Selector = styled.div`
  position: relative;
  z-index: 1;
  cursor: pointer;
  display: inline-block;

  border: 1px solid rgba(23, 23, 23, 0.3);
  border-radius: 8px;

  > div {
    padding: 8px 56px 8px 16px;
  }

  @media ${mediaQueries.footer} {
    border-radius: 20px;
    background-color: ${props => props.theme.colors.primaryLight};
    border: none;
    color: ${props => props.theme.colors.primary};
    font-weight: 700;
    > div {
      padding: 16px 56px 16px 24px;
    }
  }

  ul li a,
  ul li span {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 16px;
    display: block;
    color: ${props => props.theme.colors.grey100};

    &:hover {
      background-color: ${props => props.theme.colors.primaryLight};
    }

    @media ${mediaQueries.footer} {
      color: ${props => props.theme.colors.primary};
      &:hover {
        background-color: ${props => props.theme.colors.primaryMedium};
        color: #fff;
      }
    }
  }
`

export const Icon = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;

  svg {
    width: 10px;
  }

  @media ${mediaQueries.footer} {
    right: 24px;
    svg {
      width: 14px;
      path {
        stroke: ${props => props.theme.colors.primary};
      }
    }
  }
`
