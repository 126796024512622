import styled, { keyframes } from "styled-components"
import { mediaQueries, variables, T3, T6 } from "@styles"

const Section = styled.section`
  padding: 0;
  z-index: 15;
  @media ${mediaQueries.medium} {
    margin-top: -130px;
  }
`

const Title = styled(T3)`
  cursor: pointer;
`

const FaqTitle = styled(T6)`
  @media ${mediaQueries.preMedium} {
    font-size: 32px;
  }
`

const Background = styled.div`
  max-width: 1584px;
  margin: 0 auto;

  padding: 0 0 100px;
  border-radius: 24px;
  z-index: 50;

  @media ${mediaQueries.medium} {
    border-radius: 50px;
    padding: 0;
  }
`

const Block = styled.div`
  padding: 0 5px;
  @media ${mediaQueries.medium} {
    background-color: #fff;
    border-radius: 20px;
    padding: 48px 88px;
  }
`

const fadeInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const Arrow = styled.span`
  display: block;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;

    top: 24px;
    right: 0;
    padding-top: 16px;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI0LjM3NSAxMS4yNUwxNSAyMC42MjUgNS42MjUgMTEuMjUiIHN0cm9rZT0iIzJEODFGRiIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);
    background-position: center;
    width: 30px;
    height: 30px;
    transition: 400ms transform;
    ${props => (props.active ? "transform: rotate(180deg);" : "")}
  }
`
const Article = styled.article`
  position: relative;

  padding: 24px 0;

  :first-child {
    padding-top: 0;
  }

  :not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.grey25};
  }

  & span:first-child {
    padding-top: 16px;
  }

  > h3 {
    padding-right: 48px;
  }

  > div {
    padding-right: 0;
  }

  p a {
    color: ${variables.darkBlue};
    text-decoration: underline;
    &:active {
      color: ${variables.darkBlue};
      text-decoration: underline;
    }
  }

  > div {
    padding-top: 16px;

    ${props => (props.active ? "" : "display: none;")}
    animation: ${fadeInRight} 0.75s;
  }
`

const Questions = styled.div`
  background-color: #fff;
  @media ${mediaQueries.medium} {
    border-radius: 30px;
  }
`

const Button = styled.div`
  margin-top: 24px;
`

export {
  Section,
  Background,
  Block,
  Article,
  Questions,
  Button,
  Arrow,
  Title,
  FaqTitle,
}
