import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
const jsonData = require("../../../data/data.json")

import { T6, ButtonBlue } from "@styles"
import {
  Section,
  ThisContainer as Container,
  Block,
  Content,
  Image,
  StoreBox,
} from "./style"
import { isMobile } from "react-device-detect"

const InsurancesOffer = ({ slice, context }) => {
  const primary = slice.primary || {}
  const title = primary.title || ""
  const content = primary.structContent || primary.content || {}
  const link = primary.link || {}
  const image = getImage(primary.image)
  const variation = slice.variation

  const currentDate = new Date()

  let code = null
  const tag = context?.tag

  const findActiveCode = (data, currentDate) => {
    if (data && data.length) {
      data.forEach(item => {
        const start = new Date(item.validity_start_date)
        const end = new Date(item.validity_end_date)

        if (currentDate >= start && currentDate <= end) {
          return (code = item.code)
        }
      })
    }

    return null
  }

  findActiveCode(jsonData[tag], currentDate)

  return (
    <Section>
      <Container>
        <Block>
          {image && (
            <Image>
              <GatsbyImage
                image={image}
                alt={title}
                sizes={`162px, (min-width: 769px) 240px`}
              />
            </Image>
          )}
          <T6 as="h2" mb={3} mt={image ? 0 : 6}>
            {title}
          </T6>

          <Content>
            <PrismicRichText
              field={content.richText}
              components={{
                strong: ({ text }) => (
                  <strong>{text.replace("[CODE]", code)}</strong>
                ),
                listItem: ({ children }) => (
                  <li>
                    <span>{children}</span>
                  </li>
                ),
              }}
            />
          </Content>

          {variation === "store" ? (
            !isMobile && (
              <StoreBox>
                <a
                  href={primary.app_store_link.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={primary.app_store.url} width="160" alt="store" />
                </a>
                <a
                  href={primary.google_play_link.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={primary.google_play.url} width="160" alt="store" />
                </a>
              </StoreBox>
            )
          ) : (
            <ButtonBlue
              as="a"
              href={link.url}
              picto="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIuNSA1LjM3M2gxNXYxMC42MjVhLjYyNS42MjUgMCAwMS0uNjI1LjYyNUgzLjEyNWEuNjI1LjYyNSAwIDAxLS42MjUtLjYyNVY1LjM3M3oiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMi41IDUuMzczaDE1TDEwIDEyLjI0OCAyLjUgNS4zNzN6IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PHBhdGggZD0iTTE3LjUgNS4zNzNMMTAgMTIuMjQ4IDIuNSA1LjM3MyIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg=="
            >
              <span>{primary.button}</span>
            </ButtonBlue>
          )}
          {primary.appendix && (
            <small style={{ display: "block", marginTop: 16, fontSize: 14 }}>
              {primary.appendix}
            </small>
          )}
        </Block>
      </Container>
    </Section>
  )
}

export default InsurancesOffer

export const query = graphql`
  fragment InsurancesOffer on PrismicInsurancesOfferDefault {
    id
    variation
    slice_type
    version
    primary {
      button
      title
      structContent: content {
        html
      }
      link {
        url
        target
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
    }
  }

  fragment InsurancesOfferStore on PrismicInsurancesOfferStore {
    id
    variation
    slice_type
    version
    primary {
      title
      appendix
      structContent: content {
        richText
      }
      link {
        url
        target
      }
      google_play_link {
        url
        target
      }
      app_store_link {
        url
        target
      }
      app_store {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
        url
      }
      google_play {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
        url
      }
    }
  }
`
