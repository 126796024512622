import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Tag } from "@styles"

import { Author, HeaderLeft, Infos, DateText } from "./style"

const Header = ({ tag, image, date, name, job, lang }) => {
  const gatsbyImg = getImage(image)

  const formattedDate = new Intl.DateTimeFormat(lang, {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(new Date(date))

  return (
    <Infos>
      <HeaderLeft style={{ display: "flex", alignItems: "center" }}>
        <Tag>{tag}</Tag>
        <DateText>{formattedDate}</DateText>
      </HeaderLeft>
      <Author>
        <div>
          <GatsbyImage image={gatsbyImg} alt={name} sizes={`36px`} />
        </div>
        <div>
          <p className="name">{name}</p>
          <p className="job">{job}</p>
        </div>
      </Author>
    </Infos>
  )
}

export default Header
