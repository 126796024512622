export default {
  orange: "#FF7D4F",
  lightOrange: "#FFF3EF",
  darkOrange: "#EB6333",
  lightVelvet: "#EEEEFF",
  mediumOrange: "#FF885D",
  lightYellow: "#FFF8E2",
  yellow: "#FFDC6D",
  grey: "#9B9B9B",
  grey25: "#9B9B9B",
  lightBlue: "#EAF2FF",
  blue: "#2D81FF",
  darkBlue: "#1462D6",
  white: "#FFF",
  siteWidth: "1220px",
  trame: "1440px",
  gutter: "24px",
}
