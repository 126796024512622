import styled from "styled-components"

import { T2, P0, variables, mediaQueries, Filters } from "@styles"

const gutter = "24px"

const ids = [
  "memberN1",
  "memberN2",
  "memberN3",
  "memberN4",
  "memberN5",
  "memberN6",
  "memberN7",
  "memberN8",
]

const Image = styled.div`
  text-align: center;
  transform: translateY(-50%);
  margin-bottom: -40px;

  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
  @media ${mediaQueries.medium} {
    max-width: 100%;
  }
`

const Title = styled(T2)`
  text-align: center;
  margin-bottom: 16px;

  span {
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 4px;
      left: -12px;
      width: 48px;
      height: 48px;

      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjYiIGhlaWdodD0iNjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yOS4yODIgNTAuMjI4Yy0xLjExIDEuMDQ4LTIuMjc5IDEuOTkxLTMuMjU0IDMuMTAzLTIuMzc3IDIuNzExLTIuMjk0IDYuNjkuMDk0IDkuMzEyIDIuMzYxIDIuNTkzIDYuNTY5IDMuMjM2IDkuMjk1Ljk0NCA5LjM3My03Ljg4MiAxOC42MzctMTUuODkyIDI3Ljg2Mi0yMy45NDUgMi4yMTQtMS45MzMgMi42OTMtNS41NzYgMS40NDktNy45MS0xLjYyLTMuMDM3LTQuNTM0LTQuNDgyLTguMDMtMy44MjMtMi40NjQuNDY0LTQuODkzIDEuMTEtNy41MDggMS43MTQuMTYzLTEuNzQ1IDEuNDMtMi4xMzQgMi4xODMtMi45MjUgMi4zNzItMi40OTIgMy4wMzctNS44NzggMS42MTgtOC40NzgtMS43MDctMy4xMjUtNC4zNDMtNC4zMi04LjEwNS0zLjYxLTEuMjU3LjIzNi0yLjQ3OC42NTItMy43MzUuODg2LTEuMjI3LjIyOC0yLjM4My45MTktMy45OTguNTkyLjUxNy0xLjMxMyAxLjY5NS0xLjg3NCAyLjUyMy0yLjcwNSAyLjk2LTIuOTcyIDMuMTkyLTYuOTkuNTUzLTEwLjAwMS0yLjUxMi0yLjg2OC02LjYzOS0zLjM4Ni05Ljc2LS43NjktOS4wNTQgNy41OTctMTguMDEyIDE1LjMxMi0yNi45MDcgMjMuMDk0Qy42NjggMjguMjM4LjIyNyAzMS42NiAxLjkyOSAzNC42NDljMS42NzQgMi45NDQgNC43NDcgNC4wOTQgOC41NzkgMy4yMiAxLjQ1LS4zMyAyLjktLjY2OCA0LjM1OS0uOTQ3LjI3OC0uMDUzLjYwNS4xNDguOTE3LjIzNC0uMDY5IDEuMTM1LS43MDUgMS45Mi0xLjM2NCAyLjc0OC0yLjA1MiAyLjU3Ny0yLjQ4NSA1LjM3OC0uNzE5IDguMjY2IDEuODA4IDIuOTU2IDQuNTE0IDMuOTA1IDguMzM1IDMuMDQgMS44NzMtLjQyNiAzLjcyNy0uOTMyIDUuNTk1LTEuMzc4LjU3Mi0uMTM2IDEuMjMtLjQ2MyAxLjY1MS4zOTZ6IiBmaWxsPSIjRkZBQURGIi8+PC9zdmc+);
      background-repeat: no-repeat;
      background-size: 100%;

      @media ${mediaQueries.medium} {
        top: 12px;
        left: -24px;
        width: 66px;
        height: 66px;
      }
    }
  }
`

const Content = styled(P0)`
  text-align: center;
  margin-bottom: 24px;
`

const MembersWrapper = styled.div`
  max-width: 100%;
  overflow: auto;
`

const TeamMembers = styled.div`
  display: flex;
  flex-flow: row nowrap;

  width: calc(100% + ${gutter});
  margin-left: -${gutter};

  @media ${mediaQueries.medium} {
    flex-flow: row wrap;
    justify-content: center;
  }
`

const TeamMember = styled.div`
  ${props => (props.active ? `display: block;` : `display: none;`)}
  width: 180px;
  padding-left: ${gutter};
  margin-bottom: 40px;
  flex: 0 0 auto;

  h3 {
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
  }

  p {
    font-size: 12px;
  }

  ${ids
    .map(
      (id, i) => `
        &:nth-child(8n+${(i + 1) % ids.length}) > div {
          clip-path: url(#${id});
        }
      `
    )
    .join("")}
`

const MemberImage = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;

  margin-bottom: 16px;

  &::after {
    content: "";
    padding-bottom: 100%;
    display: block;
  }

  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`

const ThisFilters = styled(Filters)`
  margin-bottom: 48px;
  text-align: center;
`

const Background = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;

  background-color: ${variables.lightOrange};
  border-radius: 54px;

  height: 100%;
`

const Wrapper = styled.div`
  margin: 0 auto 215px;
  @media ${mediaQueries.medium} {
    margin: 0 auto 440px;
  }
  @media ${mediaQueries.large} {
    width: ${(100 * 10) / 12}%;
  }
`

export {
  Title,
  Content,
  TeamMembers,
  TeamMember,
  MemberImage,
  ThisFilters,
  Image,
  Background,
  Wrapper,
  MembersWrapper,
}
