import { graphql } from "gatsby"
import React from "react"
import { Section, Image } from "./style"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, T2 } from "@styles"
import { PrismicRichText } from "@prismicio/react"

const Header = ({ slice }) => {
  const primary = slice.primary
  const title = primary.title_faq || ""
  const image = getImage(primary.image)

  return (
    <Section>
      <Container>
        <PrismicRichText
          field={title.richText || []}
          components={{
            heading1: ({ children }) => <T2 as="h1">{children}</T2>,
          }}
        />

        <Image>
          <GatsbyImage image={image} alt={title} sizes={`568px`} />
        </Image>
        <svg
          width="631"
          height="765"
          viewBox="0 0 631 765"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <clipPath
              clipPathUnits="objectBoundingBox"
              transform="scale(.0013, .0013)"
              id="faq-header"
            >
              <path d="M382.553 764.239H174.789C79.5509 760.563 3.08727 683.688 0.5 588.185V578.115C1.67055 533.301 19.4668 490.525 50.4276 458.105L50.5735 457.973L153.719 340.856C154.743 339.696 155.378 338.245 155.538 336.706C155.697 335.167 155.372 333.617 154.608 332.271L5.44897 68.9009C5.32086 68.6823 5.20568 68.4564 5.10401 68.2243C1.75779 60.8709 0.311829 52.795 0.898814 44.7374C1.4858 36.6799 4.08691 28.8988 8.46351 22.108C12.8401 15.3173 18.852 9.73442 25.9476 5.87165C33.0433 2.00889 40.9954 -0.0100702 49.0743 3.77684e-05H767.5V764.239H382.553Z" />
            </clipPath>
          </defs>
        </svg>
      </Container>
    </Section>
  )
}

export default Header

export const query = graphql`
  fragment FaqHeader on PrismicFaqHeaderDefault {
    id
    slice_type
    primary {
      title_faq {
        richText
      }
      content {
        html
      }
      image {
        gatsbyImageData(layout: FIXED, width: 568, imgixParams: { q: 100 })
      }
    }
  }
`
