import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Grid, GridItem, Container, P0, T6 } from "@styles"
import { Section, Block, Image } from "./style"

const Screen = ({ index, title, image, active, onClick, onMouseEnter }) => {
  const imageData = getImage(image)
  return (
    <GridItem col={{ md: 4, lg: 4 }}>
      <Block active={active} onClick={onClick} onMouseOver={onMouseEnter}>
        <span>{index}.</span>
        <P0 as="h3" mb={6}>
          {title}
        </P0>

        <Image active={active}>
          <GatsbyImage image={imageData} sizes={`362px`} alt={title} />
        </Image>
      </Block>
    </GridItem>
  )
}

const HomeAppScreens = ({ slice }) => {
  const timer = React.useRef(null)
  const [active, setActive] = React.useState(0)

  const primary = slice.primary || {}
  const items = slice.items || []

  const handleClick = React.useCallback(
    n => evt => {
      evt.preventDefault()
      setActive(n)

      if (timer.current) {
        clearInterval(timer.current)
        timer.current = null
      }
    },
    [setActive]
  )

  React.useEffect(() => {
    timer.current = setInterval(() => {
      setActive(n => (n + 1) % items.length)
    }, 4000)

    return () => {
      if (timer.current) {
        clearInterval(timer.current)
        timer.current = null
      }
    }
  }, [setActive, items.length])

  return (
    <Section redBg>
      <Container>
        <T6 as="h2" mb={2} mbmd={8} center pre>
          {primary.title}
        </T6>

        <Grid>
          {items.map((screen, key) => (
            <Screen
              {...screen}
              key={`screen-${key}`}
              active={active === key}
              index={key + 1}
              onClick={handleClick(key)}
              onMouseEnter={handleClick(key)}
            />
          ))}
        </Grid>
      </Container>
    </Section>
  )
}

export default HomeAppScreens

export const query = graphql`
  fragment HomeAppScreens on PrismicHomeAppScreensDefault {
    id
    slice_type
    variation
    primary {
      title
    }
    items {
      title
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
    }
  }
`
