import styled from "styled-components"
import { mediaQueries } from "@styles"

const gutter = "24px"

const Grid = styled.div`
  display: flex;
  flex-flow: row wrap;

  width: calc(100% + ${gutter});
  margin-left: -${gutter};

  ${props =>
    props.reverse
      ? `@media ${mediaQueries.medium} {flex-flow: row-reverse wrap;}`
      : ""}
`

const GridItem = styled.div`
  padding-left: ${gutter};
  flex: 1 1 auto;
  width: ${({ width }) => (width ? `${width}%` : "100%")};
  max-width: ${({ width }) => (width ? `${width}%` : "100%")};
  ${({ mb }) => (mb ? `margin-bottom: ${mb * 8}px;` : "")}
  ${({ mbmd }) =>
    mbmd ? `@media ${mediaQueries.medium} {margin-bottom: ${mbmd * 8}px;}` : ""}

  ${({ col = {}, push = {}, pull = {} }) => {
    const colQueries = Object.keys(col).map(key =>
      mediaQueries[key]
        ? `
        @media ${mediaQueries[key]} {
          width: ${(100 * col[key]) / 12}%;
          max-width: ${(100 * col[key]) / 12}%;
        }
      `
        : ""
    )

    const pushQueries = Object.keys(push).map(key =>
      mediaQueries[key]
        ? `
        @media ${mediaQueries[key]} {
          margin-left: ${(100 * push[key]) / 12}%;
        }
      `
        : ""
    )

    const pullQueries = Object.keys(pull).map(key =>
      mediaQueries[key]
        ? `
          @media ${mediaQueries[key]} {
            margin-left: -${(100 * pull[key]) / 12}%;
          }
        `
        : ""
    )

    return `
      ${colQueries.join("")}
      ${pushQueries.join("")}
      ${pullQueries.join("")}
    `
  }}
`

const GridCentered = styled(Grid)`
  align-items: center;
`

const GridJustified = styled(Grid)`
  justify-content: center;
`

export { Grid, GridCentered, GridItem, GridJustified }
