import React from "react"
import { graphql } from "gatsby"

import { Container, P3, ButtonBlueBig, T6, P00 } from "../../../styles"
import { Section, BoxContainer, Wrapper, Grid, Title } from "./style"
import { AppLink } from "@components/CustomLink"
import { PrismicRichText } from "@prismicio/react"

const Box = ({ content_link_box, link_box, title_box, icon_box }) => {
  return (
    <BoxContainer>
      <img src={icon_box.url} alt={icon_box.alt} />
      <P3 as="h3" mb={2} mt={3}>
        {title_box}
      </P3>
      <AppLink link={link_box}>
        <ButtonBlueBig>{content_link_box}</ButtonBlueBig>
      </AppLink>
    </BoxContainer>
  )
}

const BoxIcon = ({ slice }) => {
  const items = slice.items || []
  const primary = slice.primary || []

  return (
    <Section>
      <Container>
        <Wrapper>
          <Grid>
            <Title>
              <T6 as="h2" mb={2}>
                {primary.title_box_icon}
              </T6>
              <PrismicRichText
                field={primary.content_box_icon.richText}
                components={{
                  paragraph: ({ children }) => (
                    <div style={{ maxWidth: 880, marginBottom: 40 }}>
                      <P00>{children}</P00>
                    </div>
                  ),
                }}
              />
            </Title>
            {items.map((feature, key) => (
              <Box key={`feature-${key}`} {...feature} />
            ))}
          </Grid>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default BoxIcon

export const query = graphql`
  fragment BoxIcon on PrismicBoxIconDefault {
    id
    slice_type
    variation
    primary {
      title_box_icon
      content_box_icon {
        richText
      }
    }
    items {
      icon_box {
        alt
        url
      }
      title_box
      link_box {
        url
        target
        link_type
      }
      content_link_box
    }
  }
`
