import { mediaQueries } from "@styles"
import styled from "styled-components"
import { variables } from "../../../styles"

const Section = styled.section`
  padding: 0;
  z-index: 15;
  @media ${mediaQueries.medium} {
    margin-top: -130px;
  }
`
const Block = styled.div`
  text-align: left;
  padding: 0 5px;
  max-width: 1204px;
  margin: 100px auto 48px;
  background-color: ${variables.lightVelvet};
  border-radius: 20px;
  padding: 48px 24px;
  @media ${mediaQueries.medium} {
    padding: 48px 88px;
    text-align: center;
  }
`

export { Section, Block }
