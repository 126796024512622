import React from "react"
import AppStores from "./app-stores"
import LangSelector from "./lang-selector"
import RegularLink from "./regular-link"
import RegularMenu from "./regular-menu"
import Newsletter from "./newsletter"
import SocialNetworks from "./social-networks"

import { SliceZone } from "@prismicio/react"

import { Container, T4 } from "@styles"
import { Grid, Legal, Wrapper } from "./style"

function Footer({ data = {}, languages }) {
  const legal = data.body || []
  const slices = data.body1 || []

  const year = new Date().getFullYear()

  return (
    <Wrapper>
      <Container>
        <Grid>
          <SliceZone
            slices={slices}
            defaultComponent={() => null}
            components={{
              regular_menu: RegularMenu,
              app_stores: AppStores,
              newsletter: Newsletter,
            }}
          />
        </Grid>
        <div>
          <SliceZone
            slices={slices}
            defaultComponent={() => null}
            components={{
              social_networks: SocialNetworks,
            }}
          />
        </div>
        <Legal>
          <div>
            <ul className="menu">
              <SliceZone
                slices={legal}
                defaultComponent={() => null}
                components={{
                  regular_link: RegularLink,
                }}
              />
              {languages?.alternate_languages &&
                languages?.alternate_languages.length > 0 && (
                  <li>
                    <LangSelector languages={languages} />
                  </li>
                )}
            </ul>
          </div>
          <div>
            <T4 as="p">
              &copy; Goodmed 2022 - {year}. {data.copyright}{" "}
            </T4>
          </div>
        </Legal>
      </Container>
    </Wrapper>
  )
}

export default Footer
