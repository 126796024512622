import React from "react"
import { graphql } from "gatsby"
import { useSwipeable } from "react-swipeable"
import Testimonial from "./testimonial"

import { Container, T6 } from "@styles"
import {
  Section,
  Slider,
  Track,
  CusomtDots as Dots,
  ArrowNext,
  ArrowPrev,
  CustomControls as Controls,
} from "./style"

const HomeTestimonialsSlider = ({ slice }) => {
  const primary = slice.primary || {}
  const items = slice.items || []

  const [active, setActive] = React.useState(0)

  const goToSlide = React.useCallback(
    n => evt => {
      evt.preventDefault()
      setActive(n)
    },
    [setActive]
  )

  const goToNext = React.useCallback(
    evt => {
      evt && evt.preventDefault()
      setActive(n => n + 1)
    },
    [setActive]
  )

  const goToPrev = React.useCallback(
    evt => {
      evt && evt.preventDefault()
      setActive(n => n - 1)
    },
    [setActive]
  )

  const handlers = useSwipeable({
    onSwipedLeft: () => goToNext(),
    onSwipedRight: () => goToPrev(),
  })

  return (
    <Section>
      <Container>
        <T6 as="h2" center mb={9} mbmd={13}>
          {primary.title}
        </T6>

        <Slider {...handlers}>
          <Track active={active} length={items.length}>
            {items.map((testimonial, key) => (
              <div key={`testimonial-${key}`}>
                <Testimonial {...testimonial} />
              </div>
            ))}
          </Track>
          <Controls>
            <ArrowPrev onClick={goToPrev} active={active > 0}>
              prev
            </ArrowPrev>
            <ArrowNext
              onClick={goToNext}
              active={active < items.length - 2 && items.length > 2}
            >
              next
            </ArrowNext>
          </Controls>
        </Slider>

        <Dots>
          {items.map((_, key) => (
            <li key={`dot-${key}`} className={active === key ? "active" : ""}>
              <button onClick={goToSlide(key)}>{key}</button>
            </li>
          ))}
        </Dots>
      </Container>
    </Section>
  )
}

export default HomeTestimonialsSlider

export const query = graphql`
  fragment HomeTestimonialsSlider on PrismicHomeTestimonialsSliderDefault {
    id
    slice_type
    variation
    items {
      title
      since
      note
      name
      date(formatString: "DD/MM/YYYY")
      content_testimonial {
        html
      }
      icon {
        gatsbyImageData(layout: FIXED, width: 80)
      }
    }
    primary {
      title
    }
  }
`
