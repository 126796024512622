import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Stores from "@components/stores"

import { Container, GridCentered, GridItem, T6, P3 } from "@styles"
import { Section, Image, ListItem, Background } from "./style"

const PremiumHow = ({ slice }) => {
  const primary = slice.primary || {}
  const image = getImage(primary.image)
  const content = primary.contenthow || {}
  const richText = content.richText
  const items = slice.items || []

  return (
    <Section>
      <Background>
        <Container>
          <GridCentered>
            <GridItem col={{ lg: 6, md: 6 }}>
              <T6 as="h2" mb={4} mbmd={8}>
                {primary.title}
              </T6>
              <ul>
                {richText &&
                  richText.map(({ text }, index) => (
                    <ListItem key={`list-${index}`}>
                      <span>{index + 1}</span>
                      <P3 as="h3">{text}</P3>
                      {index === 0 && (
                        <div className="stores">
                          <Stores stores={items} />
                        </div>
                      )}
                    </ListItem>
                  ))}
              </ul>
            </GridItem>
            <GridItem col={{ lg: 6, md: 6 }}>
              <Image>
                <GatsbyImage
                  image={image}
                  alt={primary.title}
                  sizes={`50vw, (min-width: 769px) 500px`}
                />
              </Image>
            </GridItem>
          </GridCentered>
        </Container>
      </Background>
      <svg width="0" height="0">
        <defs>
          <clipPath
            clipPathUnits="objectBoundingBox"
            transform="scale(.001965)"
            id="premium-how"
          >
            <path
              d="M457.614 246.596a4.924 4.924 0 00-.813 2.819 5.117 5.117 0 005.143 5.063h14.139a30.928 30.928 0 0130.929 30.926c0 .566 0 1.122-.053 1.679a193.546 193.546 0 01-.69 5.054C488.136 414.87 382.395 509 254.544 509a256.466 256.466 0 01-31.75-1.979 261.619 261.619 0 01-30.593-5.717A254.932 254.932 0 0132.175 378.341c-31.539-56.453-15.323-152.987 36.69-191.3l9.791-7.157.363-.274a5.054 5.054 0 001.13-6.424 5.454 5.454 0 00-4.736-2.58H31.715A31.715 31.715 0 010 138.92V31.668A31.711 31.711 0 0131.715 0h98.089a257.614 257.614 0 0162.344 7.696A250.395 250.395 0 01254.5 32.58a259.02 259.02 0 0140.649 28.39 4.95 4.95 0 008.183-3.747V31.598A31.717 31.717 0 01335.012 0h142.273A31.716 31.716 0 01509 31.668v69.063a254.772 254.772 0 01-27.102 106.828c-.363.724-.725 1.537-1.176 2.262a257.528 257.528 0 01-22.834 36.227 2.553 2.553 0 01-.274.548z"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
    </Section>
  )
}

export default PremiumHow

export const query = graphql`
  fragment PremiumHow on PrismicPremiumHowDefault {
    id
    slice_type
    variation
    primary {
      title
      contenthow: content {
        html
        richText
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
    }
    items {
      logo {
        gatsbyImageData(layout: FIXED, imgixParams: { q: 100 }, height: 40)
      }
      link {
        url
      }
    }
  }
`
