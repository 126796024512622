/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import Header from "./Header"
import Footer from "./Footer"

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
`

const Layout = ({
  children,
  languages,
  backgroundColor,
  backgroundHeader,
  navigation,
}) => {
  return (
    <>
      <Header
        {...navigation}
        backgroundHeader={backgroundHeader}
        backgroundColor={backgroundColor}
      />
      <Wrapper>
        {children}
        <Footer languages={languages} {...navigation} />
      </Wrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  languages: PropTypes.node,
  navigation: PropTypes.node,
  backgroundColor: PropTypes.string,
  backgroundHeader: PropTypes.string,
}

Layout.defaultProps = {
  backgroundColor: "#fff",
}

export default Layout

export const query = graphql`
  fragment Navigation on PrismicNavigation {
    _previewable
    type
    lang
    data {
      copyright
      mobile_title
      close_menu
      home_page {
        url
        link_type
        target
      }
      body {
        ... on PrismicNavigationDataBodyRegularLink {
          ...RegularLink
        }
      }
      body1 {
        ... on PrismicNavigationDataBody1RegularMenu {
          ...RegularMenu
        }
        ... on PrismicNavigationDataBody1Newsletter {
          ...Newsletter
        }
        ... on PrismicNavigationDataBody1SocialNetworks {
          ...SocialNetworks
        }
        ... on PrismicNavigationDataBody1AppStores {
          ...AppStores
        }
      }
      body2 {
        ... on PrismicNavigationDataBody2MenuLink {
          ...MenuLink
        }
      }
    }
  }
`
