import styled from "styled-components"
import mediaQueries from "./media-helper"
import variables from "./variables"

const Button = styled.div`
  font-family: "stolz";
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;

  display: inline-block;
  height: 52px;
  line-height: 52px;

  padding: 0 24px;
  border-radius: 24px;

  > span {
    position: relative;
    padding-left: 28px;
    ${props =>
      props.picto
        ? `background-image: url(${props.picto});`
        : "background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMuMTI1IDEwaDEzLjc1TTExLjI1IDQuMzc1TDE2Ljg3NSAxMGwtNS42MjUgNS42MjUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjc1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);"}
    background-position: left center;
    background-size: 15px;
  }

  transition: 0.3s transform;
  &:hover {
    transform: scale(0.95);
  }

  @media ${mediaQueries.medium} {
    padding: 0 32px;
  }
`

const ButtonBlue = styled(Button)`
  color: #fff;
  background-color: ${variables.blue};

  &:hover {
    background-color: ${variables.darkBlue};
  }
`

const ButtonBlueBig = styled(ButtonBlue)`
  font-weight: 700;
  font-family: "inter";
  @media ${mediaQueries.medium} {
    font-size: 20px;
  }
`

export { Button, ButtonBlue, ButtonBlueBig }
