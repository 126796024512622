import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Stores from "@components/stores"

import { T6, P0 } from "@styles"
import {
  Section,
  Icon,
  Block,
  Image,
  Wrapper,
  CustomContainer as Container,
} from "./style"

const PremiumApp = ({ slice }) => {
  const primary = slice.primary || {}
  const content = primary.content || {}
  const icon = getImage(primary.icon)
  const image = getImage(primary.image)
  const items = slice.items || []

  return (
    <Section>
      <Container>
        <Wrapper>
          <Block>
            <Icon>
              <GatsbyImage
                image={icon}
                alt={primary.title}
                sizes={`132px, (min-width: 769px) 259px`}
              />
            </Icon>
            <T6 as="h2" mb={2}>
              {primary.title}
            </T6>
            <P0
              as="div"
              dangerouslySetInnerHTML={{ __html: content.html }}
              mb={6}
            />
            <Stores stores={items} />
          </Block>
          <Image>
            <GatsbyImage
              image={image}
              alt={primary.title}
              sizes={`100vw, (min-width: 769px) 65vw, (min-width: 1220px) 800px`}
            />
          </Image>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default PremiumApp

export const query = graphql`
  fragment PremiumApp on PrismicPremiumAppDefault {
    id
    slice_type
    variation
    primary {
      title
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
      icon {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
      content {
        html
      }
    }
    items {
      link {
        url
      }
      logo {
        gatsbyImageData(layout: FIXED, imgixParams: { q: 100 }, height: 52)
      }
    }
  }
`
