import React from "react"

import Article from "./article"
import Pagination from "./pagination"

import { GridItem } from "@styles"

import { ThisGrid, ThisContainer, Wrapper } from "./style"

const ArticlesGrid = ({ blogposts, pages, page, doc }) => {
  return (
    <ThisContainer id="ArticleGrid">
      <Wrapper>
        <ThisGrid>
          {blogposts.map(blogpost => (
            <GridItem key={`article-${blogpost.id}`} col={{ md: 4, lg: 4 }}>
              <Article {...blogpost} />
            </GridItem>
          ))}
        </ThisGrid>

        <Pagination page={page} pages={pages} doc={doc} />
      </Wrapper>
    </ThisContainer>
  )
}

export default ArticlesGrid
