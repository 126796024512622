import styled from "styled-components"

import { mediaQueries } from "@styles"

const Section = styled.section`
  padding: 56px 0 120px;
  @media ${mediaQueries.medium} {
    padding: 154px 0 124px;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  @media ${mediaQueries.medium} {
    width: ${(100 * 10) / 12}%;
  }
  @media ${mediaQueries.large} {
    width: ${(100 * 8) / 12}%;
  }

  h2 {
    margin-bottom: 32px;
  }
  h3 {
    margin-bottom: 16px;
  }

  p + p {
    margin-top: 20px;
  }
`

const Title = styled.h3`
  font-size: 22px;
  line-height: 25px;
  @media ${mediaQueries.medium} {
    font-size: 36px;
    line-height: 40px;
  }
`

export { Section, Wrapper, Title }
