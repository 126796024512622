import styled from "styled-components"
import { Container, mediaQueries, variables } from "@styles"

export const Section = styled.section`
  padding: 48px 0;
`

export const ThisContainer = styled(Container)`
  @media ${mediaQueries.preMedium} {
    padding: 0;
    width: 100%;
  }
`

export const StoreBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  a {
    cursor: pointer;
    display: block;
    transition: 0.3s transform;
  }

  a:hover {
    transform: scale(0.95);
  }
`

export const Block = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.08);
  border-radius: 60px;

  padding: 0 36px 64px;

  h2 {
    display: inline-flex;
  }

  @media ${mediaQueries.medium} {
    padding: 0 48px 48px;
  }
`

export const Content = styled.div`
  font-size: 20px;
  line-height: 25px;
  margin: 0 0 48px;

  ul li strong {
    color: ${variables.blue};
  }

  ul li {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    font-family: stolz;

    position: relative;
    padding-left: ${30 + 12}px;
    min-height: 30px;
    display: flex;
    align-items: center;
    margin: 0 0 16px;

    @media ${mediaQueries.medium} {
      margin: 0 0 16px;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: 30px;
      height: 30px;

      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMTUiIGN5PSIxNS41MTgiIHI9IjE1IiBmaWxsPSIjRUFGMkZGIi8+PHBhdGggZD0iTTIxLjg3NSAxMS4xNDNsLTguNzUgOC43NS00LjM3NS00LjM3NSIgc3Ryb2tlPSIjMkQ4MUZGIiBzdHJva2Utd2lkdGg9IjIuMzUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);
    }
  }

  p + ul {
    margin-top: 48px;
  }
`

export const Image = styled.div`
  max-width: 162px;
  position: relative;
  top: -40px;
  @media ${mediaQueries.medium} {
    max-width: 240px;
    top: -64px;
  }
`
