import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import styled from "styled-components"
import { Link } from "gatsby"

export const MentionDm = ({ slice }) => {
  const primary = slice.primary
  return (
    <Container>
      <img src={primary.icon.url} alt={primary.icon.alt} />
      <Text>
        <PrismicRichText
          field={primary.text.richText || []}
          internalLinkComponent={({ href, ...props }) => (
            <Link to={href} {...props} />
          )}
        />
      </Text>
    </Container>
  )
}

export const query = graphql`
  fragment MentionDm on PrismicMentionDmDefault {
    id
    slice_type
    primary {
      text {
        richText
      }
      icon {
        alt
        url
      }
    }
  }
`
const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 15px 0 0;
  max-width: 1220px;
  margin: 139px auto 0px;
  @media all and (max-width: 500px) {
    flex-direction: column;
  }
`

const Text = styled.section`
  p {
    font-size: 14px !important;
    margin-left: 15px;
    margin-bottom: 1.5rem;
    line-height: 1.33;
    @media all and (max-width: 500px) {
      margin-left: 0px;
      margin-top: 15px;
      padding: 16px;
    }
  }
`
