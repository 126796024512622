import React from "react"
import { graphql } from "gatsby"

import { Container, T6, P3, T4, ButtonBlueBig } from "@styles"
import { Section, Background, Block, Article, Questions, Button } from "./style"

const Question = ({ title, contentfaq }) => {
  const [active, setActive] = React.useState(false)

  const toggle = React.useCallback(
    evt => {
      evt.preventDefault()
      setActive(state => !state)
    },
    [setActive]
  )

  return (
    <Article onClick={toggle} active={active}>
      <P3 as="h3">{title}</P3>
      <T4
        as="div"
        dangerouslySetInnerHTML={{ __html: contentfaq && contentfaq.html }}
      />
    </Article>
  )
}

const Faq = ({ slice }) => {
  const primary = slice.primary || {}
  const items = slice.items || []
  const link = primary.link || {}

  return (
    <Section>
      <Background>
        <Container>
          <Block>
            <T6 as="h2" mb={4} mbmd={8}>
              {primary.title}
            </T6>

            <Questions>
              <div>
                {items.map((question, key) => (
                  <Question key={`question-${key}`} {...question} />
                ))}
              </div>

              <Button>
                <ButtonBlueBig as="a" href={link.url}>
                  {primary.button}
                </ButtonBlueBig>
              </Button>
            </Questions>
          </Block>
        </Container>
      </Background>
    </Section>
  )
}

export default Faq

export const query = graphql`
  fragment PremiumFaq on PrismicPremiumFaqDefault {
    id
    slice_type
    variation
    primary {
      title
      button
      link {
        url
      }
    }
    items {
      contentfaq: content {
        html
      }
      title
    }
  }
`
