exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */),
  "component---src-templates-drug-js": () => import("./../../../src/templates/drug.js" /* webpackChunkName: "component---src-templates-drug-js" */),
  "component---src-templates-drugs-list-js": () => import("./../../../src/templates/drugs-list.js" /* webpackChunkName: "component---src-templates-drugs-list-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-goodmed-page-js": () => import("./../../../src/templates/goodmed_page.js" /* webpackChunkName: "component---src-templates-goodmed-page-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-insurance-register-js": () => import("./../../../src/templates/insurance-register.js" /* webpackChunkName: "component---src-templates-insurance-register-js" */),
  "component---src-templates-insurances-js": () => import("./../../../src/templates/insurances.js" /* webpackChunkName: "component---src-templates-insurances-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-podcasts-js": () => import("./../../../src/templates/podcasts.js" /* webpackChunkName: "component---src-templates-podcasts-js" */),
  "component---src-templates-premium-js": () => import("./../../../src/templates/premium.js" /* webpackChunkName: "component---src-templates-premium-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */)
}

