const theme = {
  colors: {
    primary: "#2D81FF",
    primaryMedium: "#448EFF",
    primaryLight: "#EAF2FF",

    secondary: "#FFDC6D",
    secondaryMedium: "#FFE286",
    secondaryLight: "#FFF8E2",

    tertiary: "#61E5B9",
    tertiaryMedium: "#7BE8C3",
    tertiaryLight: "#E8F9F2",

    red: "#FF546D",
    redMedium: "#FF6B81",
    redLight: "#FFF2F4",

    pink: "#FF9EDB",
    pinkMedium: "#FFAADF",
    pinkLight: "#FFECF8",

    orange: "#FF7D4F",
    orangeMedium: "#FF885D",
    orangeLight: "#FFF3EF",

    skin: "#FFDCE2",
    skinMedium: "#FFECEF",

    sand: "#FEFAF1",
    sandMedium: "#FEF8EC",

    grey0: "#F8F8F8",
    grey25: "#E5E5E5",
    grey50: "#B9B9B9",
    grey75: "#9B9B9B",
    grey100: "#171717",

    black: "#000",
    white: "#fff",
  },
  breakpoints: {
    xs: 0,
    // 480px en rem
    sm: "30rem",
    // 768px en rem
    md: "48rem",
    // 992px en rem
    lg: "62rem",
    // 1280x en rem
    xl: "80rem",
    // 1440x en rem
    xxl: "90rem",
  },
  margin: {
    // 4px en rem
    xs: "0.25rem",
    // 8px en rem
    sm: "0.5rem",
    // 14px en rem
    md: "0.875rem",
    // 24px en rem
    lg: "1.5rem",
    // 32px en rem
    xl: "2rem",
    // 40px en rem
    xxl: "2.5rem",
  },
  padding: {
    sm: "0.5rem",
    md: "0.875rem",
    lg: "1.5rem",
  },
  shadows: {
    sm: "0px 0px 40px 1px #00000014",
    md: "0px 2px 32px 0px #0000001A",
    xl: "0px 0px 40px 1px rgba(0, 0, 0, 0.08)",
  },
  fontSize: {
    homeTitle: "2.25rem",
    pageTitle: "1.5rem",
    title3: "1.063rem",
    title4: "0.938rem",
    body: "0.938rem",
    bodySmall: "0.813rem",
    caption: "0.688rem",
  },
}

export default theme
