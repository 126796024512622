import styled from "styled-components"
import { mediaQueries } from "@styles"

const Section = styled.section`
  padding: 74px 0 0;
`

const Image = styled.div`
  max-width: 412px;
  margin-bottom: 24px;

  clip-path: url(#home-app-download);

  @media ${mediaQueries.medium} {
    margin-left: auto;
    margin-bottom: 0;
  }
`

export { Section, Image }
