import styled from "styled-components"
import { mediaQueries } from "@styles"

export const Wrapper = styled.header`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;

  height: ${40 + 2 * 14}px;
  padding: 14px 20px;

  transition: 0.3s background-color;
  ${props => (props.bgColor ? `background-color: ${props.bgColor};` : "")}

  @media ${mediaQueries.navbar} {
    height: ${52 + 2 * 16}px;
    padding: 16px 20px;
  }
`

export const Container = styled.div`
  max-width: 1238px;
  margin: 0 auto;

  @media ${mediaQueries.preNavbar} {
    > div:first-child {
      img {
        max-width: 180px;
        margin-top: 4px;
      }
    }
  }

  @media ${mediaQueries.navbar} {
    display: flex;
    align-items: center;

    > nav:last-child {
      flex: 0 0 auto;
    }
    > div:first-child {
      flex: 1 1 auto;
    }
  }
`

export const Menu = styled.nav`
  ul li a {
    font-size: 16px;
    line-height: 20px;

    color: ${props => props.theme.colors.grey100};

    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      transform: scaleX(0);
      transform-origin: 0 0;
      transition: 250ms transform;

      height: 1px;
      background-color: ${props => props.theme.colors.grey100};
    }
    &.active::after,
    &:hover::after {
      transform: scaleX(1);
    }

    &.btn::after {
      content: none;
    }
  }

  @media ${mediaQueries.preNavbar} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    padding: 104px 20px 40px;
    background-color: #fff;
    z-index: -1;

    transition: 0.3s transform;
    ${props =>
      props.active
        ? "transform: translateX(0);"
        : "transform: translateX(110vw);"}

    ul li {
      margin-bottom: 24px;
    }
  }

  @media ${mediaQueries.navbar} {
    ul li {
      display: inline-block;
      vertical-align: middle;
      margin-left: 48px;
    }
  }
`

export const Hmbg = styled.button`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  border: none;
  padding: 0 0 4px;
  border-bottom: 1px solid;

  font-size: 16px;
  font-family: "stolz";
  background-color: transparent;

  @media ${mediaQueries.navbar} {
    display: none;
  }
`
