import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { linkResolver } from "@utils/linkResolver"
import { Container, GridItem, GridCentered, Tag } from "@styles"

import { SlideDiv, Title, Content, Button, Image, Header, Date } from "./style"

const MAX_LENGTH = 240

const Slide = ({ data = {}, readMore, ...article }) => {
  const image = data.image || {}
  const title = data.title || {}
  const intro = data.intro || {}
  const date = data.date || ""
  const categoryData = data.category || {}
  const categoryDoc = categoryData.document || {}
  const categoryDocData = categoryDoc.data || {}
  const category = categoryDocData.category_name || ""

  const shortText = React.useMemo(() => {
    const textLength = intro.text ? intro.text.length : 0
    if (textLength > MAX_LENGTH)
      return `${intro.text.substring(0, MAX_LENGTH)}...`
    return intro.text
  }, [intro.text])

  return (
    <SlideDiv>
      <Container>
        <GridCentered>
          <GridItem col={{ lg: 6 }}>
            <Header>
              <Tag type={categoryData.uid}>{category}</Tag>
              <Date>{date}</Date>
            </Header>
            <Title as="h2">{title.text}</Title>
            <Content as="p">{shortText}</Content>
            <Button as="a" href={linkResolver(article)}>
              <span>{readMore}</span>
            </Button>
          </GridItem>
          <GridItem col={{ lg: 6 }}>
            <Image>
              <span />
              <GatsbyImage
                image={getImage(image)}
                alt={title.text}
                sizes="100vw, (min-width: 769px) 60vw, (min-width: 1200px) 520px"
              />
            </Image>
          </GridItem>
        </GridCentered>
      </Container>
    </SlideDiv>
  )
}

export default Slide
