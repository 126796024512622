import { Header as FaqHeader, Collapse } from "../templates/faq-components"
import {
  Content,
  Header as HeaderAboutUs,
  Stores,
  Team,
} from "../templates/about-us-components"
import {
  Header as PodcastHeader,
  TitlePodcast,
} from "../templates/podcasts-components"
import { Header as InsuranceregisterHeader } from "../templates/insurance-register-components"

import {
  SolutionHeader,
  SolutionFeatures,
  SolutionSteps,
} from "../templates/solution-components"
import {
  PremiumHeader,
  PremiumHow,
  PremiumFeatures,
  PremiumApp,
  PremiumSubscribe,
  PremiumFaq,
} from "../templates/premium-components"

import { MentionDm } from "@slices"
import {
  HomeHeader,
  HomeFeatures,
  HomeCompaniesSlider,
  HomeApp,
  HomeAppScreens,
  HomeAppPremium,
  HomeAppDownload,
  HomeArticles,
  HomeTestimonialsSlider,
  Video,
} from "../templates/home-components"
import {
  InsurancesSection,
  InsurancesFeatures,
  InsurancesOffer,
} from "../templates/insurances-components"

import {
  ContentBackground,
  BoxIcon,
} from "../templates/goodmed-page-components"

export const allSlices = {
  home_header: HomeHeader,
  home_features: HomeFeatures,
  home_companies_slider: HomeCompaniesSlider,
  home_app: HomeApp,
  home_app_screens: HomeAppScreens,
  home_app_premium: HomeAppPremium,
  home_app_download: HomeAppDownload,
  insurances_section: InsurancesSection,
  home_articles: HomeArticles,
  video: Video,
  mention_dm: MentionDm,
  home_testimonials_slider: HomeTestimonialsSlider,
  about_us_header: HeaderAboutUs,
  about_us_content: Content,
  about_us_app_store: Stores,
  about_us_team: Team,
  podcast_header: PodcastHeader,
  title_podcast: TitlePodcast,
  faq_header: FaqHeader,
  collapse: Collapse,
  insurances_features: InsurancesFeatures,
  insurances_offer: InsurancesOffer,
  insurance_register_header: InsuranceregisterHeader,
  premium_header: PremiumHeader,
  premium_how: PremiumHow,
  premium_features: PremiumFeatures,
  premium_app: PremiumApp,
  premium_subscribe: PremiumSubscribe,
  premium_faq: PremiumFaq,
  hiw_features: SolutionFeatures,
  hiw_header: SolutionHeader,
  hiw_steps: SolutionSteps,
  content_background: ContentBackground,
  box_icon: BoxIcon,
}
