exports.linkResolver = doc => {
  const lang = doc.lang ? doc.lang.substr(0, 2) : "fr"

  switch (doc.type) {
    case "home": {
      return doc.lang === "fr-fr" ? "/" : `/${lang}`
    }

    case "insurances": {
      return `/${lang}/${doc.uid}`
    }

    case "insurance-register": {
      return `/${doc.uid}`
    }

    case "goodmed_page": {
      return `/${doc.uid}`
    }

    case "blog": {
      const category =
        doc.category && doc.category !== "all" ? `/${doc.category}` : ""
      const page = doc.page || 1
      return `/${lang}/blog${category}/${page}`
    }

    case "about_us": {
      return `/${lang}/${doc.uid}`
    }

    case "solution": {
      return `/${lang}/${doc.uid}`
    }

    case "premium": {
      return `/${lang}/${doc.uid}`
    }

    case "faq": {
      return `/${lang}/${doc.uid}`
    }

    case "podcast_page": {
      return `/${lang}/${doc.uid}`
    }

    case "blog_post": {
      return `/${lang}/blog/${doc.uid}`
    }

    case "blog_category": {
      return `/${lang}/blog/${doc.uid}`
    }

    case "legal_page": {
      return `/${lang}/${doc.uid}`
    }

    default:
      return "/"
  }
}
