import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useLottieAnimation from "@utils/hooks/useLottieAnimation"

import { T6, ButtonBlueBig, P00 } from "@styles"
import { Image, Premium, Shadow, Gradient } from "./style"

const Content = props => {
  const primary = props.primary || {}
  const link = primary.link || {}
  const json = primary.json || {}
  const image = getImage(primary.image)

  const animation = useLottieAnimation(json)
  const title = primary.structTitle || primary.title

  return (
    <Shadow>
      <Gradient>
        <div>
          <Premium ref={animation} />
          <T6 as="h2" mb={3} dangerouslySetInnerHTML={{ __html: title.html }} />
          <P00 as="p" mb={5}>
            {primary.content}
          </P00>
          <ButtonBlueBig
            as="a"
            href={link.url}
            picto="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIuNSA1LjM3M2gxNXYxMC42MjVhLjYyNS42MjUgMCAwMS0uNjI1LjYyNUgzLjEyNWEuNjI1LjYyNSAwIDAxLS42MjUtLjYyNVY1LjM3M3oiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMi41IDUuMzczaDE1TDEwIDEyLjI0OCAyLjUgNS4zNzN6IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PHBhdGggZD0iTTE3LjUgNS4zNzNMMTAgMTIuMjQ4IDIuNSA1LjM3MyIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg=="
          >
            <span>{primary.button}</span>
          </ButtonBlueBig>
        </div>
        <Image>
          <GatsbyImage
            image={image}
            alt={title.text}
            sizes={`60vw, (min-width: 1200px) 658px`}
          />
        </Image>
      </Gradient>
    </Shadow>
  )
}

export default Content

export const query = graphql`
  fragment HomeAppPremiumContent on PrismicHomeAppPremiumWithContent {
    id
    primary {
      button
      structTitle: title {
        html
      }
      gradient
      content
      link {
        url
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
      json {
        url
      }
    }
    slice_type
    variation
  }
`
