import { graphql } from "gatsby"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Container, T2, ButtonBlue } from "@styles"

import { Section, Image, Wrapper, Content } from "./style"

const Header = ({ slice }) => {
  const primary = slice.primary || ""
  const title = primary.title || ""
  const content = primary.content || {}
  const button = primary.button || ""
  const image = getImage(primary.image)

  return (
    <Section>
      <Container>
        <Wrapper>
          <T2 as="h1">{title}</T2>
          <Content
            as="div"
            dangerouslySetInnerHTML={{
              __html: content.html,
            }}
          />
          <ButtonBlue as="a" href={`#podcasts`}>
            <span>{button}</span>
          </ButtonBlue>
        </Wrapper>
      </Container>
      <Image>
        <GatsbyImage image={image} alt={title} sizes={`1212px`} />
      </Image>
      <svg width="0" height="0">
        <defs>
          <clipPath
            clipPathUnits="objectBoundingBox"
            transform="scale(.001468 .0014265)"
            id="podcast-header"
          >
            <path
              d="M680.5 590C607.032 658.701 508.6 701 404.07 701C387.221 700.996 370.388 699.947 353.668 697.858C337.314 695.8 321.098 692.77 305.104 688.783C251.704 675.249 201.63 650.956 157.95 617.392C114.269 583.828 77.9016 541.7 51.0751 493.589C1.01001 403.974 26.751 250.733 109.318 189.914L124.861 178.553L125.436 178.118C126.892 176.904 127.871 175.214 128.199 173.348C128.528 171.481 128.185 169.559 127.232 167.921C126.442 166.645 125.333 165.596 124.014 164.878C122.696 164.16 121.214 163.797 119.713 163.825H50.3457C37.0005 163.825 24.2012 158.527 14.7608 149.095C5.32036 139.663 0.0111551 126.87 0 113.526V0H680.5V590Z"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
    </Section>
  )
}

export default Header

export const query = graphql`
  fragment PodcastHeader on PrismicPodcastHeaderDefault {
    id
    slice_type
    primary {
      title
      content {
        html
      }
      button
      image {
        gatsbyImageData(layout: FIXED, width: 889, imgixParams: { q: 100 })
      }
    }
  }
`
