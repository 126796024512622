import styled from "styled-components"
import { mediaQueries } from "@styles"

const Section = styled.section`
  padding: 80px 0;
  @media ${mediaQueries.preMedium} {
    margin-top: -56px;
    padding: 0 0 80px;
    h2 {
      text-align: center;
    }
  }
`

const Image = styled.div`
  display: none;
  max-width: 508px;
  margin: 0 auto;

  position: relative;
  &::after {
    content: "";
    padding-bottom: 100%;
    display: block;
  }

  clip-path: url(#premium-how);

  .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  @media ${mediaQueries.medium} {
    display: block;
  }
`

const ListItem = styled.li`
  text-align: center;
  margin: 0 0 24px;
  span {
    display: block;
    margin: 0 auto 20px;
    vertical-align: middle;

    width: 34px;
    height: 34px;
    line-height: 34px;

    border-radius: 50%;

    color: #fff;
    background-color: ${props => props.theme.colors.primary};

    font-size: 20px;
    text-align: center;
    font-family: "stolz";
  }

  .stores {
    margin-top: 24px;
  }

  @media ${mediaQueries.medium} {
    position: relative;
    margin: 0 0 54px;
    padding-left: ${54 + 24}px;
    text-align: left;

    span {
      position: absolute;
      left: 0;
      top: -14px;

      width: 54px;
      height: 54px;
      line-height: 54px;
    }
  }
`

const Background = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  border-radius: 24px;
  background-color: ${props => props.theme.colors.redLight};

  padding: 154px 0 32px;
  @media ${mediaQueries.medium} {
    border-radius: 50px;
    padding: 154px 0;
  }
`

export { Section, Image, ListItem, Background }
