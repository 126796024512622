import React from "react"

const Filter = ({ children, onChange, active }) => {
  return (
    <li>
      <input
        id={`filter-${children}`}
        type="radio"
        name="filter"
        value={children}
        onChange={onChange}
        checked={active}
      />
      <label htmlFor={`filter-${children}`}>{children}</label>
    </li>
  )
}

export default Filter
