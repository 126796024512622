import React from "react"
import slugify from "slugify"
import { Sticky } from "react-sticky"
import { Link } from "react-scroll"

import { List, ListItem } from "./style"

const StickyList = ({ slices }) => {
  const textSlices = slices.filter(slice => slice.slice_type === "text")
  const headings = textSlices.reduce((result, slice) => {
    const primary = slice.primary || {}
    const text = primary.text || {}
    const richText = text.richText || []
    const titles = richText.filter(
      richTextElement => richTextElement.type.indexOf("heading2") > -1
    )

    return result.concat(titles)
  }, [])

  return (
    <Sticky topOffset={0}>
      {sticky => (
        <List
          style={{
            ...sticky.style,
            paddingTop: 305,
            paddingBottom: 200,
            fontWeight: 700,
            width: 240,
          }}
        >
          <ul>
            {headings.map((content, idx) => {
              return (
                <Subtitle
                  key={`title-${idx}`}
                  uid={slugify(content.text, { strict: true })}
                  title={content.text}
                />
              )
            })}
          </ul>
        </List>
      )}
    </Sticky>
  )
}

export default StickyList

const Subtitle = ({ title, uid }) => {
  return (
    <ListItem>
      <Link
        to={`slice-${uid}`}
        offset={-140}
        spy={true}
        smooth={true}
        activeClass="active"
        duration={500}
      >
        {title}
      </Link>
    </ListItem>
  )
}
