import styled from "styled-components"
import { mediaQueries } from "@styles"
import { T2 } from "@styles"

const Wrapper = styled.div`
  margin-block-end: -130px;
  @media ${mediaQueries.medium} {
    margin-block-end: -270px;
  }
`
const PremiumTitle = styled(T2)`
  white-space: pre-wrap;
  @media ${mediaQueries.preMedium} {
    white-space: inherit;
  }
`

const List = styled.ul`
  a {
    cursor: pointer;
    display: block;
    transition: 0.3s transform;
  }

  a:hover {
    transform: scale(0.95);
  }
`

const Section = styled.section`
  background-image: url(/header.svg);
  background-size: 1391px;
  background-position: top right -44px;

  padding: 88px 0 80px;
  min-height: 500px;
  strong {
    font-weight: 700;
  }

  @media ${mediaQueries.medium} {
    background-size: 2069px;
    background-position: top center;

    padding: 162px 0 80px;
    min-height: 736px;
  }

  h1 {
    margin-left: auto;
    margin-right: auto;
  }
`

const Image = styled.div`
  position: relative;
  margin: 0 auto 24px;

  ${props =>
    props.hasAnimation
      ? `
    max-width: 185px;
    @media ${mediaQueries.medium} {
      max-width: 268px;
      margin-bottom: 56px;
    }

    &::after {
      content: "";
      display: block;
      padding-bottom: 55.55%;
    }

    > div {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  `
      : `
    text-align: center;
    @media ${mediaQueries.preMedium} {
      display: none;
    }
  `}
`

export { Section, Image, PremiumTitle, List, Wrapper }
