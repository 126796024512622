import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"

import { Container, P00, T6, T8, Grid, GridItem, ButtonBlue } from "@styles"
import { Section, Block } from "./style"

const Feature = props => {
  const title = props.txtTitle || props.title
  const content = props.txtContent || props.content

  return (
    <GridItem col={{ md: 6, lg: 6 }} mb={4} mbmd={6}>
      <Block>
        <T8 as="h3" mb={2}>
          {title}
        </T8>
        <P00 as="p">{content}</P00>
      </Block>
    </GridItem>
  )
}

const InsurancesFeatures = ({ slice }) => {
  const primary = slice.primary || {}
  const items = slice.items || []
  const title = primary.structTitle || primary.title
  const content = primary.structContent || primary.content
  const link = primary.link || {}
  return (
    <Section>
      <Container>
        <PrismicRichText
          field={title.richText}
          components={{
            paragraph: ({ children }) => <T6 as="h1">{children}</T6>,
          }}
        />
        {content && (
          <P00 mb={6} dangerouslySetInnerHTML={{ __html: content.html }} />
        )}

        <Grid>
          {items.map((feature, key) => (
            <Feature key={`feature${key}`} {...feature} />
          ))}
        </Grid>

        {primary.button && (
          <div className="center">
            <ButtonBlue as="a" href={link.url}>
              <span>{primary.button}</span>
            </ButtonBlue>
          </div>
        )}
      </Container>
    </Section>
  )
}

export default InsurancesFeatures

export const query = graphql`
  fragment InsurancesFeatures on PrismicInsurancesFeaturesDefault {
    id
    variation
    version
    slice_type
    primary {
      button
      structTitle: title {
        html
        richText
        raw
      }
      link {
        url
        target
      }
      structContent: content {
        html
      }
    }
    items {
      txtContent: content
      txtTitle: title
    }
  }
`
