import React, { useState } from "react"
import { graphql } from "gatsby"

import { Container, T6 } from "@styles"
import styled, { keyframes } from "styled-components"
import { ButtonBlue } from "@styles"
import * as Dialog from "@radix-ui/react-dialog"

const overlayShow = keyframes`
  from {
    opacity: 0
    }

    to {
      opacity: 1
    }
`

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(.93);
  }
  
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  } 
`

const VideoIntro = styled.video`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0);
  object-position: 50% 50%;
  max-width: 860px;
`

const VideoWrapper = styled.div`
  border: 16px solid white;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.1));
  border-radius: 40px;
  overflow: hidden;
  max-width: 860px;
  margin: 0 auto 48px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:hover {
    filter: drop-shadow(0px 0px 45px rgba(0, 0, 0, 0.3));
    transform: scale(0.95);
    img {
      opacity: 1;
    }
  }
`

const Section = styled.section``

const Close = styled(Dialog.Close)`
  position: absolute;
  top: -35px;
  right: 0;
  cursor: pointer;
`

const Content = styled(Dialog.Content)`
  z-index: 150;
  max-width: 85vw;
  width: 1280px;
  padding-bottom: 48%;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (prefers-reduced-motion: no-preference) {
    animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

const Wrapper = styled(Container)`
  position: relative;
  margin-bottom: 164px;
  &::before {
    position: absolute;
    top: 0;
    left: -10%;
    content: url("../../shape-pink.svg");
  }
`

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 100;
  inset: 0;
  @media (prefers-reduced-motion: no-preference) {
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
`

const Icon = styled.img`
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 100;
  width: 40px;
`

const VideoOverlay = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: ${props => (props.hover ? "0.5" : "0")};
  z-index: 100;
  transition: all 0.15s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
`

const Video = ({ slice }) => {
  const primary = slice.primary || {}
  const [play, setPlay] = useState(false)

  const url = play ? `${primary.video_modal}?autoplay=1` : primary.video_modal

  return (
    <>
      <Dialog.Root onOpenChange={() => setPlay(!play)}>
        <Dialog.Portal>
          <Overlay>
            <div style={{ position: "relative" }}>
              <Content>
                <Close asChild>
                  <span>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.5 7.5L7.5 22.5"
                        stroke="white"
                        strokeWidth="2.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.5 22.5L7.5 7.5"
                        stroke="white"
                        strokeWidth="2.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Close>
                <iframe
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 16, overflow: "hidden" }}
                  src={url}
                  frameBorder="0"
                  allowFullScreen
                />
              </Content>
            </div>
          </Overlay>
        </Dialog.Portal>

        <Section style={{ position: "relative" }}>
          <Wrapper>
            <T6
              as="h2"
              center
              mb={9}
              mbmd={13}
              style={{
                maxWidth: 845,
                margin: "80px auto 64px",
                position: "relative",
              }}
            >
              {primary.title}
            </T6>
            <Dialog.Trigger asChild>
              <VideoWrapper>
                <VideoOverlay />
                <VideoIntro autoPlay muted playsInline loop>
                  <source src={primary.video_thumbnail} type="video/mp4" />
                </VideoIntro>
                <Icon src="../icn-play.svg" alt="play" />
              </VideoWrapper>
            </Dialog.Trigger>
            <div
              style={{
                textAlign: "center",
                position: "absolute",
                bottom: -26,
                left: 0,
                right: 0,
              }}
            >
              <Dialog.Trigger asChild>
                <ButtonBlue picto="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyLjIzMyA2LjI2OUwyLjE2MyAxLjA2MmExLjI3MyAxLjI3MyAwIDAwLS41NjItLjEzOSAxLjI4MyAxLjI4MyAwIDAwLS41NjguMTIxIDEuMDU1IDEuMDU1IDAgMDAtLjQxOS4zNDcuODM4LjgzOCAwIDAwLS4xNTMuNDc5djEwLjQxNGEuODQuODQgMCAwMC4xNTQuNDc5Yy4xLjE0NS4yNDUuMjY1LjQxOC4zNDcuMTczLjA4Mi4zNy4xMjQuNTY4LjEyLjE5OS0uMDAzLjM5My0uMDUuNTYzLS4xMzhsMTAuMDctNS4yMDdhMS4wNCAxLjA0IDAgMDAuMzkyLS4zNDUuODM2LjgzNiAwIDAwLjE0My0uNDYzLjgzNi44MzYgMCAwMC0uMTQzLS40NjMgMS4wNCAxLjA0IDAgMDAtLjM5My0uMzQ1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==">
                  <span>{primary.button_text}</span>
                </ButtonBlue>
              </Dialog.Trigger>
            </div>
          </Wrapper>
        </Section>
      </Dialog.Root>
    </>
  )
}

export default Video

export const query = graphql`
  fragment Video on PrismicVideoDefault {
    id
    slice_type
    variation
    primary {
      title
      button_text
      video_modal
      video_thumbnail
    }
  }
`
