import styled from "styled-components"

import { variables, mediaQueries, GridItem, Container, T5 } from "@styles"

const Slice = styled.section`
  position: relative;
  z-index: 1;
  margin-top: -100px;

  @media ${mediaQueries.preMedium} {
    > div > div > div:nth-child(n + 2) {
      display: none;
    }
  }

  @media ${mediaQueries.medium} {
    margin-top: -320px;
  }
`

const ThisContainer = styled(Container)`
  @media ${mediaQueries.preMedium} {
    padding: 0;
    width: 100%;
  }
`

const ThisGridItem = styled(GridItem)`
  margin-bottom: 144px;
  @media ${mediaQueries.medium} {
    margin-bottom: ${variables.gutter};
  }
`

const Block = styled.div`
  position: relative;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 50px 50px;

  padding: 88px 32px 48px 32px;
  text-align: center;

  @media ${mediaQueries.medium} {
    text-align: left;

    padding: 120px 48px 48px 64px;
    border-radius: 60px;
  }

  @media ${mediaQueries.lage} {
    padding: 90px 64px 48px 88px;
  }
`

const Title = styled(T5)`
  margin-bottom: 48px;
`

const Image = styled.div`
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);

  @media ${mediaQueries.medium} {
    transform: translateY(-100%);
    left: 64px;
  }
`

const Stores = styled.div`
  display: flex;
  width: calc(100% + 8px);
  margin-left: -8px;

  > a,
  > div {
    flex: 0 1 auto;
    padding-left: 8px;
  }

  a {
    transition: 0.3s transform;
  }

  a:hover {
    transform: scale(0.95);
  }
`

const Wrapper = styled.div`
  @media ${mediaQueries.medium} {
    margin: 0 auto;
    max-width: ${(100 * 10) / 12}%;
  }
`

export {
  Slice,
  Block,
  Title,
  Image,
  ThisGridItem,
  Stores,
  ThisContainer,
  Wrapper,
}
