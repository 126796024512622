import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"

const Store = ({ link, logo }) => {
  const image = getImage(logo)
  return (
    <GatsbyLink to={link.url || ""}>
      <GatsbyImage image={image} sizes={`${image.width}px`} alt="store" />
    </GatsbyLink>
  )
}

export default Store
