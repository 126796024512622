import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { List } from "./style"

const Store = ({ logo, link }) => {
  const image = getImage(logo)
  return (
    <li>
      <a href={link.url} target="_blank" rel="noreferrer">
        <GatsbyImage image={image} sizes={`${image.width}px`} alt="store" />
      </a>
    </li>
  )
}

const Stores = ({ stores }) => {
  return (
    <List>
      {stores.map((store, key) => (
        <Store key={`store-${key}`} {...store} />
      ))}
    </List>
  )
}

export default Stores
