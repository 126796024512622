import React from "react"
import { graphql } from "gatsby"

import { ButtonBlue } from "@styles"
import { AppLink } from "@components/CustomLink"

const MenuLink = ({ slice }) => {
  const primary = slice.primary
  const link = primary.link || {}
  const title = primary.title || ""
  const button = primary.button

  const url = typeof window !== "undefined" ? window.location.href : ""
  const searchTerm = "medicaments/"

  const isDrugPage = url.indexOf(searchTerm) > 0

  const adjustLink = {
    target: "_blank",
    link_type: "web",
    url: "https://app.adjust.com/y0h912n?campaign=Goodmed-Website&adgroup=Website&creative=Fiches-Médicaments",
  }

  if (button) {
    return (
      <li>
        <AppLink link={isDrugPage ? adjustLink : link} className="btn">
          <ButtonBlue picto="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwIDIuNXYxMS44NzVNNC4zNzUgOC43NUwxMCAxNC4zNzVsNS42MjUtNS42MjVNMy4xMjUgMTYuODc1aDEzLjc1IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS43NSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+">
            <span>{title}</span>
          </ButtonBlue>
        </AppLink>
      </li>
    )
  }

  return (
    <li>
      <AppLink link={link} activeClassName="active">
        {title}
      </AppLink>
    </li>
  )
}

export default MenuLink

export const query = graphql`
  fragment MenuLink on PrismicNavigationDataBody2MenuLink {
    id
    slice_type
    primary {
      button
      link {
        url
        link_type
      }
      title
    }
  }
`
