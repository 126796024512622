import React from "react"
import { graphql } from "gatsby"
import Article from "@templates/blog-components/articles-grid/article"
import { useSwipeable } from "react-swipeable"
import { T6 } from "@styles"
import {
  Section,
  CustomContainer,
  Slider,
  CustomDots as Dots,
  Wrapper,
  Track,
} from "./style"

const HomeAppArticles = ({ slice }) => {
  const [active, setActive] = React.useState(0)

  const primary = slice.primary || {}
  const items = slice.items || []

  const articles = items.map(item => {
    const article = item.article || {}
    const doc = article.document || {}
    return doc
  })

  const goToNext = React.useCallback(
    evt => {
      evt && evt.preventDefault()
      setActive(n => n + 1)
    },
    [setActive]
  )

  const goToPrev = React.useCallback(
    evt => {
      evt && evt.preventDefault()
      setActive(n => n - 1)
    },
    [setActive]
  )

  const handlers = useSwipeable({
    onSwipedLeft: () => goToNext(),
    onSwipedRight: () => goToPrev(),
  })

  const goToSlide = React.useCallback(
    n => evt => {
      evt.preventDefault()
      setActive(n)
    },
    [setActive]
  )

  return (
    <Section>
      <CustomContainer>
        <T6 as="h2" center mb={4} mbmd={8}>
          {primary.title}
        </T6>
        <Wrapper>
          <Slider {...handlers}>
            <Track active={active} length={articles.length}>
              {articles.map(article => (
                <div key={article.id}>
                  <Article {...article} />
                </div>
              ))}
            </Track>
            <Dots>
              {items.map((_, key) => (
                <li
                  key={`dot-${key}`}
                  className={active === key ? "active" : ""}
                >
                  <button onClick={goToSlide(key)}>{key}</button>
                </li>
              ))}
            </Dots>
          </Slider>
        </Wrapper>
      </CustomContainer>
    </Section>
  )
}

export default HomeAppArticles

export const query = graphql`
  fragment HomeArticles on PrismicHomeArticlesDefault {
    id
    slice_type
    variation
    primary {
      title
    }
    items {
      article {
        document {
          ... on PrismicBlogPost {
            ...BlogpostCard
          }
        }
      }
    }
  }
`
