import styled from "styled-components"
import { mediaQueries } from "@styles"

const Section = styled.section`
  padding: 48px 0;
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    z-index: -1;
    position: absolute;
    height: 100%;
    border-radius: 60px;
    top: 0;
    width: 1400px;
    max-width: calc(100% - 20px);
    left: 50%;
    transform: translateX(-50%);
    background-color: ${props =>
      props.redBg ? props.theme.colors.redLight : ""};
  }

  @media ${mediaQueries.medium} {
    padding: 80px 0;
  }
`

const Block = styled.div`
  max-width: 332px;
  margin: 0 auto;
  cursor: pointer;

  span {
    font-size: 64px;
    font-family: "stolz";
    font-weight: 700;
    display: block;
  }

  span,
  h3 {
    transition: opacity 0.2s;
    @media ${mediaQueries.medium} {
      ${props => (props.active ? "opacity: 1" : "opacity: .2")};
    }
  }
`

const Image = styled.div`
  display: none;
  transition: transform 0.2s;

  @media ${mediaQueries.medium} {
    display: block;
    ${props => (props.active ? "transform: scale(1)" : "transform: scale(.9)")};
  }
`

export { Section, Block, Image }
