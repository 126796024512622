import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import { Link } from "gatsby"
import styled from "styled-components"
import { mediaQueries } from "@styles"

export const CompulsoryStatements = ({ slice }) => {
  const primary = slice.primary
  return (
    <SmallText>
      <PrismicRichText
        field={primary.text.richText || []}
        internalLinkComponent={({ href, ...props }) => (
          <Link to={href} {...props} />
        )}
      />
    </SmallText>
  )
}

export const query = graphql`
  fragment CompulsoryStatements on PrismicCompulsoryStatementsDefault {
    id
    slice_type
    primary {
      text {
        richText
      }
    }
  }
`

const SmallText = styled.section`
  max-width: 1400px;
  margin: 0 auto;

  padding: 0 32px 0;
  @media ${mediaQueries.medium} {
    padding: 32px 46px 0;
  }
  p {
    font-size: 14px;
    margin-left: 15px;
    /* margin-bottom: 1.5rem; */
    margin-bottom: 0;
    line-height: 1.33;
    @media all and (max-width: 500px) {
      margin-left: 0px;
      margin-top: 15px;
    }
  }
`
