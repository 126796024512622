import styled from "styled-components"
import { variables, mediaQueries } from "@styles"

const Section = styled.section`
  padding: 80px 0;
`

const Wrapper = styled.div`
  display: grid;
  align-items: center;
`

const Title = styled.div``

const BoxContainer = styled.div`
  padding: 24px;
  border-radius: 40px;
  background-color: ${variables.lightBlue};
  width: 330px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  img {
    aspect-ratio: 3/2;
    height: 75px;
    object-fit: contain;
  }
`

const Image = styled.div`
  margin-bottom: 16px;
  height: 108px;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 24px;
  margin: 64px auto;
  grid-auto-columns: min-content;
  grid-column-start: 1;
  grid-column-end: 1;
  ${Title} {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 1;
    @media ${mediaQueries.medium} {
      margin-top: 24px;
      grid-column-start: 1;
      grid-column-end: 3;
    }
    @media ${mediaQueries.navbar} {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
`

export { Section, Image, BoxContainer, Wrapper, Grid, Title }
