import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { SliceZone } from "@prismicio/react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import { MentionDm, CompulsoryStatements } from "@slices"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import {
  SolutionHeader,
  SolutionFeatures,
  SolutionSteps,
} from "./solution-components"
import { Stores } from "./about-us-components"

const BackgroundLayout = styled.div`
  section:nth-child(4) {
    transform: none;
  }

  section:nth-child(4),
  section:nth-child(5) {
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      z-index: -1;
      position: absolute;
      width: 1400px;
      max-width: calc(100% - 20px);

      left: 50%;
      transform: translateX(-50%);
    }
  }

  section:nth-child(4)::before {
    background-image: linear-gradient(
      180deg,
      ${props => props.theme.colors.redLight} 50%,
      ${props => props.theme.colors.primaryLight} 50%
    );
  }

  section:nth-child(5)::before {
    background-color: ${props => props.theme.colors.primaryLight};
    border-radius: 0 0 50px 50px;
  }

  section:nth-child(4)::before,
  section:nth-child(5)::before {
    height: 100%;
    top: 0;
  }
`

const Solution = ({ data = {} }) => {
  const langParams = data.langParams || {}
  const navigation = data.prismicNavigation || {}
  const doc = data.prismicSolution || {}
  const docData = doc.data || {}
  const slices = docData.body || []

  return (
    <Layout navigation={navigation} languages={langParams}>
      <Seo
        title={docData.meta_title}
        description={docData.meta_description}
        openGraphImg={docData.open_graph && docData.open_graph.url}
      />
      <BackgroundLayout>
        <SliceZone
          slices={slices}
          components={{
            hiw_features: SolutionFeatures,
            hiw_header: SolutionHeader,
            hiw_steps: SolutionSteps,
            about_us_app_store: Stores,
            mention_dm: MentionDm,
            compulsory_statements: CompulsoryStatements,
          }}
        />
      </BackgroundLayout>
    </Layout>
  )
}

export default withPrismicPreview(Solution)

export const query = graphql`
  query Solutionpage($lang: String) {
    langParams: prismicSolution(lang: { eq: $lang }) {
      uid
      lang
      url
      type
      alternate_languages {
        uid
        type
        lang
      }
    }
    prismicSolution(lang: { eq: $lang }) {
      lang
      url
      type
      _previewable
      data {
        meta_description
        meta_title
        open_graph {
          url
        }
        body {
          ... on PrismicAboutUsAppStoreDefault {
            ...AboutUsStores
          }
          ... on PrismicHiwHeaderDefault {
            ...SolutionHeader
          }
          ... on PrismicHiwStepsDefault {
            ...SolutionSteps
          }
          ... on PrismicHiwFeaturesDefault {
            ...SolutionFeatures
          }
          ... on PrismicHiwFeaturesNewFeatures {
            ...SolutionFeaturesNew
          }
          ... on PrismicMentionDmDefault {
            ...MentionDm
          }
          ... on PrismicCompulsoryStatementsDefault {
            ...CompulsoryStatements
          }
        }
      }
    }
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
  }
`
