import styled from "styled-components"
import { T5, P0, mediaQueries } from "@styles"

const Section = styled.section`
  padding: 48px 0 80px;
`

const Title = styled(T5)`
  margin: 0 0 24px;
  text-align: center;

  span {
    position: relative;
    z-index: 1;
    svg {
      position: absolute;
      top: -4px;
      left: -20px;
      width: 48px;
      z-index: -1;

      @media ${mediaQueries.medium} {
        left: -28px;
        width: 66px;
      }

      fill: ${props => props.theme.colors[props.color || "secondaryMedium"]};
    }
  }
`

const Content = styled(P0)`
  font-family: "stolz";
  text-align: center;
  margin: 0 auto 64px;
  max-width: 332px;
`

const Button = styled.div`
  text-align: center;
`

export { Section, Content, Title, Button }
