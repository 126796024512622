import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import styled from "styled-components"

export const Quote = ({ slice }) => (
  <Aside className={slice.primary.color}>
    <Bar className={slice.primary.color} />
    <PrismicRichText field={slice.primary.quote.richText || []} />
  </Aside>
)

export const query = graphql`
  fragment BlogPostDataBodyQuote on PrismicBlogPostDataBodyQuote {
    primary {
      color
      quote {
        richText
      }
    }
  }
`
const Aside = styled.aside`
  padding-left: 0.75rem;
  font-weight: 700;
  font-size: 1.25rem;
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 1.25;
  position: relative;

  color: ${props => props.theme.colors.primary};
`

const Bar = styled.div`
  width: 5px;
  height: 90%;
  position: absolute;
  left: 0;
  border-radius: 60px;
  top: 5%;

  background-color: ${props => props.theme.colors.primary};
`
