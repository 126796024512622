import styled from "styled-components"

const List = styled.ul`
  > li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    margin-bottom: 16px;

    a {
      cursor: pointer;
      display: block;
      transition: 0.3s transform;
    }

    a:hover {
      transform: scale(0.95);
    }
  }
`

export { List }
