import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import { StickyContainer } from "react-sticky"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import Layout from "@components/layout"
import Seo from "../components/seo"
import { MentionDm } from "@slices"
import { useSwipeable } from "react-swipeable"

import { SliceZone } from "@prismicio/react"

import { ImageCaption, Quote, Text } from "@slices"
import Article from "@templates/blog-components/articles-grid/article"
import BlogTitle from "@templates/blog-components/blog-title"
import useMediaQuery from "../utils/hooks/useMediaQuery"
import {
  Image,
  Header,
  StickyList as Sticky,
  Newsletter,
  AppStores,
  Cta,
} from "./blogpost-components"

import {
  Trame,
  Grid,
  GridJustified,
  Container as RegularContainer,
} from "@styles"

import {
  Shadow,
  Section,
  Wrapper,
  ThisContainer as Container,
  Divider,
  ArticleWrapper,
  Title,
  Slider,
  Intro,
  CustomDots as Dots,
  Track,
  CardContainer,
} from "./blogpost-components/style"

import { Background } from "./blog-components/blog-body/style"

const BlogPost = ({ data }) => {
  const [active, setActive] = React.useState(0)
  const langParams = data.langParams || {}
  const navigation = data.prismicNavigation || {}
  const blogpost = data.prismicBlogPost || {}
  const content = blogpost.data || {}
  const tag = blogpost.tags ? blogpost.tags[0] : ""
  const lang = blogpost.lang || "fr-fr"
  const slices = content.body || []
  const slices1 = content.body1 || []

  const blogDoc = data.blog || {}
  const blog = blogDoc.data || {}

  const relatedBlogposts = content.related_articles || []
  const blogposts = relatedBlogposts.map(
    ({ article = {} }) => article.document || {}
  )
  const image = content.image_in_card || {}
  // const altLangs = pageContext.alternateLanguages
  const isDesktop = useMediaQuery("(min-width: 769px)")

  const goToNext = React.useCallback(
    evt => {
      evt && evt.preventDefault()
      setActive(n => n + 1)
    },
    [setActive]
  )

  const goToPrev = React.useCallback(
    evt => {
      evt && evt.preventDefault()
      setActive(n => n - 1)
    },
    [setActive]
  )

  const handlers = useSwipeable({
    onSwipedLeft: () => goToNext(),
    onSwipedRight: () => goToPrev(),
  })

  const goToSlide = React.useCallback(
    n => evt => {
      evt.preventDefault()
      setActive(n)
    },
    [setActive]
  )

  return (
    <Layout
      navigation={navigation}
      languages={langParams}
      backgroundColor="#fff"
    >
      <Seo
        title={content.meta_title}
        description={content.meta_description}
        openGraphImg={content.open_graph && content.open_graph.url}
      />
      <div>
        {/* Header Image */}
        <Image
          image={isDesktop ? content.image : image}
          alt={content.title1.text}
        />

        <StickyContainer>
          <Container>
            <Sticky topOffset={-140} slices={slices} />

            <Section id="article">
              <Shadow />
              <Header
                tag={tag}
                date={content.date}
                image={content.author_img}
                job={content.author_job}
                name={content.author_name}
                lang={lang}
              />
              <Divider />
              <Wrapper>
                <Title>{content.title.text}</Title>
                <Intro>
                  <PrismicRichText field={content.intro.richText} />
                </Intro>
                <SliceZone
                  slices={slices}
                  components={{
                    image_with_caption: ImageCaption,
                    quote: Quote,
                    text: Text,
                    mention_dm: MentionDm,
                  }}
                />
              </Wrapper>
            </Section>
          </Container>
        </StickyContainer>
      </div>

      <svg width="0" height="0">
        <defs>
          <clipPath
            transform="scale(0.0005094243504839531, 0.0022222222222222222)"
            clipPathUnits="objectBoundingBox"
            id="header-image"
          >
            <path
              d="M0 0l16.096 157.149c17.744 173.225 172.945 303.804 346.65 291.657l1315.016-91.954c173.71-12.147 300.14-162.42 282.4-335.644L1958.06.693 0 0z"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>

      {/* Additional content */}
      {blogposts.length > 0 && (
        <div id="RelatedArticles">
          <Trame style={{ marginTop: 100 }}>
            <Background style={{ top: 0 }} />

            <RegularContainer>
              {/* Featured articles */}
              <ArticleWrapper>
                <BlogTitle title={blog.other_articles} />
                <Grid>
                  <Slider {...handlers}>
                    <Track active={active} length={blogposts.length}>
                      {blogposts.map(blogpost => (
                        <div key={blogpost.id}>
                          <Article {...blogpost} />
                        </div>
                      ))}
                    </Track>
                    <Dots>
                      {blogposts.map((_, key) => (
                        <li
                          key={`dot-${key}`}
                          className={active === key ? "active" : ""}
                        >
                          <button onClick={goToSlide(key)}>{key}</button>
                        </li>
                      ))}
                    </Dots>
                  </Slider>
                </Grid>
              </ArticleWrapper>
            </RegularContainer>
          </Trame>
        </div>
      )}

      {/* Cards */}
      <CardContainer>
        <GridJustified>
          <SliceZone
            slices={slices1}
            components={{
              card_newsletter_: Newsletter,
              card_stores: AppStores,
              card_call_to_action: Cta,
            }}
          />
        </GridJustified>
      </CardContainer>
    </Layout>
  )
}

export default withPrismicPreview(BlogPost)

export const query = graphql`
  query BlogPost($id: String, $lang: String) {
    langParams: prismicBlogPost(id: { eq: $id }, lang: { eq: $lang }) {
      uid
      lang
      url
      type
      alternate_languages {
        uid
        type
        lang
      }
    }
    blog: prismicBlog(lang: { eq: $lang }) {
      data {
        other_articles
        body {
          ... on PrismicBlogDataBodyCoolCta {
            ...CoolCtaSlice
          }
        }
      }
    }
    prismicBlogPost(id: { eq: $id }, lang: { eq: $lang }) {
      lang
      tags
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      data {
        meta_title
        meta_description
        open_graph {
          url
        }
        author_img {
          alt
          url
          gatsbyImageData(layout: FIXED, width: 36, imgixParams: { q: 100 })
        }
        author_job
        author_name
        body1 {
          ... on PrismicBlogPostDataBody1CardNewsletter {
            ...CardNewsletter
          }
          ... on PrismicBlogPostDataBody1CardStores {
            ...CardStores
          }
          ... on PrismicBlogPostDataBody1CardCallToAction {
            ...CardCta
          }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...BlogPostDataBodyText
          ...BlogPostDataBodyQuote
          ...BlogPostDataBodyImageWithCaption
          ... on PrismicMentionDmDefault {
            ...MentionDm
          }
        }
        date
        image_in_card {
          gatsbyImageData(
            layout: FULL_WIDTH
            srcSetMaxWidth: 1963
            imgixParams: { q: 100 }
          )
        }
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            srcSetMaxWidth: 1963
            imgixParams: { q: 100 }
          )
          alt
          url
          thumbnails {
            Mobile {
              alt
              url
            }
          }
        }
        intro {
          html
          richText
          text
        }
        title {
          html
          richText
          text
        }
        title1 {
          html
          richText
          text
        }
        related_articles {
          article {
            id
            url
            document {
              ... on PrismicBlogPost {
                ...BlogpostCard
              }
            }
          }
        }
        title {
          html
          richText
          text
        }
      }
    }
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
  }
`
