import React from "react"

import { MediaPlayer, Track, Play, Loader, Duration } from "./style"

const Player = ({ streamUrl, duration, marginBottom, featured }) => {
  const [isLoading, setLoadingState] = React.useState(false)
  const [isPlaying, setAudioState] = React.useState(false)
  const [progress, setProgress] = React.useState(0)
  const savedProgress = React.useRef(progress)
  const mediaPlayer = React.useRef()
  const previousTouch = React.useRef()
  const dragging = React.useRef()

  const minutes = React.useMemo(
    () =>
      Math.trunc((duration - progress) / 60)
        .toString()
        .padStart(2, "0"),
    [progress, isPlaying, duration]
  )

  const seconds = React.useMemo(
    () =>
      Math.trunc((duration - progress) % 60)
        .toString()
        .padStart(2, "0"),
    [progress, isPlaying, duration]
  )

  // Convert progress to duration ratio
  const currentProgress = React.useMemo(
    () => Math.min(1, progress / duration),
    [duration, progress]
  )

  // Play / pause
  const togglePlay = React.useCallback(
    evt => {
      evt.preventDefault()
      setAudioState(state => !state)
    },
    [setAudioState]
  )

  const whilePlaying = React.useCallback(() => {
    const player = mediaPlayer.current
    const currentTime = player.currentTime
    setProgress(currentTime)
  }, [setProgress])

  // Drag start
  const setDrag = React.useCallback(() => {
    setAudioState(false)
    dragging.current = true
  }, [setAudioState])

  // Drag stop
  const clearDrag = React.useCallback(() => {
    dragging.current = false
    previousTouch.current = null
  }, [])

  // Drag cursor
  const handleDrag = React.useCallback(
    evt => {
      if (dragging.current) {
        if (evt.touches) {
          evt.stopPropagation()
          const touch = evt.touches[0]

          if (previousTouch.current) {
            evt.movementX = touch.pageX - previousTouch.current.pageX
            evt.movementY = touch.pageY - previousTouch.current.pageY
          }
          previousTouch.current = touch
        }
        const player = mediaPlayer.current
        const width = player.parentElement.offsetWidth || 100
        const x = (200 * evt.movementX) / width
        setProgress(a => a + x)
      }
    },
    [setProgress]
  )

  // Position audio on track
  const handleTrackClick = React.useCallback(
    evt => {
      const x = evt.nativeEvent.offsetX || 0
      const width = evt.target.offsetWidth || 100
      const newProgress = x / width
      setProgress(newProgress * duration)
      setAudioState(true)
    },
    [duration, setProgress]
  )

  /** show loader */
  const handleVideoLoading = React.useCallback(
    () => setLoadingState(true),
    [setLoadingState]
  )

  /** hide loader */
  const handleVideoPlaying = React.useCallback(
    () => setLoadingState(false),
    [setLoadingState]
  )

  React.useEffect(() => {
    const player = mediaPlayer.current
    player.addEventListener("timeupdate", whilePlaying)
    document.addEventListener("mouseup", clearDrag)
    document.addEventListener("touchend", clearDrag)
    document.addEventListener("mousemove", handleDrag)
    player.addEventListener("waiting", handleVideoLoading)
    player.addEventListener("playing", handleVideoPlaying)
    return () => {
      player.removeEventListener("timeupdate", whilePlaying)
      document.removeEventListener("mouseup", clearDrag)
      document.removeEventListener("touchend", clearDrag)
      document.removeEventListener("mousemove", handleDrag)
      player.removeEventListener("waiting", handleVideoLoading)
      player.removeEventListener("playing", handleVideoPlaying)
    }
  }, [whilePlaying])

  React.useEffect(() => {
    savedProgress.current = progress
  }, [progress])

  React.useEffect(() => {
    const player = mediaPlayer.current

    if (isPlaying && player.paused) {
      player.currentTime = savedProgress.current
      player.play()
    }

    if (!isPlaying && !player.paused) {
      player.pause()
    }
  }, [isPlaying])

  return (
    <MediaPlayer marginBottom={marginBottom}>
      <audio src={streamUrl} ref={mediaPlayer} />

      {/* Play */}
      <div>
        <Play
          featured={featured}
          onClick={isLoading ? () => {} : togglePlay}
          active={isPlaying}
        >
          {isLoading ? (
            <Loader featured={featured} />
          ) : (
            <span aria-hidden={true} />
          )}
        </Play>
      </div>

      {/* Track */}
      <Track
        aria-hidden={true}
        onMouseDown={isLoading ? () => {} : setDrag}
        onTouchStart={isLoading ? () => {} : setDrag}
        onClick={isLoading ? () => {} : handleTrackClick}
      >
        <span
          style={{ width: `${100 * currentProgress}%` }}
          className="progress"
        ></span>
        <span className="cursor"></span>
      </Track>

      {/* Duration */}
      <Duration>{`${minutes}:${seconds}`}</Duration>
    </MediaPlayer>
  )
}

export default Player
