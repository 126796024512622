import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"
import { SliceZone } from "@prismicio/react"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import {
  Slider,
  ArticlesGrid,
  BlogBody,
  SearchBar,
  CtaBlocks,
  BlogTitle,
} from "./blog-components"

const Blog = ({ data, pageContext }) => {
  const langParams = data.langParams || {}
  const navigation = data.prismicNavigation || {}
  const featuredBlogpostNodes = data.featured_blogposts || {}
  const featured_blogposts = featuredBlogpostNodes.nodes || []
  const blogpostsNodes = data.blogposts || {}
  const blogposts = blogpostsNodes.nodes || []
  const categoriesNodes = data.categories || {}
  const categories = categoriesNodes.nodes || []
  const pages = pageContext.pages || 1
  const page = pageContext.page || 1
  const currentCategories = pageContext.category || ["all"]
  const currentCategory =
    currentCategories.length > 0 ? "all" : currentCategories[0]
  const blogDoc = data.blog || {}
  const blog = blogDoc.data || {}

  return (
    <Layout navigation={navigation} languages={langParams}>
      <Seo
        title={blog.meta_title}
        description={blog.meta_description}
        openGraphImg={blog.open_graph && blog.open_graph.url}
      />

      {/* Header slider with featured posts */}
      <Slider blogposts={featured_blogposts} readMore={blog.read_more} />

      {/* Title */}
      <BlogTitle title={blog.title} />

      {/* Search bar */}
      <SearchBar
        categories={categories}
        currentCategory={currentCategory}
        placeholder={blog.search}
        all={blog.all}
        noResults={blog.no_results}
        lang={blogDoc.lang}
      />

      <BlogBody>
        <ArticlesGrid
          pages={pages}
          page={page}
          blogposts={blogposts}
          doc={{
            lang: blogDoc.lang,
            category: currentCategory,
            type: blogDoc.type,
          }}
        />
      </BlogBody>

      <SliceZone
        slices={blog.body}
        components={{
          cool_cta: CtaBlocks,
        }}
      />
    </Layout>
  )
}

export default withPrismicPreview(Blog)

export const query = graphql`
  query ($lang: String, $skip: Int, $perPage: Int, $category: [String]) {
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
    langParams: prismicBlog(lang: { eq: $lang }) {
      lang
      url
      type
      alternate_languages {
        uid
        type
        lang
      }
    }
    blog: prismicBlog(lang: { eq: $lang }) {
      _previewable
      type
      lang
      alternate_languages {
        uid
        type
        lang
      }
      data {
        meta_title
        meta_description
        title
        no_results
        read_more
        search
        all
        body {
          ... on PrismicBlogDataBodyCoolCta {
            ...CoolCtaSlice
          }
        }
      }
    }

    categories: allPrismicBlogCategory(
      filter: { lang: { eq: $lang } }
      sort: { order: ASC, fields: first_publication_date }
    ) {
      nodes {
        ...CategoryData
      }
    }

    featured_blogposts: allPrismicBlogPost(
      filter: { lang: { eq: $lang }, data: { featured: { eq: true } } }
      sort: { order: DESC, fields: data___date }
    ) {
      nodes {
        ...BlogpostCard
      }
    }

    blogposts: allPrismicBlogPost(
      filter: {
        lang: { eq: $lang }
        data: { category: { uid: { in: $category } } }
      }
      sort: { order: DESC, fields: data___date }
      limit: $perPage
      skip: $skip
    ) {
      nodes {
        ...BlogpostCard
      }
    }
  }
`
