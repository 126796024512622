import React from "react"

import { Section, CustomContainer } from "./style"

import Default from "./default"
import Content from "./content"

const mapVariations = {
  default: Default,
  with_content: Content,
}

const HomeAppPremium = ({ slice }) => {
  const Component = mapVariations[slice.variation]

  return (
    <Section variation={slice.variation} gradient={slice.primary.gradient}>
      <CustomContainer>
        <Component {...slice} />
      </CustomContainer>
      <svg width="0" height="0">
        <defs>
          <clipPath
            clipPathUnits="objectBoundingBox"
            transform="scale(.001520 .002137)"
            id="home-app-premium"
          >
            <path
              d="M658 412.014c0 30.377-24.625 55.001-55.001 55L41.883 467C.828 397.024 21.937 279.335 89.645 231.845l12.746-8.872.471-.339a6.205 6.205 0 002.266-3.725 6.013 6.013 0 00-.793-4.238 6.979 6.979 0 00-2.639-2.376 7.378 7.378 0 00-3.527-.822H41.285c-10.943 0-21.44-4.137-29.18-11.502C4.362 192.606.008 182.616 0 172.197V39.254c.015-10.416 4.372-20.4 12.113-27.76C19.852 4.134 30.346 0 41.285 0h127.687C259.31.051 346.716.094 436.1 0H603c30.376 0 55 24.624 55 55v357.014z"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
    </Section>
  )
}

export default HomeAppPremium
