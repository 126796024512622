import styled from "styled-components"
import { mediaQueries, Container } from "@styles"

const Section = styled.section`
  padding: 80px 0 0;
  @media ${mediaQueries.medium} {
    padding: 80px 0;
  }
`

const Wrapper = styled(Container)`
  @media ${mediaQueries.preMedium} {
    padding: 0;
    width: 100%;
  }
`

const Block = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.08);

  border-radius: 0px 0px 50px 50px;
  padding: 40px 40px 104px;

  @media ${mediaQueries.medium} {
    border-radius: 60px;
    padding: 0 80px 0;

    display: flex;
    > div:first-child {
      padding: 80px 0;
      padding-right: 32px;
    }
    > div:last-child {
      padding-top: 80px;
    }
  }

  @media ${mediaQueries.large} {
    display: flex;
    > div:first-child {
      flex: 1 1 auto;
      align-self: center;
      padding-right: 32px;
    }
    > div:last-child {
      align-self: flex-end;
      flex: 0 0 auto;
      width: 33%;
    }
  }

  svg {
    display: block;
    margin: -120px 0 24px;
    max-width: 108px;

    @media ${mediaQueries.medium} {
      margin: -172px 0 16px;
      max-width: 136px;
    }
  }
`

const Image = styled.div`
  display: none;

  filter: drop-shadow(-23.5158px 0px 58.79px rgba(24, 48, 63, 0.2));
  max-width: 332px;
  img {
    border-radius: 24px 24px 0px 0px;
    overflow: hidden;
  }

  @media ${mediaQueries.large} {
    display: block;
  }
`

export { Section, Block, Wrapper, Image }
