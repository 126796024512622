import React from "react"

import Slide from "./slide"

import {
  MainSlider,
  ArrowNext,
  ArrowPrev,
  CustomControls as Controls,
  Track,
} from "./style"

const Slider = ({ blogposts, readMore }) => {
  const [currentSlide, setSlide] = React.useState(0)

  const track = React.useRef()

  const nextSlide = React.useCallback(
    evt => {
      evt.preventDefault()
      setSlide(slide => {
        if (slide < blogposts.length - 1) return slide + 1
        return slide
      })
    },
    [setSlide, blogposts.length]
  )

  const prevSlide = React.useCallback(
    evt => {
      evt.preventDefault()
      setSlide(slide => {
        if (slide > 0) return slide - 1
        return slide
      })
    },
    [setSlide, blogposts.length]
  )

  const resizeTrack = React.useCallback(() => {
    const currentTrack = track.current
    const children = currentTrack.children
    const currentNode = children[currentSlide]
    const refElement = currentNode.firstChild
    // currentTrack.style.height = `${refElement.offsetHeight + 248}px`
    currentTrack.style.height = `${refElement.offsetHeight + 88}px`
  }, [currentSlide])

  React.useEffect(() => {
    resizeTrack()
    window.addEventListener("resize", resizeTrack)
    return () => window.removeEventListener("resize", resizeTrack)
  }, [resizeTrack])

  return (
    <MainSlider>
      <Controls>
        <ArrowPrev onClick={prevSlide} active={currentSlide > 0}>
          prev
        </ArrowPrev>
        <ArrowNext
          onClick={nextSlide}
          active={currentSlide < blogposts.length - 1}
        >
          next
        </ArrowNext>
      </Controls>

      <Track ref={track} length={blogposts.length} currentSlide={currentSlide}>
        {blogposts.map(blogpost => (
          <Slide key={`${blogpost.id}`} readMore={readMore} {...blogpost} />
        ))}
      </Track>

      <svg height="0" width="0">
        <defs>
          <clipPath
            transform="scale(0.002380952380952381,0.002364066193853428)"
            clipPathUnits="objectBoundingBox"
            id="slider-shape"
          >
            <path
              fill="#FFFFFF"
              d="M5.876 52.179c-1.147-4.356-2.25-8.745-2.992-13.188-6.2-37.064-5.215-37.947 31.84-37.914 118.935.135 237.875.861 356.787-.558 26.123-.31 30.564 9.075 26.729 31.527-11.847 69.35-47.637 121.66-108.959 155.988-12.519 8.484-24.16 14.848-41.059 22.999 42.346 0 84.741 1.133 127.027-.476 21.157-.802 25.901 5.894 23.84 26.212-10.462 103.152-102.711 185.787-208.89 185.72-106.584-.079-198.286-82.004-209.003-185.444-2.11-20.306 3.1-27.117 24.056-26.38 42.049 1.474 84.184.439 126.281.439C76.676 179.831 26.437 130.013 5.876 52.179z"
            />
          </clipPath>
        </defs>
      </svg>
    </MainSlider>
  )
}

export default Slider
