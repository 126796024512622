import styled from "styled-components"
import { mediaQueries } from "@styles"

const Section = styled.section`
  padding: 80px 0;
`

const Wrapper = styled.div`
  @media ${mediaQueries.medium} {
    width: ${(100 * 10) / 12}%;
    margin: 0 auto;
  }
`

const Block = styled.div`
  @media ${mediaQueries.preMedium} {
    text-align: center;
  }
`

const Image = styled.div`
  margin-bottom: 16px;
  @media ${mediaQueries.medium} {
    margin-bottom: 24px;

    height: 108px;
    display: flex;
    align-items: flex-end;
  }

  @media ${mediaQueries.preMedium} {
    .gatsby-image-wrapper {
      margin: 0 auto;
    }
    zoom: 0.5;
  }
`

export { Section, Image, Block, Wrapper }
