import React from "react"
import { linkResolver } from "@utils/linkResolver"

import * as prismicH from "@prismicio/helpers"
import { P1 } from "@styles"

import { ApiResult, Title, ThisTag as Tag } from "./style"

const MAX_LENGTH = 120

const Result = ({ lang, uid, type, data = {} }) => {
  const title = data.title || {}
  const intro = data.intro || {}
  const categoryDoc = data.category || {}
  const categoryData = categoryDoc.data || {}
  const category = categoryData.category_name

  const shortText = React.useMemo(() => {
    const text = prismicH.asText(intro)
    const textLength = text.length
    if (textLength > MAX_LENGTH) return `${text.substring(0, MAX_LENGTH)}...`
    return text
  }, [intro])

  return (
    <ApiResult>
      <a href={linkResolver({ type, lang, uid })}>
        {category && <Tag>{category}</Tag>}
        <Title as="h3">{prismicH.asText(title)}</Title>
        <P1>{shortText}</P1>
      </a>
    </ApiResult>
  )
}

export default Result
