import React from "react"

export default function useLottieAnimation(json) {
  const animation = React.useRef()
  const animObj = React.useRef()
  const wait = React.useRef(null)

  React.useEffect(() => {
    import("lottie-web")
      .then(({ default: lottie }) => lottie)
      .then(lottie => {
        return lottie.loadAnimation({
          container: animation.current, // the dom element that will contain the animation
          renderer: "svg",
          loop: true,
          autoplay: false,
          path: json.url,
        })
      })
      .then(lottieAnimation => {
        animObj.current = lottieAnimation
        wait.current = setInterval(() => {
          if (lottieAnimation.isLoaded) {
            clearInterval(wait.current)
            wait.current = null
            lottieAnimation.play()
          }
        }, 100)
      })

    return () => {
      wait.current && clearInterval(wait.current)
      if (animObj.current) animObj.current.destroy()
    }
  }, [json])

  return animation
}
