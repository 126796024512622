import styled from "styled-components"
import mediaQueries from "./media-helper"
import variables from "./variables"

/***
 * Slider controls
 */
export const Controls = styled.div`
  position: absolute;
  height: 0;
  z-index: 1;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  max-width: 1220px;
`

export const Arrow = styled.button`
  width: 32px;
  height: 32px;

  border-radius: 7px;
  border: 1px solid ${variables.blue};

  text-indent: 99999999px;

  position: absolute;
  background-position: center;
  background-size: 14px;
  ${({ active }) => {
    return active
      ? `
        cursor: pointer;
        background-color: ${variables.blue};
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMuMTI1IDEwaDEzLjc1TTExLjI1IDQuMzc1TDE2Ljg3NSAxMGwtNS42MjUgNS42MjUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjc1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);
        transition: .3s transform;
        &:hover {
          transform: scale(.95);
          background-color: ${variables.darkBlue};
        }
      `
      : `
        display: none;
        cursor: no-drop;
        background-color: ${variables.white};
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMuMTI1IDEwaDEzLjc1TTExLjI1IDQuMzc1TDE2Ljg3NSAxMGwtNS42MjUgNS42MjUiIHN0cm9rZT0iIzJEODFGRiIgc3Ryb2tlLXdpZHRoPSIxLjc1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4K);
      `
  }}

  @media ${mediaQueries.siteWidth} {
    width: 62px;
    height: 62px;
    border-radius: 14px;
    background-size: 24px;
  }
`
