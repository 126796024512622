import * as React from "react"
import { allSlices } from "../../utils/slices"
import styled from "styled-components"

const CustomSliceZone = ({ slices, resolver = {} }) => {
  return slices.map(slice => {
    const Component = resolver[slice.slice_type]
    return (
      Component && (
        <section
          id={slice.slice_type}
          style={{ padding: "20px 0 200px 0", border: "1px solid grey" }}
        >
          <h2 style={{ padding: "0 16px 16px 16px" }}>
            <a
              href={`#${slice.slice_type}`}
              style={{ color: "black", textDecoration: "underline" }}
            >
              #{slice.slice_type}
            </a>
          </h2>
          <Component key={slice.id} slice={slice} />
        </section>
      )
    )
  })
}

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
`

const DocumentationLayout = ({ slices }) => {
  let sliceTypeArray = []

  {
    /*
      @Todo 
      - Redo this using filter
    */
  }
  {
    slices.map(slice => {
      sliceTypeArray.push(slice.slice_type)
    })
  }
  return (
    <Wrapper>
      <aside
        style={{
          position: "fixed",
          overflowY: "auto",
          border: "1px solid grey",
          width: 300,
          height: "100vh",
          padding: "32px",
          backgroundColor: "#fff",
          zIndex: 100,
        }}
      >
        <h1 style={{ fontSize: "1.3rem", marginBottom: 32 }}>Slices Goodmed</h1>
        {sliceTypeArray.map((sliceType, idx) => {
          return (
            <p key={idx} style={{ marginBottom: 10 }}>
              <a
                href={`#${sliceType}`}
                style={{ color: "black", textDecoration: "underline" }}
              >
                {sliceType}
              </a>
            </p>
          )
        })}
      </aside>
      <div style={{ paddingLeft: "300px" }}>
        <CustomSliceZone slices={slices} resolver={allSlices} />
      </div>
    </Wrapper>
  )
}

export default DocumentationLayout
