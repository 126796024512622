import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Image } from "./style"
import { mediaQueries } from "@styles"

const BlogpostFeaturedImage = ({ image, alt }) => {
  const gatsbyImg = getImage(image)
  return (
    <Image>
      <GatsbyImage
        image={gatsbyImg}
        alt={alt}
        sizes={`100vw, (min-width: ${
          mediaQueries.breakpoints.sm + 1
        }px) 1963px`}
      />
    </Image>
  )
}

export default BlogpostFeaturedImage
