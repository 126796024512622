import styled, { keyframes } from "styled-components"

import { mediaQueries, variables, T4, Tag } from "@styles"

const gutter = "48px"

const Wrapper = styled.div`
  margin: 0 auto;
  @media ${mediaQueries.large} {
    width: ${(100 * 10) / 12}%;
  }
`

const FlexBox = styled.div`
  padding: 64px 0;

  display: flex;
  flex-flow: row wrap;
  width: calc(100% + ${gutter});
  margin-left: -${gutter};

  @media ${mediaQueries.medium} {
    flex-flow: row nowrap;
  }
`

const Bar = styled.div`
  position: relative;
  padding-left: ${gutter};
  flex: 0 0 auto;
  max-width: 510px;
  width: 100%;
  margin-bottom: 24px;

  input {
    display: block;
    height: 52px;
    line-height: 52px;

    background-color: ${variables.lightBlue};
    border: none;
    width: 100%;

    border-radius: 16px;
    padding-left: 62px;
    padding-right: 24px;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTExLjMyOCAxOS41MzFhOC4yMDMgOC4yMDMgMCAxMDAtMTYuNDA2IDguMjAzIDguMjAzIDAgMDAwIDE2LjQwNnpNMTcuMTI4IDE3LjEyOWw0Ljc0NyA0Ljc0NiIgc3Ryb2tlPSIjMkQ4MUZGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);
    background-position: left 24px center;
    background-size: 24px;
    outline: 0 !important;
    border: 1px solid rgba(0, 0, 0, 0);
  }

  input:focus,
  input:active {
    border: 1px solid ${variables.blue};
  }

  input,
  input::placeholder {
    color: ${variables.blue};
    font-size: 15px;
  }
`

const Filters = styled.div`
  padding-left: ${gutter};
  flex: 1 1 auto;
`

const Filter = styled.li`
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-bottom: 8px;

  @media ${mediaQueries.large} {
    margin-right: 16px;
    margin-bottom: 16px;
  }

  @media ${mediaQueries.siteWidth} {
    margin-right: 24px;
  }

  a {
    cursor: pointer;
    display: block;
    padding: 12px 20px;
    border-radius: 20px;

    font-size: 15px;
    line-height: 19px;

    color: ${variables.blue};
    border: 1px solid #e5e5e5;
    font-family: "stolz";
    transition: 0.3s transform;
  }

  a:hover,
  a.active {
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: ${variables.blue};
  }

  a:hover {
    transform: scale(0.95);
  }
`
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Loader = styled.span`
  position: absolute;
  width: 24px;
  height: 24px;

  border-width: 2px;
  border-color: ${variables.blue} transparent;
  border-radius: 100%;
  border-style: solid;

  right: ${24 + 32}px;
  top: 14px;
  animation: ${rotate} 2s linear infinite;
`

const Results = styled.div`
  position: absolute;
  z-index: 2;
  top: 56px;
  left: ${gutter};
  width: cacl(100% - ${gutter});

  background-color: #fff;
  padding: 16px;
  border: 1px solid ${variables.blue};
  border-radius: 16px;

  max-height: 320px;
  overflow: auto;
`
const ApiResult = styled.li`
  a {
    color: #000;
    display: block;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid ${variables.blue};
  }

  &:first-child a {
    border: none;
    padding-top: 0;
  }

  &:last-child a {
    padding-bottom: 0;
  }
`

const Title = styled(T4)`
  margin-bottom: 8px;
`

const ThisTag = styled(Tag)`
  margin-bottom: 4px;
`

const Clear = styled.button`
  border: none;
  padding: 0;
  background: transparent;

  z-index: 2;
  position: absolute;
  top: 14px;
  right: 24px;

  width: 24px;
  height: 24px;

  cursor: pointer;
  span {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 16px;
    height: 2px;
    background-color: ${variables.blue};
  }

  span:first-child {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  span:last-child {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

export {
  FlexBox,
  Bar,
  Filters,
  Filter,
  Wrapper,
  Loader,
  Results,
  ApiResult,
  Title,
  ThisTag,
  Clear,
}
