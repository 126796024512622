import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "@components/seo"
import { SliceZone } from "@prismicio/react"
import { allSlices } from "../utils/slices"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import DocumentationLayout from "../components/DocumentationLayout"

const GoodmedPage = ({ data = {} }) => {
  const langParams = data.langParams || {}
  const navigation = data.prismicNavigation || {}
  const doc = data.prismicGoodmedPage || {}
  const docData = doc.data || {}
  const slices = docData.body || []
  const noIndex = docData.is_not_index || docData.documentation

  return (
    <>
      <Seo
        title={docData.meta_title}
        description={docData.meta_description}
        openGraphImg={docData.open_graph && docData.open_graph.url}
        noIndex={noIndex ? true : false}
      />
      {docData.documentation ? (
        <DocumentationLayout slices={slices} />
      ) : (
        <Layout navigation={navigation} languages={langParams}>
          <SliceZone slices={slices} components={allSlices} />
        </Layout>
      )}
    </>
  )
}

export default withPrismicPreview(GoodmedPage)

export const query = graphql`
  query GoodmedPage($id: String, $lang: String) {
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
    langParams: prismicGoodmedPage(id: { eq: $id }, lang: { eq: $lang }) {
      uid
      lang
      url
      type
      alternate_languages {
        uid
        type
        lang
      }
    }
    prismicGoodmedPage(id: { eq: $id }, lang: { eq: $lang }) {
      lang
      url
      uid
      type
      _previewable
      data {
        meta_description
        meta_title
        documentation
        is_not_index
        open_graph {
          url
        }
        body {
          ... on PrismicHomeHeaderDefault {
            ...HomeHeader
          }
          ... on PrismicHomeFeaturesDefault {
            ...HomeFeatures
          }
          ... on PrismicHomeCompaniesSliderDefault {
            ...HomeCompaniesSlider
          }
          ... on PrismicHomeAppDefault {
            ...HomeApp
          }
          ... on PrismicHomeAppScreensDefault {
            ...HomeAppScreens
          }
          ... on PrismicHomeAppPremiumDefault {
            ...HomeAppPremium
          }
          ... on PrismicHomeAppDownloadDefault {
            ...HomeAppDownload
          }
          ... on PrismicInsurancesSectionLeft {
            ...InsurancesSectionLeft
          }
          ... on PrismicInsurancesSectionRight {
            ...InsurancesSectionRight
          }
          ... on PrismicHomeArticlesDefault {
            ...HomeArticles
          }
          ... on PrismicHomeTestimonialsSliderDefault {
            ...HomeTestimonialsSlider
          }
          ... on PrismicMentionDmDefault {
            ...MentionDm
          }
          ... on PrismicVideoDefault {
            ...Video
          }
          ... on PrismicAboutUsContentDefault {
            ...AboutUsContent
          }
          ... on PrismicAboutUsHeaderDefault {
            ...AboutUsHeader
          }
          ... on PrismicAboutUsAppStoreDefault {
            ...AboutUsStores
          }
          ... on PrismicAboutUsTeamDefault {
            ...AboutUsTeam
          }
          ... on PrismicBoxIconDefault {
            ...BoxIcon
          }
          ... on PrismicPodcastHeaderDefault {
            ...PodcastHeader
          }
          ... on PrismicTitlePodcastDefault {
            ...TitlePodcast
          }
          ... on PrismicCollapseDefault {
            ...Collapse
          }
          ... on PrismicFaqHeaderDefault {
            ...FaqHeader
          }
          ... on PrismicContentBackgroundDefault {
            ...ContentBackground
          }
          ... on PrismicInsurancesFeaturesDefault {
            ...InsurancesFeatures
          }
          ... on PrismicInsurancesOfferDefault {
            ...InsurancesOffer
          }
          ... on PrismicInsurancesOfferStore {
            ...InsurancesOfferStore
          }
          ... on PrismicPremiumFaqDefault {
            ...PremiumFaq
          }
          ... on PrismicPremiumAppDefault {
            ...PremiumApp
          }
          ... on PrismicPremiumSubscribeDefault {
            ...PremiumSubscribe
          }
          ... on PrismicPremiumHowDefault {
            ...PremiumHow
          }
          ... on PrismicPremiumHeaderDefault {
            ...PremiumHeader
          }
          ... on PrismicPremiumFeaturesDefault {
            ...PremiumFeatures
          }
          ... on PrismicHiwHeaderDefault {
            ...SolutionHeader
          }
          ... on PrismicHiwStepsDefault {
            ...SolutionSteps
          }
          ... on PrismicHiwFeaturesDefault {
            ...SolutionFeatures
          }
          ... on PrismicInsuranceRegisterHeaderDefault {
            ...InsuranceRegisterHeader
          }
        }
      }
    }
  }
`
