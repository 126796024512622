import React from "react"
import { graphql } from "gatsby"

import { Container, Grid, GridItem, ButtonBlueBig } from "@styles"
import { Section, Content, Title, Button } from "./style"

const colors = ["secondaryMedium", "pinkMedium", "tertiaryMedium"]

const Feature = ({ title, content, index }) => {
  return (
    <GridItem col={{ md: 4, lg: 4 }}>
      <Title as="h3" color={colors[index]}>
        <span>
          {title}
          <svg width="66" height="65" viewBox="0 0 66 65">
            <path d="M29.282 50.07c-1.11 1.048-2.279 1.992-3.254 3.103-2.377 2.711-2.293 6.69.094 9.313 2.361 2.592 6.569 3.235 9.295.943 9.373-7.882 18.637-15.892 27.862-23.945 2.214-1.933 2.693-5.576 1.45-7.91-1.621-3.037-4.535-4.482-8.03-3.823-2.464.464-4.894 1.11-7.509 1.714.163-1.744 1.43-2.134 2.183-2.925 2.372-2.492 3.038-5.878 1.618-8.478-1.707-3.125-4.343-4.32-8.105-3.61-1.256.236-2.478.652-3.735.886-1.227.229-2.383.919-3.998.592.518-1.313 1.696-1.874 2.524-2.705 2.96-2.973 3.191-6.99.552-10.001-2.512-2.868-6.638-3.386-9.76-.769-9.054 7.597-18.012 15.312-26.907 23.094C.669 28.08.228 31.502 1.929 34.49c1.675 2.944 4.747 4.094 8.579 3.22 1.45-.33 2.9-.667 4.36-.947.277-.053.604.148.916.234-.069 1.135-.705 1.92-1.364 2.748-2.052 2.577-2.485 5.378-.718 8.266 1.807 2.956 4.513 3.905 8.334 3.04 1.873-.426 3.727-.932 5.595-1.377.572-.136 1.23-.464 1.651.396z" />
          </svg>
        </span>
      </Title>
      <Content>{content}</Content>
    </GridItem>
  )
}

const HomeFeatures = ({ slice }) => {
  const items = slice.items || []
  const primary = slice.primary || {}
  const link = primary.link || {}
  return (
    <Section>
      <Container>
        <Grid>
          {items.map((feature, key) => (
            <Feature
              {...feature}
              index={key % colors.length}
              key={`feature-${key}`}
            />
          ))}
        </Grid>

        <Button>
          <ButtonBlueBig as="a" href={link.url}>
            {primary.button}
          </ButtonBlueBig>
        </Button>
      </Container>
    </Section>
  )
}

export default HomeFeatures

export const query = graphql`
  fragment HomeFeatures on PrismicHomeFeaturesDefault {
    id
    variation
    slice_type
    items {
      title
      content
    }
    primary {
      button
      link {
        url
      }
    }
  }
`
