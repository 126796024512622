import styled from "styled-components"

import { mediaQueries } from "@styles"

const Section = styled.section`
  position: relative;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAxMyIgaGVpZ2h0PSIxMDgxIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xOTQ0LjA3IDBMMCAxMzUuOTRsNjYuODI1IDY1Mi40MDhjMTcuNzQzIDE3My4yMjQgMTcyLjk0NCAzMDMuODAxIDM0Ni42NSAyOTEuNjYxbDEzMTUuMDE1LTkxLjk1OGMxNzMuNzEtMTIuMTQ3IDMwMC4xNC0xNjIuNDIgMjgyLjQtMzM1LjY0NUwxOTQ0LjA3IDB6IiBmaWxsPSIjRkVGOEVDIi8+PC9zdmc+);
  background-position: bottom -80px right;
  background-size: 2003px;
  width: 100%;
  overflow: hidden;

  padding: 88px 0 64px;
  @media ${mediaQueries.medium} {
    padding: 157px 0 180px;
    background-position: top -260px center;
  }

  h1 {
    margin-bottom: 24px;
  }

  p + p {
    margin-top: 24px;
  }

  strong {
    font-weight: 700;
  }
`

const Image = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  width: 1280px;

  img {
    left: -25%;
  }

  clip-path: url(#about-us-header);

  @media ${mediaQueries.large} {
    display: block;
  }
`

const Wrapper = styled.div`
  @media ${mediaQueries.large} {
    width: 50%;
  }

  svg {
    display: block;
    margin: 0 0 24px;
  }
`

export { Section, Image, Wrapper }
