import { graphql } from "gatsby"
import React from "react"
import { Section, Wrapper } from "../../premium-components/premium-header/style"
import { Container, T2, P00, GridCentered, GridItem, ButtonBlue } from "@styles"
import { PrismicRichText } from "@prismicio/react"
import { isMobile } from "react-device-detect"

function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  return hasMounted
}

const Header = ({ slice }) => {
  const primary = slice.primary
  const hasMounted = useHasMounted()

  if (!hasMounted) {
    return null
  }

  const title = primary.title || ""
  const description = primary.content.richText || ""

  return (
    <Wrapper>
      <Section>
        <Container>
          <GridCentered>
            <GridItem col={{ md: 12, lg: 9 }}>
              <T2 stolz weight="700" mb={2}>
                {title}
              </T2>
              <PrismicRichText
                field={description}
                components={{
                  paragraph: ({ children }) => <P00 mb={2}>{children}</P00>,
                }}
              />
            </GridItem>
            <GridItem col={{ sm: 12, lg: 3 }}>
              {isMobile && (
                <div>
                  <ButtonBlue as="a" href={primary.link.url} target="_blank">
                    <span>{primary.button}</span>
                  </ButtonBlue>
                </div>
              )}
            </GridItem>
          </GridCentered>
        </Container>
      </Section>
    </Wrapper>
  )
}

export default Header

export const query = graphql`
  fragment InsuranceRegisterHeader on PrismicInsuranceRegisterHeaderDefault {
    id
    slice_type
    primary {
      title
      content {
        richText
      }
      content {
        richText
      }
      button
      link {
        url
        target
      }
    }
  }
`
