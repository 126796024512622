import React from "react"
import { graphql } from "gatsby"
import { Container, T4, ButtonBlueBig } from "@styles"
import {
  Section,
  Background,
  Block,
  Questions,
  Article,
  Button,
  Arrow,
  Title,
  FaqTitle,
} from "./style"

const Question = ({ title, contentfaq }) => {
  const [active, setActive] = React.useState(false)

  const toggle = React.useCallback(
    evt => {
      evt.preventDefault()
      setActive(state => !state)
    },
    [setActive]
  )

  return (
    <Article active={active}>
      <Arrow onClick={toggle} active={active} />
      <Title onClick={toggle} as="h3">
        {title}
      </Title>
      <T4
        as="div"
        dangerouslySetInnerHTML={{ __html: contentfaq && contentfaq.html }}
      />
    </Article>
  )
}

const Collapse = ({ slice }) => {
  const primary = slice.primary || {}
  const items = slice.items || []
  const link = primary.link || {}

  return (
    <Section>
      <Background>
        <Container>
          <Block>
            <FaqTitle as="h2" mb={4} mbmd={8}>
              {primary.title}
            </FaqTitle>
            <Questions>
              <div>
                {items.map((question, key) => (
                  <Question key={`question-${key}`} {...question} />
                ))}
              </div>
            </Questions>
            <Button>
              <ButtonBlueBig as="a" href={link.url}>
                {primary.button}
              </ButtonBlueBig>
            </Button>
          </Block>
        </Container>
      </Background>
    </Section>
  )
}

export default Collapse

export const query = graphql`
  fragment Collapse on PrismicCollapseDefault {
    id
    slice_type
    variation
    primary {
      title
      button
      link {
        url
      }
    }
    items {
      contentfaq: content {
        html
      }
      title
    }
  }
`
