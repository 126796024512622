import React, { useState, useEffect } from "react"
import Logo from "@components/Logo"
import { T8, mediaQueries } from "@styles"

import { Wrapper, Container } from "../Header/style"

import styled from "styled-components"

const StyledContainer = styled(Container)`
  @media ${mediaQueries.preMedium} {
    > div:first-child {
      img {
        max-width: 150px;
      }
    }
  }
`

const affixDelta = 40

const HeaderInsurance = ({ backgroundColor, backgroundHeader, logo }) => {
  const [bgColor, setBackground] = useState(null)
  const [affix, setState] = useState(false)

  const handleScroll = React.useCallback(() => {
    if (!affix && window.scrollY > affixDelta) setState(true)
    if (affix && window.scrollY <= affixDelta)
      setState(backgroundHeader ? true : false)
  }, [setState, affix])

  useEffect(() => {
    if (affix) {
      setBackground(backgroundColor)
    } else {
      setBackground(backgroundHeader ? backgroundColor : null)
    }
  }, [affix, backgroundColor])

  // Affix
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    window.addEventListener("load", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [handleScroll])

  return (
    <Wrapper bgColor={bgColor}>
      <StyledContainer>
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <Logo />
          <T8 as="span" stolz weight="500">
            &
          </T8>{" "}
          <img src={logo.url} alt={logo.alt} style={{ maxHeight: 40 }} />
        </div>
      </StyledContainer>
    </Wrapper>
  )
}

export default HeaderInsurance
