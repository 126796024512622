import styled from "styled-components"
import variables from "./variables"

const Trame = styled.div`
  position: relative;
  z-index: 1;

  max-width: ${variables.trame};
  width: 100%;
  margin: 0 auto;
  padding: 0;
`
export default Trame
