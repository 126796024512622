import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Member from "./team-member"
import Filter from "./filter"

import { Trame, Container } from "@styles"

import {
  Title,
  Content,
  TeamMembers,
  ThisFilters as Filters,
  Image,
  Background,
  Wrapper,
  MembersWrapper,
} from "./style"

const Team = ({ slice }) => {
  const primary = slice.primary
  const items = slice.items
  const title = primary.title || ""
  const content = primary.content || {}
  const image = getImage(primary.icon)

  const filters = React.useMemo(() => {
    let teams = []
    const mixedTeams = items.map(({ team }) => team.split(","))

    for (let i = 0; i < mixedTeams.length; i++) {
      if (Array.isArray(mixedTeams[i])) {
        teams = teams.concat(mixedTeams[i])
      } else {
        teams.push(mixedTeams[i])
      }
    }

    const result = []

    for (let i = 0; i < teams.length; i++) {
      if (result.indexOf(teams[i]) === -1) {
        result.push(teams[i])
      }
    }

    return result
  }, [items])

  const [currentFilter, setFilter] = React.useState(filters[0])

  const handleFilterChange = React.useCallback(
    evt => setFilter(evt.target.value),
    [setFilter]
  )

  return (
    <Trame>
      <Background />
      <Container>
        <Wrapper>
          <Image>
            <GatsbyImage image={image} sizes={`${image.width}px`} alt={title} />
          </Image>
          <Title as="h2">
            <span>{title}</span>
          </Title>
          <Content>{content.text}</Content>

          <form onSubmit={evt => evt.preventDefault()}>
            <Filters>
              {filters.map(filter => (
                <Filter
                  key={filter}
                  onChange={handleFilterChange}
                  active={currentFilter === filter}
                >
                  {filter}
                </Filter>
              ))}
            </Filters>
          </form>

          <MembersWrapper>
            <TeamMembers>
              {items.map((member, key) => (
                <Member
                  {...member}
                  key={`member-${key}`}
                  active={member.team.split(",").indexOf(currentFilter) > -1}
                />
              ))}
            </TeamMembers>
          </MembersWrapper>
        </Wrapper>
      </Container>

      <svg width="0" height="0">
        <defs>
          <clipPath
            transform="scale(.00535)"
            clipPathUnits="objectBoundingBox"
            id="memberN1"
          >
            <path
              d="M81.41.771c20.409 0 40.83.436 61.225-.125 19.21-.527 32.214 8.384 39.91 25.113 7.629 16.583 3.44 35.205-9.946 48.631-5.935 5.952-11.739 12.17-18.381 17.245-7.554 5.776-7.67 11.697-4.141 19.819 9.41 21.644 17.936 43.673 27.262 65.358 3.084 7.175 1.257 9.181-6.072 9.154-44.02-.151-88.047.364-132.055-.349-16.037-.262-27.817-9.417-34.237-24.338-6.942-16.142-4.947-31.629 6.201-45.101 6.596-7.975 14.465-14.95 22.154-21.942 5.784-5.26 7.387-10.072 3.587-17.753-10.826-21.88-20.52-44.319-31.14-66.305C2.156 2.678 3.53.508 11.78.623c23.207.322 46.42.11 69.63.11v.04z"
              fill="#fff"
            />
          </clipPath>
          <clipPath
            transform="scale(.00538 .00535)"
            clipPathUnits="objectBoundingBox"
            id="memberN2"
          >
            <path
              d="M185.177 112.184c-.14 1.071-.353 2.146-.566 3.218-8.225 39.82-46.563 70.627-90.331 70.604-29.334-.023-56.112-13.646-73.102-34.62a87.013 87.013 0 01-7.429-10.654c-8.026-13.564-8.13-30.4.31-43.727.043-.066.088-.135.128-.203 5.548-8.692 12.87-16.976 22.275-24.826-7.06 0-10.472.02-13.886 0C.657 71.814.633 71.814.612 50.206.588 36.147.588 22.116.657 8.058.681 4.727.114.938 5.586.848 33.666.413 61.84-.936 87.078 14.607c8.244 5.086 15.024 11.866 23.86 19.784 0-10.111.263-17.916-.095-25.672-.258-5.728 1.495-8.102 7.938-7.987 19.715.317 39.43.091 59.148.114 3.599 0 7.322-.25 7.367 4.93.334 28.113 2.277 56.57-16.516 81.01-2.678 3.49-5.569 6.822-8.602 10.268a479.282 479.282 0 0015.168-.297c9.265-.342 11.348 2.465 10.45 11.022a76.776 76.776 0 01-.435 3.311c-.057.365-.118.729-.184 1.093z"
              fill="#fff"
            />
          </clipPath>
          <clipPath
            transform="scale(.00538 .00535)"
            clipPathUnits="objectBoundingBox"
            id="memberN3"
          >
            <path
              d="M158.476 123.019c4.064-2.39 8.482-4.183 12.546-6.573 6.971-4.112 11.413-9.084 13.4-15.012 1.489-4.446 1.604-9.418.402-14.988-.121-.502-.239-1.004-.428-1.506-2.883-10.063-12.557-18.573-24.666-18.573-23.29 0 3.897-11.737 11.883-16.422 6.285-3.681 10.515-8.797 12.525-14.773 1.605-4.757 1.817-10.064.542-15.633C182.482 9.882 172.298.726 161.453.655 138.252.487 55.86.94 37.876.965c-.919.024-1.817.072-2.74.096C13.87 3.81-.19 19.204.566 38.949c.759 20.08 16.991 35.784 37.879 36.478 5.621.167 11.245.023 16.868.023-15.827 16.829-36.151 38.892-43.429 46.661-18.64 19.936-14.688 51.003 10.493 60.692 11.974 4.608 124.803 2.941 137.636 2.773 12.45-.167 21.594-8.055 24.382-18.836.591-2.271.924-4.661.924-7.123 0-2.654-.307-5.188-.948-7.554-2.859-10.972-12.168-18.454-24.88-19.458-15.301-1.173-4.984-7.219-1.016-9.586z"
              fill="#fff"
            />
          </clipPath>
          <clipPath
            transform="scale(.00535 .00538)"
            clipPathUnits="objectBoundingBox"
            id="memberN4"
          >
            <path
              d="M164.288 181.953c24.312-12.092 29.371-40.736 10.574-60.658-7.337-7.777-16.126-17.207-43.781-46.643 5.667 0 11.336.158 16.999-.026 21.048-.688 37.418-16.374 38.188-36.465.755-19.714-13.421-35.108-34.86-37.858-.918-.033-1.835-.062-2.749-.095C111.757.15 74.856-.184 37.961.138 16.522.322 1.238 15.618 1.08 37.165c-.336 46.55-.024 93.109-.17 139.663-.016 5.606.632 8.618 7.557 8.56 48.4-.436 96.805-.45 145.2-.596 5.563 0 7.925-1.498 10.622-2.839z"
              fill="#fff"
            />
          </clipPath>
          <clipPath
            transform="scale(.0054 .00535)"
            clipPathUnits="objectBoundingBox"
            id="memberN5"
          >
            <path
              d="M77.585 172.875c-14.587 12.47-30.752 16.984-48.885 8.885-17.685-7.905-27.667-21.752-27.826-41.356-.35-43.158.061-86.321-.31-129.482C.492 2.544 3.448.583 11.26.756c22.504.489 45.037.662 67.531.007C88.626.48 90.784 2.946 91.215 13.624c.092 5.577.377 16.818.377 21.144L95.545 29c3.986-5.045 7.42-10.685 12.06-15.018C121.756.77 138.502-3.096 156.411 4.408c18.257 7.655 28.163 22.278 28.308 42.339.312 43.158-.037 86.323.28 129.479.055 7.712-2.613 9.803-9.974 9.675-22.917-.391-45.856-.551-68.765.081-9.35.254-12.047-1.357-12.362-12.094.104-6.134.106-8.85.106-15.016-6.846 5.615-11.512 9.81-16.42 14.003z"
              fill="#fff"
            />
          </clipPath>
          <clipPath
            transform="scale(.00538)"
            clipPathUnits="objectBoundingBox"
            id="memberN6"
          >
            <path
              d="M92.882 185.128c-27.583 0-55.168-.187-82.747.129-7.04.082-9.84-1.642-9.737-9.273.394-29.059-.349-58.135.214-87.186C1.515 42.038 40.965 2.964 89.292.17c45.431-2.624 89.934 34.662 96.194 79.978 1.346 9.735-1.848 12.811-11.162 12.481-14.772-.522-29.563-.514-44.345-.498-3.369.003-8.582-1.47-9.48 2.629-.983 4.498 4.57 4.304 7.572 5.673 32.175 14.701 51.429 39.094 57.233 74.152 1.461 8.823-1.144 10.973-9.678 10.837-27.576-.441-55.161-.163-82.744-.163v-.132z"
              fill="#fff"
            />
          </clipPath>
          <clipPath
            transform="scale(.00538)"
            clipPathUnits="objectBoundingBox"
            id="memberN7"
          >
            <path
              d="M22.742 111.161c-21.926.164-21.947.167-21.985 22.656a6443.69 6443.69 0 00.066 43.873c.017 3.463-.554 7.403 4.922 7.493 28.093.461 56.287 1.877 81.53-14.319 8.26-5.299 15.026-12.341 23.872-20.592 0 10.536.272 18.644-.09 26.724-.271 5.967 1.503 8.437 7.94 8.325 19.723-.347 39.456-.109 59.184-.119 3.608-.002 7.321.25 7.383-5.132.322-29.271 2.271-58.883-16.53-84.328-4.841-6.555-11.953-14.01-17.802-21.069 9.002 0 17.643-.147 25.23 0 6.304.121 9.346-1.401 9.203-8.522-.379-19.36-.391-38.735.005-58.094.135-6.513-2.368-8.028-8.267-8.017C120.974.164 64.548.114 8.119.046.363.036.968 19.476.689 23.777-1.499 57.28 7.95 86.24 36.641 111.16c-7.07 0-10.486-.024-13.9.002z"
              fill="#fff"
            />
          </clipPath>
          <clipPath
            transform="scale(.00538)"
            clipPathUnits="objectBoundingBox"
            id="memberN8"
          >
            <path
              d="M22.905 52.292C9.569 50.41.044 38.947.325 25.712.607 12.625 10.863.56 24.154.404c45.698-.522 91.403-.417 137.104-.093 10.967.077 21.254 9.218 23.479 18.853 2.894 12.54-.872 25.555-13.204 30.373-9.241 3.61-27.249 10.008-25.323 13.435 1.55 2.557 9.255 2.557 13.317 2.96 12.782 1.275 22.961 9.237 25.346 20.051 2.866 12.999-1.616 22.799-13.937 29.956-4.115 2.389-26.576 10.708-25.376 14.648.65 3.254 9.365 1.175 13.733 1.509 15.616 1.203 26.125 12.008 26.089 26.954-.031 14.236-10.366 25.75-25.564 25.943-44.103.554-88.223.539-132.333.181-14.346-.117-23.981-8.725-26.53-21.328-2.511-12.409 3.007-24.889 15.928-31.75 4.89-2.598 8.81-3.627 14.032-5.449 6.407-2.236 12.761-3.579 10.673-4.622-2.089-1.044-14.507-2.73-18.392-3.47C11.102 116.261 3.014 109.39.899 96.917c-2.169-12.77 3.113-22.044 15.984-28.898 6.31-3.359 12.767-6.44 19.114-9.73 1.941-1.008 8.136-3.891 3.673-5.143-5.69-.26-11.295-.083-16.765-.854z"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
    </Trame>
  )
}

export default Team

export const query = graphql`
  fragment AboutUsTeam on PrismicAboutUsTeamDefault {
    id
    slice_type
    primary {
      title
      content {
        text
      }
      icon {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
    }
    items {
      job
      name
      team
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          srcSetMaxWidth: 185
          imgixParams: { q: 100 }
        )
      }
    }
  }
`
