import * as React from "react"
import { graphql } from "gatsby"
import { SliceZone } from "@prismicio/react"

import Layout from "@components/layout"
import Seo from "@components/seo"

import { HomeTestimonialsSlider } from "./home-components"
import {
  PremiumHeader,
  PremiumHow,
  PremiumFeatures,
  PremiumApp,
  PremiumSubscribe,
  PremiumFaq,
} from "./premium-components"
import { MentionDm } from "@slices"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

const Premium = ({ data = {} }) => {
  const langParams = data.langParams || {}
  const navigation = data.prismicNavigation || {}
  const doc = data.prismicPremium || {}
  const docData = doc.data || {}
  const slices = docData.body || []

  return (
    <Layout navigation={navigation} languages={langParams}>
      <Seo
        title={docData.meta_title}
        description={docData.meta_description}
        openGraphImg={docData.open_graph && docData.open_graph.url}
      />
      <SliceZone
        slices={slices}
        components={{
          home_testimonials_slider: HomeTestimonialsSlider,
          premium_header: PremiumHeader,
          premium_how: PremiumHow,
          premium_features: PremiumFeatures,
          premium_app: PremiumApp,
          premium_subscribe: PremiumSubscribe,
          premium_faq: PremiumFaq,
          mention_dm: MentionDm,
        }}
      />
    </Layout>
  )
}

export default withPrismicPreview(Premium)

export const query = graphql`
  query Premiumpage($lang: String) {
    langParams: prismicPremium(lang: { eq: $lang }) {
      uid
      lang
      url
      type
      alternate_languages {
        uid
        type
        lang
      }
    }
    prismicPremium(lang: { eq: $lang }) {
      lang
      url
      type
      _previewable
      data {
        meta_description
        meta_title
        open_graph {
          url
        }
        body {
          ... on PrismicHomeTestimonialsSliderDefault {
            ...HomeTestimonialsSlider
          }
          ... on PrismicPremiumFaqDefault {
            ...PremiumFaq
          }
          ... on PrismicPremiumAppDefault {
            ...PremiumApp
          }
          ... on PrismicPremiumSubscribeDefault {
            ...PremiumSubscribe
          }
          ... on PrismicPremiumHowDefault {
            ...PremiumHow
          }
          ... on PrismicPremiumHeaderDefault {
            ...PremiumHeader
          }
          ... on PrismicPremiumFeaturesDefault {
            ...PremiumFeatures
          }
          ... on PrismicMentionDmDefault {
            ...MentionDm
          }
        }
      }
    }
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
  }
`
