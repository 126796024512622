import React from "react"
import Player from "./player"
import Platforms from "./platforms"

import { GridItem, P1, T4, P0 } from "@styles"

import {
  RegularPodcast,
  PodcastHeader,
  PodcastImage,
  RegularPodcastFooter,
} from "./style"

const Podcast = (data = {}) => {
  const category = data.category || ""
  const content = data.content
  const authorName = data.author_name || ""
  const acast = data.acast.length > 0 ? data.acast[0] : {}
  const platforms = data.platforms || []

  return (
    <GridItem col={{ md: 6, lg: 6 }} mbmd={3}>
      <RegularPodcast>
        <PodcastHeader>
          <PodcastImage>
            <div className="gatsby-image-wrapper">
              <img src={acast.cover} alt={acast.title} />
            </div>
          </PodcastImage>
          <div>
            <P1 as="span">PODCAST - {category}</P1>
            <P0 as="h3">{acast.title}</P0>
            <T4 as="p">{authorName}</T4>
          </div>
        </PodcastHeader>

        <P1>
          {acast.summary ? (
            <span dangerouslySetInnerHTML={{ __html: acast.summary }} />
          ) : (
            <p>{content.text}</p>
          )}
        </P1>

        <Platforms available_on={data.available_on} platforms={platforms} />

        <RegularPodcastFooter>
          <Player {...acast} />
        </RegularPodcastFooter>
      </RegularPodcast>
    </GridItem>
  )
}

export default Podcast
