import styled from "styled-components"
import { mediaQueries } from "@styles"

export const Section = styled.section`
  padding: 0px 0;
  @media ${mediaQueries.medium} {
    padding: 112px 0 80px;
  }

  h1 em {
    color: ${props => props.theme.colors.primary};
  }

  h2 + div {
    max-width: 996px;
  }

  .center {
    text-align: center;
  }
`

export const Block = styled.div`
  max-width: 508px;
  @media ${mediaQueries.medium} {
    div:nth-child(2n + 2) > & {
      margin-left: auto;
    }
  }
`
