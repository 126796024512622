import styled from "styled-components"
import React, { useRef, useState } from "react"
import Spinner from "./Spinner"
import { addListMember } from "../config/mailchimp"

const Subscribe = ({ placeholder, submitButton, successMessage }) => {
  // Create a reference to the input so we can fetch/clear it's value.
  const inputEl = useRef(null)
  // Hold a message in state to handle the response from our API.
  const [message, setMessage] = useState("")
  const [loader, setLoader] = useState(false)

  const subscribe = async e => {
    e.preventDefault()
    setLoader(true)

    let email = inputEl.current.value

    addListMember(email)

    // Clear the input value and show a success message.
    inputEl.current.value = ""
    setMessage(successMessage)
    setLoader(false)
  }

  return (
    <>
      <Form onSubmit={subscribe}>
        <Input
          id="email-input"
          name="email"
          placeholder={placeholder}
          ref={inputEl}
          required
          type="email"
        />
        <SubmitButton type="submit">
          {loader ? <Spinner /> : submitButton}
        </SubmitButton>
      </Form>
      <ValidationMessage>{message ? message : ""}</ValidationMessage>
    </>
  )
}

export default Subscribe

const Form = styled.form`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
`
const Input = styled.input`
  height: 48px;
  border: 1px solid #e5e5e5;
  border-radius: 14px;
  margin-right: 14px;
  padding-left: 10px;
  width: 100%;
  margin: 0 0 16px;
`

const SubmitButton = styled.button`
  background-color: #2d81ff;
  height: 48px;
  border-radius: 14px;
  border-width: 0;
  color: #fff;
  min-width: 130px;
  padding: 0 25px;
  font-family: "stolz";
  @media all and (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
  &:hover {
    cursor: pointer;
  }
`

const ValidationMessage = styled.div`
  color: #68daa0;
  font-size: 0.9rem;
  margin-top: 1rem;
`
