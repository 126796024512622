import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Container, T6 } from "@styles"
import { Section, Companies } from "./style"

const Company = ({ logo }) => {
  const image = getImage(logo)
  return (
    <div style={{ maxWidth: 240 }}>
      <GatsbyImage image={image} alt={image.alt} />
    </div>
  )
}

const HomeCompaniesSlider = ({ slice }) => {
  const primary = slice.primary
  const items = slice.items || []
  return (
    <Section>
      <Container>
        {primary.title && (
          <T6 center stolz weight="700" mbmd={6}>
            {primary.title}
          </T6>
        )}
        <Companies>
          {items.map((company, key) => (
            <Company {...company} key={`company-${key}`} />
          ))}
        </Companies>
      </Container>
    </Section>
  )
}

export default HomeCompaniesSlider

export const query = graphql`
  fragment HomeCompaniesSlider on PrismicHomeCompaniesSliderDefault {
    id
    slice_type
    variation
    primary {
      title
    }
    items {
      logo {
        gatsbyImageData
        alt
      }
    }
  }
`
