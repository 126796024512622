import styled from "styled-components"
import { mediaQueries, Container } from "@styles"

const CustomContainer = styled(Container)``

const Gradient = styled.div`
  position: relative;
  border-radius: 60px;
  padding: 80px 24px 24px;

  appearance: none;
  border: 0;
  background-color: transparent;
  z-index: 0;

  h2 {
    em {
      display: inline-block;
      background: linear-gradient(
        90deg,
        #ffe286 14.19%,
        #ff885d 50.04%,
        #ff9edb 84.48%
      );
      background-size: 1000px;
      background-position: left -400px center;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 60px;
    background: #fff8e2;
    background: linear-gradient(
        114.77deg,
        rgba(255, 158, 219, 0.15) 14.19%,
        rgba(255, 125, 79, 0.15) 29.5%,
        rgba(255, 226, 134, 0.15) 44.44%,
        rgba(255, 226, 134, 0.15) 53.78%,
        rgba(255, 136, 93, 0.15) 69.09%,
        rgba(255, 158, 219, 0.15) 84.48%
      ),
      #ffffff;
    left: 6px;
    right: 6px;
    top: 5px;
    bottom: 5px;
  }

  &::after {
    -webkit-mask-image: -webkit-linear-gradient(black, black),
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="300" height="6" viewBox="0 0 300 6"><rect x="0" y="0" width="250" height="6" /></svg>');
    content: "";
    -webkit-mask-size: 100% 100%, 270px 5px;
    -webkit-mask-position: top left, 60px 0px;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-composite: exclude;
    -webkit-mask-composite: xor;
    border-radius: 60px;
    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      114.77deg,
      #ff9edb 14.19%,
      #ff7d4f 29.5%,
      #ffe286 44.44%,
      #ffe286 53.78%,
      #ff885d 69.09%,
      #ff9edb 84.48%
    );

    @media ${mediaQueries.preMedium} {
      -webkit-mask-image: -webkit-linear-gradient(black, black),
        url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="300" height="30" viewBox="0 0 300 30"><rect x="0" y="0" width="250" height="30" /></svg>');
      -webkit-mask-size: 100% 100%, 270px 16px;
      -webkit-mask-position: top left, -37px 0px;
    }
  }

  @media ${mediaQueries.medium} {
    padding: 80px;
  }

  @media ${mediaQueries.large} {
    > div:first-child {
      width: 62%;
    }
  }
`

const Section = styled.section`
  padding: 80px 0 0;
  z-index: 100;
  position: relative;

  ${props =>
    props.variation === "default"
      ? `
    @media ${mediaQueries.medium} {
      padding: 62px 0 154px;
    }
  `
      : ""}

  &::before {
    content: "";
    width: 1400px;
    max-width: calc(100% - 20px);
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 0;
    height: 100%;


    background-image: ${props =>
      props.gradient === true
        ? `linear-gradient(
      180deg,
      #FFF2F4 50%,
      #EAF2FF 50%
    )`
        : "inherit"};
    }
  }
`

const Block = styled.div`
  border-radius: 60px;
  padding: 80px 24px 24px;

  position: relative;
  z-index: 1;

  h2 {
    em {
      display: inline-block;
      background: linear-gradient(
        90deg,
        #ffe286 14.19%,
        #ff885d 50.04%,
        #ff9edb 84.48%
      );
      background-size: 1000px;
      background-position: left -400px center;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
  }

  &::before {
    z-index: -1;

    background: linear-gradient(
        114.77deg,
        rgba(255, 158, 219, 0.15) 14.19%,
        rgba(255, 125, 79, 0.15) 29.5%,
        rgba(255, 226, 134, 0.15) 44.44%,
        rgba(255, 226, 134, 0.15) 53.78%,
        rgba(255, 136, 93, 0.15) 69.09%,
        rgba(255, 158, 219, 0.15) 84.48%
      ),
      #ffffff;
    left: 6px;
    right: 6px;
    top: 5px;
    bottom: 5px;
    border-radius: 60px;
  }

  &::after {
    z-index: -2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      114.77deg,
      #ff9edb 14.19%,
      #ff7d4f 29.5%,
      #ffe286 44.44%,
      #ffe286 53.78%,
      #ff885d 69.09%,
      #ff9edb 84.48%
    );
    border-radius: 60px;
  }

  @media ${mediaQueries.medium} {
    padding: 80px;
  }

  @media ${mediaQueries.large} {
    > div:first-child {
      width: 41%;
    }
  }
`

const Image = styled.div`
  display: none;

  @media ${mediaQueries.large} {
    display: block;
  }

  clip-path: url(#home-app-premium);
  position: absolute;
  top: 5px;
  right: 6px;
  width: 33%;

  &,
  .gatsby-image-wrapper {
    height: calc(100% - 5px);
    border-radius: 0 56px 56px 0;
  }
`

const Premium = styled.div`
  max-width: 190px;
  position: absolute;
  top: -45px;
  left: 80px;
  z-index: 10;
  @media ${mediaQueries.preMedium} {
    left: 24px;
    max-width: 125px;
  }
`

const Shadow = styled.div`
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    z-index: -2;

    position: absolute;
    left: -24px;
    right: -24px;
    top: 0px;
    bottom: -24px;
    background: linear-gradient(
      114.77deg,
      #ff9edb 14.19%,
      #ff7d4f 29.5%,
      #ffe286 44.44%,
      #ffe286 53.78%,
      #ff885d 69.09%,
      #ff9edb 84.48%
    );
    opacity: 0.35;
    filter: blur(54px);
    border-radius: 60px;

    @media ${mediaQueries.preMedium} {
      left: -24px;
      right: 24px;
    }
  }
`

export { Section, Block, Image, Premium, Shadow, Gradient, CustomContainer }
