import React from "react"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import { ThemeProvider } from "styled-components"
import theme from "./src/utils/theme"
import "./src/stylesheet/reset.css"
import "./src/stylesheet/index.css"
import { repositoryConfigs } from "./src/utils/preview"

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    <ThemeProvider theme={theme}>{element}</ThemeProvider>{" "}
  </PrismicPreviewProvider>
)
