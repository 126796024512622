const siteWidth = 1220
const wrapperPadding = 20
// const gutter = 24;

const breakpoints = {
  xs: 480,
  sm: 768,
  md: 1024,
  lg: 1440,
  navbar: 1140,
  footer: 1000,
}

const mediaQueries = {
  sm: `(max-width: ${breakpoints.sm}px) and (min-width: ${
    breakpoints.xs + 1
  }px)`,
  md: `(max-width: ${breakpoints.md}px) and (min-width: ${
    breakpoints.sm + 1
  }px)`,
  lg: `(min-width: ${breakpoints.md + 1}px)`,
  small: `(min-width: ${breakpoints.xs + 1}px)`,
  medium: `(min-width: ${breakpoints.sm + 1}px)`,
  preSmall: `(max-width: ${breakpoints.xs}px)`,
  preMedium: `(max-width: ${breakpoints.sm}px)`,
  large: `(min-width: ${breakpoints.md + 1}px)`,
  preLarge: `(max-width: ${breakpoints.md + 1}px)`,
  siteWidth: `(min-width: ${siteWidth + 1}px)`,
  siteWidthWithSpace: `(min-width: ${siteWidth + 2 * wrapperPadding + 1}px)`,
  arrows: `(min-width: ${siteWidth + 2 * (wrapperPadding + 64) + 1}px)`,
  navbar: `(min-width: ${breakpoints.navbar + 1}px)`,
  preNavbar: `(max-width: ${breakpoints.navbar}px)`,
  footer: `(min-width: ${breakpoints.footer + 1}px)`,
  preFooter: `(max-width: ${breakpoints.footer}px)`,
  breakpoints,
}

export default mediaQueries
