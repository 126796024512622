/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with unpublished documents.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews
 */

import { componentResolverFromMap } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "./linkResolver"

import BlogPost from "../templates/blogpost"
import Blog from "../templates/blog"
import Home from "../templates/home"
import AboutUs from "../templates/about-us"
import PodcastTemplate from "../templates/podcasts"
import Insurances from "../templates/insurances"
import InsuranceRegister from "../templates/insurance-register"
import Premium from "../templates/premium"
import Faq from "../templates/faq"
import Legal from "../templates/legal"
import Solution from "../templates/solution"
import GoodmedPage from "../templates/goodmed_page"

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `withPrismicPreview` and
 * `withPrismicUnpublishedPreview` higher order components.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#withprismicunpublishedpreview
 */
export const repositoryConfigs = [
  {
    repositoryName: `goodmed`,
    linkResolver,
    componentResolver: componentResolverFromMap({
      blog_post: BlogPost,
      blog: Blog,
      home: Home,
      about_us: AboutUs,
      podcast_page: PodcastTemplate,
      insurances: Insurances,
      "insurance-register": InsuranceRegister,
      Premium: Premium,
      faq: Faq,
      legal_page: Legal,
      Solution: Solution,
      goodmed_page: GoodmedPage,
    }),
  },
]
