import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { TeamMember, MemberImage } from "./style"

const Member = ({ image, name, job, active }) => {
  const gatsbyImg = getImage(image)

  return (
    <TeamMember active={active}>
      <MemberImage>
        <GatsbyImage image={gatsbyImg} alt={name} sizes={`185px`} />
      </MemberImage>
      <h3>{name}</h3>
      <p>{job}</p>
    </TeamMember>
  )
}

export default Member
