import { graphql } from "gatsby"
import React from "react"

import { Container } from "@styles"

import { Wrapper, Title } from "../podcast-slice/style"

const TitlePodcast = ({ slice }) => {
  const primary = slice.primary
  const title = primary.title_content || ""

  return (
    <Container>
      <Wrapper>
        <Title as="h2" id="podcasts">
          <span>{title}</span>
        </Title>
      </Wrapper>
    </Container>
  )
}

export default TitlePodcast

export const query = graphql`
  fragment TitlePodcast on PrismicTitlePodcastDefault {
    id
    slice_type
    primary {
      title_content
    }
  }
`
