import styled from "styled-components"
import { mediaQueries } from "@styles"

const Section = styled.section`
  position: relative;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzc1IiBoZWlnaHQ9IjQ2NyIgdmlld0JveD0iMCAwIDM3NSA0NjciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0zOTIuNTY3IC0zMzlMLTgwNS4xNjMgLTIzNy42MzVMLTc2MS4yMzEgMjgxLjQ2NUMtNzUxLjgyNSAzOTIuNjA5IC02NTQuMDk5IDQ3NS4wODQgLTU0Mi45NTUgNDY1LjY3OEwyNTIuMjg2IDM5OC4zNzZDMzYzLjQzIDM4OC45NyA0NDUuOTA1IDI5MS4yNDQgNDM2LjQ5OSAxODAuMUwzOTIuNTY3IC0zMzlaIiBmaWxsPSIjRUVFRUZGIi8+Cjwvc3ZnPgo=);
  background-position: bottom -65px right;
  background-size: 665px;
  width: 100%;
  overflow: hidden;
  padding: 115px 0 125px;
  @media ${mediaQueries.small} {
    background-position: bottom -80px right;
    background-size: 2013px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI2NzYiIHZpZXdCb3g9IjAgMCAxNDQwIDY3NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2MzAuMDcgLTYzM0wtMzE0IC00NjguNDcxTC0yNDEuNjM3IDM4Ni41NjlDLTIyNi45NTMgNTYwLjA3OSAtNzQuMzkwNCA2ODguODMzIDk5LjEyMDIgNjc0LjE0OUwxNDE0Ljg1IDU2Mi43OTdDMTU4OC4zNiA1NDguMTEzIDE3MTcuMTEgMzk1LjU1MSAxNzAyLjQzIDIyMi4wNEwxNjMwLjA3IC02MzNaIiBmaWxsPSIjRUVFRUZGIi8+Cjwvc3ZnPgo=);
  }
  @media ${mediaQueries.medium} {
    padding: 205px 0 245px;
    background-position: bottom;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkyMCIgaGVpZ2h0PSI2NzYiIHZpZXdCb3g9IjAgMCAxOTIwIDY3NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4NzAuMDcgLTYzM0wtNzQgLTQ2OC40NzFMLTEuNjM2OTcgMzg2LjU2OUMxMy4wNDc0IDU2MC4wNzkgMTY1LjYxIDY4OC44MzMgMzM5LjEyIDY3NC4xNDlMMTY1NC44NSA1NjIuNzk3QzE4MjguMzYgNTQ4LjExMyAxOTU3LjExIDM5NS41NTEgMTk0Mi40MyAyMjIuMDRMMTg3MC4wNyAtNjMzWiIgZmlsbD0iI0VFRUVGRiIvPgo8L3N2Zz4K);
  }

  svg {
    position: absolute;
  }

  h1 {
    margin-bottom: 24px;
  }
`

const Image = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 505px;
  height: 505px;

  clip-path: url(#faq-header);

  @media ${mediaQueries.navbar} {
    display: block;
  }
`

export { Section, Image }
