import React from "react"
import { graphql } from "gatsby"

import { Container, P0, T2, T8, Grid, GridItem } from "@styles"
import { Section } from "./style"

const Feature = ({ title, content_solution_default = {}, index }) => {
  return (
    <GridItem
      mb={4}
      mbmd={6}
      col={{ md: 5, lg: 5 }}
      push={index % 2 === 1 ? { md: 1, lg: 1 } : {}}
    >
      <T8 as="h3" mb={1} mbmd={2}>
        {title}
      </T8>
      <P0
        as="div"
        dangerouslySetInnerHTML={{ __html: content_solution_default.html }}
      />
    </GridItem>
  )
}

const Default = ({ primary = {}, items = [] }) => {
  return (
    <Section>
      <Container>
        <T2 as="h2" mb={3} mbmd={8}>
          {primary.title}
        </T2>
        <Grid>
          {items.map((feature, key) => (
            <Feature index={key} key={`feature${key}`} {...feature} />
          ))}
        </Grid>
      </Container>
    </Section>
  )
}

export default Default

export const query = graphql`
  fragment SolutionFeatures on PrismicHiwFeaturesDefault {
    id
    variation
    slice_type
    primary {
      title
    }
    items {
      title
      content_solution_default: content {
        html
      }
    }
  }
`
