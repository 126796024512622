import styled from "styled-components"
import variables from "./variables"

const Tag = styled.span`
  display: inline-block;

  padding: 4px 8px;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  border: 1px solid ${variables.yellow};
  border-radius: 54px;

  ${({ type }) => {
    switch (type) {
      case "_ebook_":
        return `
          background-color: #fff;
          color: ${variables.mediumOrange};
        `
      default:
        return `
          background-color: ${variables.lightYellow};
          color: #000;
        `
    }
  }}
`

export default Tag
