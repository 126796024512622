import React from "react"
import { linkResolver } from "@utils/linkResolver"
import Result from "./result"

import { Container } from "@styles"

import {
  FlexBox,
  Bar,
  Filters,
  Filter,
  Wrapper,
  Loader,
  Results,
  Clear,
} from "./style"

const SEARCH_TIMEOUT = 1000

const useSearchState = lang => {
  const [loading, setLoading] = React.useState(false)
  const [search, setSearch] = React.useState("")
  const [results, setResults] = React.useState(null)
  const timer = React.useRef(null)

  const clearSearch = React.useCallback(
    evt => {
      evt && evt.preventDefault()
      setSearch("")
      setLoading(false)
    },
    [setSearch]
  )

  /**
   * Search primsic API with query
   */
  const doSearch = React.useCallback(
    query => {
      setLoading(true)
      return import("@prismicio/client")
        .then(prismic => {
          const endpoint = prismic.getEndpoint(
            process.env.GATSBY_PRISMIC_REPO_NAME
          )
          const client = prismic.createClient(endpoint)

          return client
            .getAllByType("blog_post", {
              lang,
              orderings: {
                field: "document.first_publication_date",
                direction: "desc",
              },
              predicates: [prismic.predicate.fulltext("document", query)],
              fetchLinks: "blog_category.category_name",
            })
            .then(res => setResults(res))
        })
        .catch(console.log)
        .finally(() => setLoading(false))
    },
    [setSearch, setLoading, setResults]
  )

  const updateSearch = React.useCallback(
    evt => setSearch(evt.target.value),
    [setSearch]
  )

  /**
   * Do search after user input
   * Wait for user to pause to do search
   */
  React.useEffect(() => {
    if (search !== "") {
      timer.current = setTimeout(() => doSearch(search), SEARCH_TIMEOUT)
    } else {
      setResults(null)
    }

    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [search, doSearch, setResults])

  return {
    search,
    loading,
    updateSearch,
    results,
    clearSearch,
  }
}

const SearchBar = ({
  lang,
  categories,
  currentCategory,
  all,
  placeholder,
  noResults,
}) => {
  const { loading, results, search, updateSearch, clearSearch } =
    useSearchState(lang)
  const displayCategories = React.useMemo(
    () =>
      categories.map(category => {
        const categoryData = category.data || {}
        return {
          ...categoryData,
          uid: category.uid,
        }
      }),
    [categories]
  )

  return (
    <Container>
      <Wrapper>
        <form onSubmit={evt => evt.preventDefault()}>
          <FlexBox>
            {/* Search bar */}
            <Bar>
              {/* Loader */}
              {loading && <Loader />}

              {/* Input */}
              <input
                type="text"
                name="s"
                placeholder={placeholder}
                onChange={updateSearch}
                value={search}
              />

              {/* Clear search */}
              {search !== "" && (
                <Clear onClick={clearSearch}>
                  <span aria-hidden={true} />
                  <span aria-hidden={true} />
                </Clear>
              )}

              {/* Resuls */}
              {search !== "" && results && (
                <Results>
                  <ul>
                    {results.length > 0 ? (
                      results.map((result, key) => (
                        <Result {...result} key={`result-${key}`} />
                      ))
                    ) : (
                      <li>{noResults}</li>
                    )}
                  </ul>
                </Results>
              )}
            </Bar>

            {/* Filters */}
            <Filters id="Filters">
              <ul>
                <FilteSelector lang={lang} active={currentCategory === "all"}>
                  {all}
                </FilteSelector>
                {displayCategories.map(category => (
                  <FilteSelector
                    key={category.uid}
                    category={category.uid}
                    active={currentCategory === category.uid}
                    lang={lang}
                  >
                    {category.category_name}
                  </FilteSelector>
                ))}
              </ul>
            </Filters>
          </FlexBox>
        </form>
      </Wrapper>
    </Container>
  )
}

export default SearchBar

const FilteSelector = ({ children, active, lang, category }) => {
  return (
    <Filter>
      <a
        className={active ? "active" : ""}
        href={`${linkResolver({
          lang,
          category,
          type: "blog",
        })}#Filters`}
      >
        {children}
      </a>
    </Filter>
  )
}
