import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Stores from "@components/stores"

import { Container, T2, Grid, GridItem } from "@styles"
import { Section, Image } from "./style"

const HomeAppDownload = ({ slice }) => {
  const primary = slice.primary || {}
  const items = slice.items || []
  const image = getImage(primary.image)
  return (
    <Section>
      <Container>
        <Grid reverse>
          <GridItem col={{ lg: 6, md: 6 }}>
            <Image>
              <GatsbyImage
                image={image}
                alt={primary.title}
                sizes={`100vw, (min-width: 769px) 40vw, (min-width: 1200px) 412px`}
              />
            </Image>
          </GridItem>
          <GridItem col={{ lg: 6, md: 6 }}>
            <T2 as="h2" mb={5} mbmd={9}>
              {primary.title}
            </T2>
            <Stores stores={items} />
          </GridItem>
        </Grid>
      </Container>

      <svg width="0" height="0">
        <defs>
          <clipPath
            clipPathUnits="objectBoundingBox"
            transform="scale(.002370)"
            id="home-app-download"
          >
            <path
              d="M213.384 359.526c-16.178 13.632-26.286 22.288-37.492 31.814-33.309 28.329-70.222 38.584-111.627 20.184C23.882 393.567 1.088 362.107.723 317.571c-.797-98.048.14-196.107-.71-294.161C-.147 4.376 6.601-.078 24.443.314c51.553 0 102.207.017 154.206.017 22.456-.645 29.474 14.234 29.474 28.902 0 14.668-.247 39.212-.247 49.04 10.866-16.603 21.981-34.36 36.565-47.913C276.757.348 314.995-8.437 355.889 8.611c41.691 17.39 64.311 50.612 64.642 96.186.713 98.048-.084 196.113.642 294.155.121 17.52-5.97 22.271-22.779 21.979-52.842.185-105.306.185-157.024.185-22.455 0-28.228-8.158-28.228-27.476 0-13.246.242-20.106.242-34.114z"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
    </Section>
  )
}

export default HomeAppDownload

export const query = graphql`
  fragment HomeAppDownload on PrismicHomeAppDownloadDefault {
    id
    slice_type
    variation
    primary {
      title
      image {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    items {
      link {
        url
      }
      logo {
        gatsbyImageData(layout: FIXED, imgixParams: { q: 100 }, height: 52)
      }
    }
  }
`
