import md5 from "md5"

export function addListMember(email) {
  // Create the new request
  var xhr = new XMLHttpRequest()
  var url =
    "https://api.synapse-medicine.com/mailchimp/3.0/lists/906ee67e0d/members"

  // Example request JSON:
  let data = {
    email_address: email,
    status: "subscribed",
    tags: ["newsletter"],
    merge_fields: {},
  }

  let final_data = JSON.stringify(data)

  xhr.open("POST", url)
  // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
  xhr.setRequestHeader("Content-Type", "application/json")

  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == 400) {
      updateTag(email)
    }
  }

  // Sends the request
  xhr.send(final_data)
}

function updateTag(email) {
  var xhr = new XMLHttpRequest()
  var url = `https://api.synapse-medicine.com/mailchimp/3.0/lists/906ee67e0d/members/${md5(
    email
  )}/tags`

  var data = {
    tags: [{ name: "newsletter", status: "active" }],
    is_syncing: false,
  }

  let final_data = JSON.stringify(data)

  xhr.open("POST", url)
  // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
  // xhr.setRequestHeader("Content-Type", "application/json")
  xhr.setRequestHeader("Content-Type", "application/json")

  // Sends the request
  xhr.send(final_data)
}
