import styled from "styled-components"
import mediaQueries from "./media-helper"

const TextElement = styled.div`
  ${props => (props.mb ? `margin-bottom: ${props.mb * 8}px;` : "")}
  ${props => (props.mt ? `margin-top: ${props.mt * 8}px;` : "")}
  ${props =>
    props.mbmd
      ? `@media ${mediaQueries.medium} {margin-bottom: ${props.mbmd * 8}px;}`
      : ""}
  ${props => (props.center ? "text-align: center;" : "")}
  ${props =>
    props.max
      ? `
    max-width: ${props.max}px;
    margin-left: auto;
    margin-right: auto;
  `
      : ""}
  ${props => (props.pre ? "white-space: pre-wrap;" : "")}
  ${props => (props.weight ? `font-weight: ${props.weight};` : "")}
  ${props => (props.stolz ? `font-family: "stolz";` : "")}
`

const P00 = styled(TextElement)`
  font-size: 20px;
  line-height: 25px;
`

const P0 = styled(P00)`
  @media ${mediaQueries.medium} {
    font-size: 22px;
    line-height: 27.5px;
  }
`

const P1 = styled(TextElement)`
  font-size: 14px;
  line-height: 17.5px;
`

const P2 = styled(TextElement)`
  font-size: 13px;
  line-height: 16.25px;
`

const P3 = styled(TextElement)`
  font-size: 20px;
  line-height: 25px;
  @media ${mediaQueries.medium} {
    font-size: 24px;
    line-height: 27.5px;
  }
`

const T2 = styled(TextElement)`
  font-size: 36px;
  line-height: 41px;
  @media ${mediaQueries.medium} {
    font-size: 60px;
    line-height: 69px;
  }
`

const T3 = styled(TextElement)`
  font-size: 22px;
  line-height: 25px;
`

const T4 = styled(TextElement)`
  font-size: 16px;
  line-height: 20px;
`

const T5 = styled(TextElement)`
  font-size: 36px;
  line-height: 40px;
`

const T6 = styled(TextElement)`
  font-size: 36px;
  line-height: 40px;
  @media ${mediaQueries.medium} {
    font-size: 48px;
    line-height: 55px;
  }
`

const T7 = styled(TextElement)`
  font-size: 32px;
  line-height: 36px;
  @media ${mediaQueries.medium} {
    font-size: 36px;
    line-height: 40px;
  }
`

const T8 = styled(TextElement)`
  font-size: 20px;
  line-height: 23px;
  @media ${mediaQueries.medium} {
    font-size: 36px;
    line-height: 36px;
  }
`

export { T2, T3, T4, T5, T6, T7, T8, P00, P0, P1, P2, P3 }
