import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import Layout from "../components/layout"
import styled from "styled-components"
import Seo from "../components/seo"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

const Legal = ({ data }) => {
  const langParams = data.langParams || {}
  const navigation = data.prismicNavigation || {}
  const doc = data.prismicLegalPage || {}
  const content = doc.data || {}

  return (
    <Layout navigation={navigation} languages={langParams}>
      <Wrapper>
        <Seo
          title={content.meta_title}
          description={content.meta_description}
          openGraphImg={
            content.open_graph && content.open_graph.length !== null
              ? content.open_graph.url
              : null
          }
        />
        <Container>
          <Section>
            <Title>{content.title.text}</Title>
            <PrismicRichText field={content.content.richText} />
          </Section>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query LegalPage($id: String, $lang: String) {
    langParams: prismicLegalPage(id: { eq: $id }, lang: { eq: $lang }) {
      uid
      lang
      url
      type
      alternate_languages {
        uid
        type
        lang
      }
    }
    prismicLegalPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      data {
        meta_description
        meta_title
        open_graph {
          url
        }
        title {
          richText
          text
        }
        content {
          richText
        }
      }
    }
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
  }
`

export default withPrismicPreview(Legal)

const Wrapper = styled.div`
  width: 100vw;
  /* min-height: 100vh; */
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;

  p {
    line-height: 1.33;
    font-size: 1.1rem;
    margin-bottom: 16px;
  }
`

const Container = styled.div`
  max-width: 1220px;
  z-index: 20;
  margin: 0 auto;
  width: 100%;
  padding-top: 100px;
`

const Section = styled.section`
  width: 66%;
  margin: 0 auto;
  @media all and (max-width: 1280px) {
    padding: 20px;
    width: 100%;
  }

  @media all and (max-width: 980px) {
    width: 100%;
  }

  @media all and (max-width: 480px) {
    padding: 0px;
  }
`

const Title = styled.h1`
  font-size: 60px;
  line-height: 1.15;
  margin: 0.67em 0;
  @media all and (max-width: 980px) {
    padding: 0 20px;
    font-size: 40px;
    margin: 0 auto 0.67em;
  }
`
