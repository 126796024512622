import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useLottieAnimation from "@utils/hooks/useLottieAnimation"

import { Container } from "@styles"
import { Section, Image, PremiumTitle } from "./style"

const PremiumHeader = ({ slice }) => {
  const primary = slice.primary || {}

  const json = primary.json || {}
  const image = getImage(primary.image)
  const animation = useLottieAnimation(json)

  return (
    <Section>
      <Container>
        <Image hasAnimation={!!json.url}>
          {json.url ? (
            <div ref={animation} />
          ) : (
            <GatsbyImage
              image={image}
              alt={primary.title}
              sizes={`185px, (min-width: 769px) 268px`}
            />
          )}
        </Image>
        <PremiumTitle as="h1" center>
          {primary.title}
        </PremiumTitle>
      </Container>
    </Section>
  )
}

export default PremiumHeader

export const query = graphql`
  fragment PremiumHeader on PrismicPremiumHeaderDefault {
    id
    slice_type
    variation
    primary {
      image {
        gatsbyImageData(layout: CONSTRAINED)
      }
      json {
        url
      }
      title
    }
  }
`
