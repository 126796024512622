import { graphql } from "gatsby"
import React from "react"
import Filter from "@templates/about-us-components/team-slice/filter"
import FeaturedPodcast from "./featured-podcast"
import RegularPodcast from "./regular-podcast"

import { Trame, Container } from "@styles"
import {
  Section,
  Background,
  Wrapper,
  ThisFilters as Filters,
  ThisContainer,
  PodcastGrid,
} from "./style"

const Podcasts = ({ podcasts, page }) => {
  const [currentFilter, setFilter] = React.useState(page.all)
  const filters = React.useMemo(() => {
    const categories = podcasts.map(({ data = {} }) => data.category)
    const result = []

    for (let i = 0; i < categories.length; i++) {
      if (result.indexOf(categories[i]) === -1) {
        result.push(categories[i])
      }
    }
    return result
  }, [podcasts])

  const handleFilterChange = React.useCallback(
    evt => setFilter(evt.target.value),
    [setFilter]
  )

  const sortPodcast = podcasts.sort(
    (a, b) =>
      Date.parse(a.children[0].publishDate) -
      Date.parse(b.children[0].publishDate)
  )

  return (
    <Section>
      <Container>
        <Wrapper>
          <form onSubmit={evt => evt.preventDefault()}>
            <Filters>
              <Filter
                onChange={handleFilterChange}
                active={currentFilter === page.all}
              >
                {page.all}
              </Filter>
              {filters.map(filter => (
                <Filter
                  key={filter}
                  onChange={handleFilterChange}
                  active={currentFilter === filter}
                >
                  {filter}
                </Filter>
              ))}
            </Filters>
          </form>
        </Wrapper>
      </Container>
      <Trame>
        <Background />
        <ThisContainer>
          <Wrapper padding>
            <FeaturedPodcast
              page={page.featured_podcast}
              available_on={page.available_on}
              animated_by={page.animated_by}
              guests={page.guests}
              monthly_podcast={page.monthly_podcast}
            />
            <PodcastGrid>
              {sortPodcast.map(podcast => {
                return (
                  <RegularPodcast
                    {...podcast.data}
                    acast={podcast.children || []}
                    available_on={page.available_on}
                    key={podcast.id}
                  />
                )
              })}
            </PodcastGrid>
          </Wrapper>
        </ThisContainer>
      </Trame>
    </Section>
  )
}

export default Podcasts

export const query = graphql`
  fragment PodcastPodcasts on PrismicPodcastsDefault {
    id
    slice_type
  }

  fragment Episode on AcastEpisode {
    id
    audio
    cover
    duration
    publishDate
    title
    status
    type
    streamUrl
    summary
  }

  fragment DocPodcast on PrismicPodcastType {
    id
    lang
    children {
      ... on AcastEpisode {
        ...Episode
      }
    }
    first_publication_date
    data {
      author_name
      category
      platforms {
        name
        link {
          url
        }
      }
      guests {
        name
        photo {
          alt
          url
          gatsbyImageData(
            layout: CONSTRAINED
            width: 136
            imgixParams: { q: 100 }
          )
        }
      }
      content {
        html
        text
      }
    }
  }
`
