import React from "react"

import { Trame } from "@styles"

import { Background, BlogPosts, Blog } from "./style"

const BlogBody = ({ children }) => {
  return (
    <Blog>
      <Trame>
        <Background />
        <BlogPosts>{children}</BlogPosts>
      </Trame>
    </Blog>
  )
}

export default BlogBody
