import React from "react"
import { Link } from "gatsby"
import { linkResolver } from "@utils/linkResolver"

const NETLINKING_WHITELIST = ["https://goodmed.com"]

// eslint-disable-next-line
export const CustomLink = (element, content) => {
  if (element.data.link_type === "Document") {
    return (
      <Link to={linkResolver(element.data)} key={element.data.id}>
        {content}
      </Link>
    )
  }

  if (element.data.link_type === "Web") {
    return <a href={element.data.url}>{content}</a>
  }
  return null
}

// eslint-disable-next-line
export const AppLink = ({ link, children, activeClassName, ...props }) => {
  const shouldRobotsFollow = NETLINKING_WHITELIST.find(whitelist => {
    return link?.url?.includes(whitelist)
  })

  let target = {}

  if (link.target) {
    target = {
      target: link.target,
      rel: `noopener ${shouldRobotsFollow ? "" : "nofollow"}`,
    }
  }

  if (link.link_type === "Document") {
    return (
      <Link to={link.url} activeClassName={activeClassName} {...props}>
        {children}
      </Link>
    )
  }

  return (
    <a style={{ color: "black" }} href={link.url} {...target} {...props}>
      {children}
    </a>
  )
}

// eslint-disable-next-line
export const GatsbyLink = (type, element, content, children, index) => {
  if (element.data.link_type === "Document") {
    return (
      <Link to={linkResolver(element.data)} key={element.data.id}>
        {content}
      </Link>
    )
  }
  return null
}
