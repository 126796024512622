import styled, { keyframes } from "styled-components"
import { mediaQueries } from "@styles"

const Section = styled.section`
  padding: 80px 0 0;
`

const Background = styled.div`
  max-width: 1584px;
  margin: 0 auto;
  background-color: ${props => props.theme.colors.primaryLight};

  padding: 48px 0 100px;
  border-radius: 24px;

  @media ${mediaQueries.medium} {
    border-radius: 50px;
    padding: 106px 0;
  }
`

const Block = styled.div`
  @media ${mediaQueries.medium} {
    background-color: #fff;
    border-radius: 20px;
    padding: 48px 88px;
  }
`

const Questions = styled.div`
  @media ${mediaQueries.preMedium} {
    background-color: #fff;
    border-radius: 30px;
    padding: 48px 24px;
  }
`

const fadeInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const Article = styled.article`
  position: relative;
  cursor: pointer;
  padding: 24px 0;

  :not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.grey25};
  }

  &:first-child {
    padding-top: 0;
  }

  &::after {
    content: "";
    position: absolute;

    top: 24px;
    right: 0;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI0LjM3NSAxMS4yNUwxNSAyMC42MjUgNS42MjUgMTEuMjUiIHN0cm9rZT0iIzJEODFGRiIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);
    background-position: center;
    width: 30px;
    height: 30px;
    transition: 400ms transform;

    ${props => (props.active ? "transform: rotate(180deg);" : "")}
  }

  &:first-child::after {
    top: 0;
  }

  > h3,
  > div {
    padding-right: 48px;
    max-width: 820px;
  }

  > div {
    padding-top: 16px;

    ${props => (props.active ? "" : "display: none;")}
    animation: ${fadeInRight} 0.75s;
  }
`

const Button = styled.div`
  margin-top: 24px;
  text-align: center;
`

export { Section, Background, Block, Article, Questions, Button }
