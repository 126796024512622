import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { P1, P2, T4, T7 } from "@styles"
import {
  Article,
  Header,
  Icon,
  StarIcon,
  Stars,
  Content,
  Footer,
} from "./style"

const stars = [0, 1, 2, 3, 4]

const Testimonial = ({
  title,
  content_testimonial,
  date,
  icon,
  name,
  since,
  note,
}) => {
  const image = getImage(icon)
  return (
    <Article>
      <Header>
        <div>
          <Icon>
            <GatsbyImage image={image} alt={title} sizes={`80px`} />
          </Icon>
          <P1 as="span" weight={700} stolz>
            {name}
          </P1>
          <P2 as="span">{since}</P2>
        </div>
        <div>
          <Stars>
            {stars.map(index => (
              <Star key={`star-${index}`} grade={note} index={index} />
            ))}
          </Stars>
        </div>
      </Header>
      <Content>
        <T7 as="h3" mb={2}>
          “{title}
        </T7>
        <T4
          as="div"
          dangerouslySetInnerHTML={{
            __html:
              content_testimonial &&
              content_testimonial.html &&
              content_testimonial.html,
          }}
        />
      </Content>
      <Footer>
        <P1 as="time">{date}</P1>
      </Footer>
    </Article>
  )
}

const Star = ({ grade, index }) => {
  const percentage = Math.max(Math.min(1, grade - index / 1), 0)
  return (
    <li>
      <StarIcon percentage={percentage}>
        <span></span>
      </StarIcon>
    </li>
  )
}

export default Testimonial
