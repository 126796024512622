import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Container, Grid, GridItem, T8, P0 } from "@styles"
import { Section, Image, Block, Wrapper } from "./style"

const Feature = ({ title, contentfeatures, image }) => {
  const imageData = getImage(image)

  return (
    <GridItem col={{ md: 6, lg: 6 }} mb={6} mbmd={8}>
      <Block>
        <Image>
          <GatsbyImage
            image={imageData}
            alt={title}
            sizes={`${imageData.size}px`}
          />
        </Image>
        <T8 as="h3" mb={1} mbmd={2}>
          {title}
        </T8>
        <P0 as="p">{contentfeatures}</P0>
      </Block>
    </GridItem>
  )
}

const PremiumFeatures = ({ slice }) => {
  const items = slice.items || []
  return (
    <Section>
      <Container>
        <Wrapper>
          <Grid>
            {items.map((feature, key) => (
              <Feature key={`feature-${key}`} {...feature} />
            ))}
          </Grid>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default PremiumFeatures

export const query = graphql`
  fragment PremiumFeatures on PrismicPremiumFeaturesDefault {
    id
    slice_type
    variation
    items {
      contentfeatures: content
      title
      image {
        gatsbyImageData(layout: FIXED)
      }
    }
  }
`
