import React from "react"

import Left from "./left"
import Right from "./right"

import { Container } from "@styles"
import { Section } from "./style"

const mapVariations = {
  left: Left,
  right: Right,
}

const InsurancesSection = ({ slice }) => {
  const Component = mapVariations[slice.variation]
  return (
    <Section>
      <Container>
        <Component {...slice} />
      </Container>
    </Section>
  )
}

export default InsurancesSection
