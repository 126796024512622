import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// Default Image
const DefaultImage = ({ primary }) => {
  return (
    <WrapperImage>
      <figcaption>
        <img src={primary.image.url} />
        {primary.caption && primary.caption.text !== "" && (
          <figcaption>{primary.caption.text}</figcaption>
        )}
      </figcaption>
    </WrapperImage>
  )
}

// Function to determine the image type
const renderSwitch = slice => {
  switch (slice.slice_label) {
    default:
      return <DefaultImage {...slice} />
  }
}

export const ImageCaption = ({ slice }) => <>{renderSwitch(slice)}</>

export const query = graphql`
  fragment BlogPostDataBodyImageWithCaption on PrismicBlogPostDataBodyImageWithCaption {
    primary {
      image {
        alt
        url
      }
      caption {
        text
      }
    }
  }
`

const WrapperImage = styled.figure`
  margin-top: 3rem;
  margin-bottom: 3rem;

  img {
    width: 100%;
  }
`
