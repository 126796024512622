import React from "react"
import { graphql } from "gatsby"
import { AppLink } from "@components/CustomLink"

const RegularLink = ({ slice }) => {
  const primary = slice.primary
  return (
    <li>
      <AppLink link={primary.link} activeClassName="active">
        {primary.title}
      </AppLink>
    </li>
  )
}

export default RegularLink

export const query = graphql`
  fragment RegularLink on PrismicNavigationDataBodyRegularLink {
    id
    slice_type
    primary {
      link {
        url
        link_type
      }
      title
    }
  }
`
