import React from "react"
import styled, { keyframes } from "styled-components"

const Spinner = () => {
  return <Spin />
}

export default Spinner

// Create the keyframes
const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spin = styled.div`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.2em solid currentColor;
  animation: ${rotation} 0.75s linear infinite;
  border-right-color: transparent;
  border-radius: 50%;
`
