import { graphql } from "gatsby"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Container, T2, P0, ButtonBlueBig } from "@styles"

import { Section, Image, Wrapper } from "./style"

const Header = ({ slice }) => {
  const primary = slice.primary
  const title = primary.title || ""
  const content = primary.content || {}
  const image = getImage(primary.image)

  return (
    <Section>
      <Container>
        <Wrapper>
          <svg width="226" height="143" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M132.083 59.109c-.259-.981-.508-1.97-.676-2.97-1.399-8.347-1.177-8.545 7.187-8.538 26.845.03 53.691.194 80.531-.126 5.896-.07 6.899 2.044 6.033 7.1-2.674 15.617-10.752 27.396-24.593 35.127-2.97 1.659-6.19 2.865-9.268 5.179 9.558 0 19.127.255 28.672-.108 4.775-.18 5.846 1.328 5.381 5.903-2.362 23.229-23.183 41.837-47.149 41.822-24.057-.018-44.756-18.466-47.175-41.76-.476-4.573 5.43-1.19 5.43-5.94 0-11.87.268-18.162-4.373-35.69z"
              fill="#FF9EDB"
            />
            <path
              d="M66.743 86.323c-6.621-4.447-11.26-6.873-14.603-11.096-6.798-8.59-8.257-18.075-3.825-28.077 4.597-10.374 13.24-15.663 24.264-15.905 19.508-.426 39.043-.473 58.547.092 11.351.327 20 6.17 24.161 17.05 4.125 10.788 1.694 20.526-6.191 28.926-.323.346-.71.634-1.04.974-3.145 3.24-8.321 6.16-8.904 9.81-.713 4.446 6.292 4.602 9.308 7.63 8.005 8.037 10.576 17.357 7.443 28.04-3 10.227-12.596 18.19-23.103 18.395-20.7.407-41.423.505-62.115-.057-10.539-.285-18.446-6.117-22.596-15.976-4.115-9.78-3.045-19.341 4.069-27.312 4.223-4.732 9.68-8.365 14.585-12.494z"
              fill="#61E5B9"
            />
            <path
              d="M101.314 25.825c7.132 0 12.913-5.78 12.913-12.912C114.227 5.78 108.446 0 101.314 0c-7.131 0-12.912 5.781-12.912 12.913 0 7.131 5.781 12.912 12.912 12.912z"
              fill="#FF546D"
            />
            <path
              d="M42.545 51.981c6.675 0 12.085-5.41 12.085-12.084 0-6.675-5.41-12.085-12.085-12.085-6.674 0-12.085 5.41-12.085 12.085 0 6.674 5.411 12.084 12.085 12.084z"
              fill="#2D81FF"
            />
            <path
              d="M178.103 42.711c7.131 0 12.912-5.781 12.912-12.913 0-7.131-5.781-12.912-12.912-12.912-7.132 0-12.913 5.78-12.913 12.912 0 7.132 5.781 12.913 12.913 12.913z"
              fill="#FFDC6D"
            />
            <path
              d="M42.485 57.118c-7.347.002-14.696-.111-22.04.03C10.304 57.34 2.307 63.711.452 72.838c-2.015 9.908 2.804 18.966 12.834 22.81 3.98 1.526 2.892 2.527.73 4.549a167.668 167.668 0 00-8.18 8.184c-7.755 8.319-7.656 20.571.15 28.279 7.712 7.614 19.764 7.921 27.8.079 15.381-15.008 30.511-30.274 45.626-45.551 6.17-6.237 7.582-13.816 4.272-21.858-3.346-8.122-9.925-12.02-18.608-12.184-7.528-.142-15.06-.029-22.59-.029z"
              fill="#FF7D4F"
            />
          </svg>
          <T2 as="h1">{title}</T2>
          <P0
            as="div"
            dangerouslySetInnerHTML={{
              __html: content.html,
            }}
          />
          {primary.link_about_us.url && (
            <ButtonBlueBig
              as="a"
              style={{ marginTop: "3rem" }}
              href={primary.link_about_us.url}
            >
              {primary.cta_content}
            </ButtonBlueBig>
          )}
        </Wrapper>
      </Container>
      <Image>
        <GatsbyImage image={image} alt={title} sizes={`1212px`} />
      </Image>
      <svg width="0" height="0">
        <defs>
          <clipPath
            clipPathUnits="objectBoundingBox"
            transform="scale(.001047 .001204)"
            id="about-us-header"
          >
            <path
              d="M0 0V314.503C0 599.208 230.794 830 515.5 830H956V0H0Z"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
    </Section>
  )
}

export default Header

export const query = graphql`
  fragment AboutUsHeader on PrismicAboutUsHeaderDefault {
    id
    slice_type
    primary {
      title
      content {
        html
      }
      cta_content
      link_about_us {
        url
      }
      image {
        gatsbyImageData(layout: FIXED, width: 1280, imgixParams: { q: 100 })
      }
    }
  }
`
