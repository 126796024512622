import styled from "styled-components"
import { mediaQueries, T6 } from "@styles"

export const Section = styled.section``
export const Title = styled(T6)`
  span {
    position: relative;
    z-index: 1;

    font-size: 60px;

    svg {
      position: absolute;
      z-index: -1;

      left: -16px;
      top: 0;
      width: 66px;

      fill: ${props => props.theme.colors[props.color || "secondaryMedium"]};
    }
  }
`

export const Background = styled.div`
  background-color: ${props => props.theme.colors.redLight};
  width: 1400px;
  margin: 0 auto;
  border-radius: 50px 50px 0 0;
  max-width: calc(100% - 20px);

  padding: 64px 0 0;
  @media ${mediaQueries.medium} {
    padding: 154px 0 0;
  }
`

export const Image = styled.div`
  display: none;
  @media ${mediaQueries.medium} {
    display: block;
  }
`

export const Block = styled.div`
  ${props =>
    props.center ? `@media ${mediaQueries.medium} {text-align: center;}` : ""}
`
