import React from "react"
import { graphql } from "gatsby"

import { Hr, MenuItem, Networks } from "./style"

const Linkedin = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.774 0C.794 0 0 .77 0 1.72v20.56C0 23.23.794 24 1.774 24h20.452c.98 0 1.774-.77 1.774-1.72V1.72C24 .77 23.206 0 22.226 0H1.774zm5.501 20.09V9.253H3.65V20.09h3.625zM5.463 7.773c1.264 0 2.05-.832 2.05-1.872C7.49 4.838 6.728 4.03 5.488 4.03c-1.24 0-2.051.809-2.051 1.872 0 1.04.787 1.872 2.003 1.872h.024zM9.28 20.09h3.625v-6.052c0-.324.024-.647.12-.879.261-.647.858-1.317 1.86-1.317 1.31 0 1.836.994 1.836 2.45v5.798h3.624v-6.214c0-3.328-1.788-4.877-4.173-4.877-1.923 0-2.785 1.05-3.267 1.788v.038h-.024l.024-.038V9.253H9.281c.048 1.017 0 10.837 0 10.837z"
      fill="#171717"
    />
  </svg>
)

const Facebook = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M24 12.073C24 5.405 18.627 0 12 0S0 5.405 0 12.073C0 18.1 4.388 23.094 10.125 24v-8.437H7.078v-3.49h3.047v-2.66c0-3.025 1.791-4.697 4.533-4.697 1.313 0 2.686.236 2.686.236v2.971H15.83c-1.491 0-1.956.93-1.956 1.886v2.264h3.328l-.532 3.49h-2.796V24C19.612 23.094 24 18.1 24 12.073z"
      fill="#171717"
    />
  </svg>
)

const Twitter = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M12 24c6.63 0 12-5.37 12-12S18.63 0 12 0 0 5.37 0 12s5.37 12 12 12zm6.036-13.902c0 3.822-2.91 8.232-8.232 8.232a8.06 8.06 0 01-4.422-1.308 5.816 5.816 0 004.284-1.194 2.889 2.889 0 01-2.7-2.01 2.884 2.884 0 001.308-.048 2.896 2.896 0 01-2.322-2.838v-.036c.39.216.834.348 1.308.36A2.88 2.88 0 015.97 8.85c0-.528.144-1.026.39-1.452a8.208 8.208 0 005.964 3.024 2.894 2.894 0 014.926-2.64 5.859 5.859 0 001.836-.702 2.892 2.892 0 01-1.272 1.602 5.872 5.872 0 001.662-.456 5.927 5.927 0 01-1.446 1.5c.006.12.006.246.006.372z"
      fill="#171717"
    />
  </svg>
)

const YouTube = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M21.377 4.507a3.024 3.024 0 012.122 2.147C24 8.548 24 12.5 24 12.5s0 3.951-.501 5.845a3.023 3.023 0 01-2.122 2.148C19.505 21 12 21 12 21s-7.506 0-9.378-.508a3.023 3.023 0 01-2.121-2.147C-.001 16.451-.001 12.5-.001 12.5s0-3.952.502-5.846a3.024 3.024 0 012.121-2.147C4.494 4 12 4 12 4s7.506 0 9.378.507zM9.599 8.857v7.286l6.236-3.643-6.236-3.644z"
      fill="#171717"
    />
  </svg>
)

const Instagram = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M7.054.076C8.334.018 8.742.004 12 .004s3.667.014 4.946.072c1.277.058 2.149.261 2.912.557a5.88 5.88 0 012.125 1.384 5.88 5.88 0 011.384 2.125c.296.763.499 1.635.557 2.912.059 1.28.073 1.688.073 4.946s-.014 3.667-.073 4.946c-.058 1.277-.26 2.149-.557 2.912a6.134 6.134 0 01-3.509 3.508c-.763.297-1.635.5-2.912.558-1.279.059-1.688.072-4.946.072s-3.666-.013-4.946-.072c-1.277-.058-2.149-.26-2.912-.558a5.88 5.88 0 01-2.124-1.383 5.88 5.88 0 01-1.384-2.125c-.297-.763-.5-1.635-.558-2.912C.018 15.666.004 15.258.004 12s.014-3.667.072-4.946c.058-1.277.261-2.149.557-2.912a5.88 5.88 0 011.385-2.125A5.88 5.88 0 014.142.634c.763-.297 1.635-.5 2.912-.558zm9.794 2.159c-1.265-.058-1.645-.07-4.848-.07s-3.582.012-4.847.07c-1.17.053-1.805.249-2.228.413a3.717 3.717 0 00-1.38.897 3.717 3.717 0 00-.897 1.38c-.164.423-.36 1.058-.413 2.227-.057 1.265-.07 1.645-.07 4.848s.013 3.583.07 4.848c.054 1.17.249 1.804.413 2.227.193.521.5.992.898 1.38.387.398.858.705 1.379.897.423.164 1.058.36 2.228.413 1.264.058 1.644.07 4.847.07 3.204 0 3.583-.012 4.848-.07 1.17-.053 1.805-.249 2.227-.413a3.973 3.973 0 002.277-2.277c.164-.423.36-1.058.413-2.227.058-1.265.07-1.645.07-4.848s-.012-3.583-.07-4.848c-.053-1.17-.249-1.804-.413-2.227a3.717 3.717 0 00-.897-1.38 3.717 3.717 0 00-1.38-.897c-.422-.164-1.058-.36-2.227-.413zM5.84 12a6.16 6.16 0 1112.32 0 6.16 6.16 0 01-12.32 0zM8 12A3.999 3.999 0 1016 12 3.999 3.999 0 008 12zm10.403-4.964a1.44 1.44 0 100-2.88 1.44 1.44 0 000 2.88z"
      fill="#171717"
    />
  </svg>
)

const mapNetwork = {
  linkedin: Linkedin,
  facebook: Facebook,
  twitter: Twitter,
  youtube: YouTube,
  instagram: Instagram,
}

const Network = ({ social_network, link }) => {
  const Component = mapNetwork[social_network]
  return (
    <li>
      <a href={link.url} target="_blank" rel="noreferrer">
        <Component />
      </a>
    </li>
  )
}

const SocialNetworks = ({ slice }) => {
  const items = slice.items
  return (
    <MenuItem>
      <Networks>
        {items.map((network, key) => (
          <Network key={`network-${key}`} {...network} />
        ))}
        <Hr />
      </Networks>
    </MenuItem>
  )
}

export default SocialNetworks

export const query = graphql`
  fragment SocialNetworks on PrismicNavigationDataBody1SocialNetworks {
    id
    slice_type
    primary {
      name
    }
    items {
      social_network
      link {
        url
      }
    }
  }
`
