import styled from "styled-components"
import { mediaQueries, Container } from "@styles"

const Section = styled.section`
  padding: 80px 0 0;
  @media ${mediaQueries.medium} {
    padding: 80px 0;
  }
`

const CustomContainer = styled(Container)`
  @media ${mediaQueries.preMedium} {
    width: 100%;
    padding: 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  z-index: 1;

  padding-top: 66%;

  @media ${mediaQueries.medium} {
    display: flex;
    align-items: center;

    max-height: 800px;
    height: 60vw;

    padding-top: 0;
  }
`

const Icon = styled.div`
  margin: 0 0 20px;
  max-width: 132px;
  @media ${mediaQueries.medium} {
    margin: -80px 0 40px;
    max-width: 260px;
  }
`

const Block = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 50px 50px;

  padding: 36px 36px 48px;

  @media ${mediaQueries.medium} {
    border-radius: 50px;
    box-shadow: none;
    max-width: 628px;

    padding: 0 48px 80px 0;
  }
`

const Image = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;

  img {
    border-radius: 50px;
  }
  @media ${mediaQueries.medium} {
    width: 65%;
    right: 0;
  }
`

export { Section, Icon, Block, Image, Wrapper, CustomContainer }
