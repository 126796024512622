import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"
import { SliceZone } from "@prismicio/react"

import { Header, Collapse } from "./faq-components"
import { variables } from "@styles"
import { MentionDm } from "@slices"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

const Faq = ({ data = {} }) => {
  const doc = data.faq || {}
  const faq = doc.data
  const langParams = data.langParams || {}
  const slices = faq.body || []
  const navigation = data.prismicNavigation || {}

  return (
    <Layout
      navigation={navigation}
      languages={langParams}
      backgroundColor={variables.lightVelvet}
      backgroundHeader
    >
      <Seo
        title={faq.meta_title || ""}
        description={faq.meta_description || ""}
      />
      <SliceZone
        slices={slices}
        components={{
          faq_header: Header,
          collapse: Collapse,
          mention_dm: MentionDm,
        }}
      />
    </Layout>
  )
}

export default withPrismicPreview(Faq)

export const query = graphql`
  query ($lang: String) {
    faq: prismicFaq(lang: { eq: $lang }) {
      _previewable
      uid
      data {
        meta_description
        meta_title
        open_graph {
          url
        }
        body {
          ... on PrismicCollapseDefault {
            ...Collapse
          }
          ... on PrismicFaqHeaderDefault {
            ...FaqHeader
          }
          ... on PrismicMentionDmDefault {
            ...MentionDm
          }
        }
      }
    }
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
  }
`
