import styled from "styled-components"
import { mediaQueries, Dots, Controls, Arrow } from "@styles"

const Section = styled.section`
  overflow: hidden;
  padding: 80px 0;

  @media ${mediaQueries.medium} {
    padding: 154px 0 80px;
  }
`

const gutter = 24
/***
 * Slider
 *
 */
const Slider = styled.div`
  position: relative;
`
const Track = styled.div`
  transition: 0.3s transform;

  transform: translateX(-${props => 100 * props.active}%);
  @media ${mediaQueries.large} {
    transform: translateX(-${props => 50 * props.active}%);
  }

  display: flex;
  flex-flow: row nowrap;
  margin-left: -${gutter}px;

  > div {
    flex: 0 0 auto;
    padding-left: ${gutter}px;
    width: 100%;
  }

  @media ${mediaQueries.large} {
    > div {
      width: 50%;
      max-width: 50%;
    }
  }
`

/**
 * Testimonials
 */
const Article = styled.article`
  position: relative;
  padding: 32px 48px;
  background: #ffffff;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
  border-radius: 60px;

  height: 100%;
  padding-bottom: ${32 + 20 + 24}px;
`

const Header = styled.header`
  padding-bottom: 24px;
  border-bottom: 1px solid ${props => props.theme.colors.grey25};

  span {
    display: block;
  }

  > div:first-child {
    margin: 0 0 16px;
  }

  @media ${mediaQueries.medium} {
    display: flex;
    flex-flow: row nowrap;

    > div:first-child {
      margin: 0;
      flex: 1 1 auto;
      padding-right: 24px;
    }

    > div:last-child {
      flex: 0 0 auto;
      align-self: flex-end;
    }
  }
`

const Content = styled.div`
  padding-top: 24px;

  b,
  strong {
    font-weight: 700;
  }
`

const Footer = styled.footer`
  padding-top: 24px;
  time {
    color: ${props => props.theme.colors.grey75};
  }

  @media ${mediaQueries.large} {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 24px 48px;
  }
`

const Icon = styled.div`
  width: 80px;
  margin-bottom: 16px;
  margin-top: -72px;
`

const StarIcon = styled.span`
  clip-path: path(
    "M11.414 16.881l4.728 2.996c.604.383 1.355-.187 1.175-.892l-1.366-5.374a.821.821 0 01.266-.832l4.24-3.53c.557-.463.27-1.388-.446-1.434l-5.537-.36a.79.79 0 01-.68-.501l-2.065-5.2a.78.78 0 00-1.458 0l-2.065 5.2a.79.79 0 01-.68.501l-5.537.36c-.716.046-1.003.97-.446 1.435l4.24 3.529a.82.82 0 01.266.832l-1.267 4.983c-.215.847.685 1.53 1.41 1.071l4.394-2.784a.77.77 0 01.828 0z"
  );

  display: block;
  width: 22px;
  height: 21px;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTExLjQxNCAxNi44ODFsNC43MjggMi45OTZjLjYwNC4zODMgMS4zNTUtLjE4NyAxLjE3NS0uODkybC0xLjM2Ni01LjM3NGEuODIxLjgyMSAwIDAxLjI2Ni0uODMybDQuMjQtMy41M2MuNTU3LS40NjMuMjctMS4zODgtLjQ0Ni0xLjQzNGwtNS41MzctLjM2YS43OS43OSAwIDAxLS42OC0uNTAxbC0yLjA2NS01LjJhLjc4Ljc4IDAgMDAtMS40NTggMGwtMi4wNjUgNS4yYS43OS43OSAwIDAxLS42OC41MDFsLTUuNTM3LjM2Yy0uNzE2LjA0Ni0xLjAwMy45Ny0uNDQ2IDEuNDM1bDQuMjQgMy41MjlhLjgyLjgyIDAgMDEuMjY2LjgzMmwtMS4yNjcgNC45ODNjLS4yMTUuODQ3LjY4NSAxLjUzIDEuNDEgMS4wNzFsNC4zOTQtMi43ODRhLjc3Ljc3IDAgMDEuODI4IDB6IiBmaWxsPSIjZmZmIiBzdHJva2U9IiMxNzE3MTciIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);

  span {
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colors.grey100};
    transform: scaleX(${props => props.percentage});
    transform-origin: 0 0;
  }
`
const Stars = styled.ul`
  > li {
    display: inline-block;
    vertical-align: middle;
  }
`
const CusomtDots = styled(Dots)`
  @media ${mediaQueries.arrows} {
    display: none;
  }
`

const CustomControls = styled(Controls)`
  display: none;
  @media ${mediaQueries.arrows} {
    display: block;
  }
`

const ArrowNext = styled(Arrow)`
  right: -${62 + 24}px;
`

const ArrowPrev = styled(Arrow)`
  left: -${62 + 24}px;
  transform: rotate(180deg);
  &:hover {
    transform: rotate(180deg) scale(0.95);
  }
`

export {
  Section,
  Article,
  Header,
  Icon,
  StarIcon,
  Stars,
  Content,
  Footer,
  Slider,
  Track,
  CusomtDots,
  ArrowNext,
  ArrowPrev,
  CustomControls,
}
