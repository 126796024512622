import styled from "styled-components"

const Dots = styled.ul`
  text-align: center;
  margin: 24px 0;

  > li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px;
  }

  button {
    cursor: pointer;
    text-indent: 9999999px;
    display: block;
    padding: 0;
    margin: 0;
    border: none;
    width: 22px;
    height: 4px;
    border-radius: 20px;
    background-color: ${props => props.theme.colors.grey50};
  }

  li.active,
  li:hover {
    button {
      background-color: ${props => props.theme.colors.primary};
    }
  }
`

export default Dots
