import React from "react"
import Platforms from "./platforms"
import Player from "./player"

import { T4, T6 } from "@styles"

import {
  AuthorFlex,
  AuthorImage,
  FeaturedPodcast as MainPodcast,
  FeaturedPodcastImage as PodcastImage,
  FeaturePodcastContent,
  FooterTitle,
  PodcastFlex,
  PodcastFooter,
} from "./style"

const FeaturedPodcast = ({
  page,
  available_on,
  animated_by,
  monthly_podcast,
}) => {
  const data = page.document.data
  const content = data.content
  const acast =
    page.document.children && page.document.children.length > 0
      ? page.document.children[0]
      : {}
  const platforms = data.platforms || []

  return (
    <MainPodcast>
      <PodcastImage>
        <div className="gatsby-image-wrapper">
          <img src={acast.cover} alt={acast.title} />
        </div>
      </PodcastImage>

      <T6 as="h3">
        {monthly_podcast}
        <br />
        {acast.title}
      </T6>

      <FeaturePodcastContent>
        {acast.summary ? (
          <span dangerouslySetInnerHTML={{ __html: acast.summary }} />
        ) : (
          <p>{content.text}</p>
        )}
      </FeaturePodcastContent>

      <Player featured marginBottom="16px" {...acast} />

      <Platforms available_on={available_on} platforms={platforms} />

      <PodcastFooter>
        <div>
          <FooterTitle as="h4">{animated_by}</FooterTitle>
          <PodcastFlex>
            {data.guests.map((guest, key) => (
              <Author
                clip={`#memberN${key + 1}`}
                key={`guest-${key}`}
                image={guest.photo}
                name={guest.name}
              >
                <T4 as="h5">{guest.name}</T4>
              </Author>
            ))}
          </PodcastFlex>
        </div>
      </PodcastFooter>

      <svg width="0" height="0">
        <defs>
          <clipPath
            transform="scale(.00535)"
            clipPathUnits="objectBoundingBox"
            id="memberN1"
          >
            <path
              d="M81.41.771c20.409 0 40.83.436 61.225-.125 19.21-.527 32.214 8.384 39.91 25.113 7.629 16.583 3.44 35.205-9.946 48.631-5.935 5.952-11.739 12.17-18.381 17.245-7.554 5.776-7.67 11.697-4.141 19.819 9.41 21.644 17.936 43.673 27.262 65.358 3.084 7.175 1.257 9.181-6.072 9.154-44.02-.151-88.047.364-132.055-.349-16.037-.262-27.817-9.417-34.237-24.338-6.942-16.142-4.947-31.629 6.201-45.101 6.596-7.975 14.465-14.95 22.154-21.942 5.784-5.26 7.387-10.072 3.587-17.753-10.826-21.88-20.52-44.319-31.14-66.305C2.156 2.678 3.53.508 11.78.623c23.207.322 46.42.11 69.63.11v.04z"
              fill="#fff"
            />
          </clipPath>
          <clipPath
            transform="scale(.00538 .00535)"
            clipPathUnits="objectBoundingBox"
            id="memberN2"
          >
            <path
              d="M185.177 112.184c-.14 1.071-.353 2.146-.566 3.218-8.225 39.82-46.563 70.627-90.331 70.604-29.334-.023-56.112-13.646-73.102-34.62a87.013 87.013 0 01-7.429-10.654c-8.026-13.564-8.13-30.4.31-43.727.043-.066.088-.135.128-.203 5.548-8.692 12.87-16.976 22.275-24.826-7.06 0-10.472.02-13.886 0C.657 71.814.633 71.814.612 50.206.588 36.147.588 22.116.657 8.058.681 4.727.114.938 5.586.848 33.666.413 61.84-.936 87.078 14.607c8.244 5.086 15.024 11.866 23.86 19.784 0-10.111.263-17.916-.095-25.672-.258-5.728 1.495-8.102 7.938-7.987 19.715.317 39.43.091 59.148.114 3.599 0 7.322-.25 7.367 4.93.334 28.113 2.277 56.57-16.516 81.01-2.678 3.49-5.569 6.822-8.602 10.268a479.282 479.282 0 0015.168-.297c9.265-.342 11.348 2.465 10.45 11.022a76.776 76.776 0 01-.435 3.311c-.057.365-.118.729-.184 1.093z"
              fill="#fff"
            />
          </clipPath>
          <clipPath
            transform="scale(.00538 .00535)"
            clipPathUnits="objectBoundingBox"
            id="memberN3"
          >
            <path
              d="M158.476 123.019c4.064-2.39 8.482-4.183 12.546-6.573 6.971-4.112 11.413-9.084 13.4-15.012 1.489-4.446 1.604-9.418.402-14.988-.121-.502-.239-1.004-.428-1.506-2.883-10.063-12.557-18.573-24.666-18.573-23.29 0 3.897-11.737 11.883-16.422 6.285-3.681 10.515-8.797 12.525-14.773 1.605-4.757 1.817-10.064.542-15.633C182.482 9.882 172.298.726 161.453.655 138.252.487 55.86.94 37.876.965c-.919.024-1.817.072-2.74.096C13.87 3.81-.19 19.204.566 38.949c.759 20.08 16.991 35.784 37.879 36.478 5.621.167 11.245.023 16.868.023-15.827 16.829-36.151 38.892-43.429 46.661-18.64 19.936-14.688 51.003 10.493 60.692 11.974 4.608 124.803 2.941 137.636 2.773 12.45-.167 21.594-8.055 24.382-18.836.591-2.271.924-4.661.924-7.123 0-2.654-.307-5.188-.948-7.554-2.859-10.972-12.168-18.454-24.88-19.458-15.301-1.173-4.984-7.219-1.016-9.586z"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
    </MainPodcast>
  )
}

const Author = ({ image, children, clip }) => {
  return (
    <AuthorFlex>
      <div>
        <AuthorImage clip={clip}>
          <img width="68" src={image.url} alt={image.alt || ""} />
        </AuthorImage>
      </div>
      <div>{children}</div>
    </AuthorFlex>
  )
}

export default FeaturedPodcast
