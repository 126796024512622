import React from "react"
import { graphql } from "gatsby"

import { Container, T2, T3, T4, Grid, GridItem } from "@styles"
import { SectionNew as Section } from "./style"

const FeatureNew = ({ title, content_solution_new = {} }) => {
  return (
    <GridItem mb={4} col={{ md: 3, lg: 3 }}>
      <T3 as="h3" mb={1}>
        {title}
      </T3>
      <T4
        as="div"
        dangerouslySetInnerHTML={{ __html: content_solution_new.html }}
      />
    </GridItem>
  )
}

const New = ({ primary = {}, items = [] }) => {
  return (
    <Section>
      <Container>
        <T2 as="h2" mb={6}>
          {primary.title}
        </T2>
        <Grid>
          {items.map((feature, key) => (
            <FeatureNew key={`feature${key}`} {...feature} />
          ))}
        </Grid>
      </Container>
    </Section>
  )
}

export default New

export const query = graphql`
  fragment SolutionFeaturesNew on PrismicHiwFeaturesNewFeatures {
    id
    variation
    slice_type
    primary {
      title
    }
    items {
      title
      content_solution_new: content {
        html
      }
    }
  }
`
