import * as React from "react"
import { graphql } from "gatsby"

import { SliceZone } from "@prismicio/react"

import Seo from "@components/seo"

import { HomeCompaniesSlider } from "./home-components"
import HeaderInsurance from "@components/HeaderInsurance"
import { InsurancesFeatures, InsurancesOffer } from "./insurances-components"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { Video } from "./home-components"
import Logo from "@components/Logo"
import {
  Header,
  SolutionFeatures,
  HomeAppScreens,
} from "./insurance-register-components"

import { MentionDm } from "@slices"

const InsuranceRegister = ({ data = {} }) => {
  const doc = data.prismicInsuranceRegister || {}
  const docData = doc.data || {}
  const docTags = doc.tags || []
  const slices = docData.body || []
  const year = new Date().getFullYear()

  return (
    <>
      <Seo title="Télécharger l'application Goodmed." noIndex />
      <HeaderInsurance backgroundColor="#FFF" logo={docData.insurance_logo} />
      <SliceZone
        slices={slices}
        components={{
          insurance_register_header: Header,
          hiw_features: SolutionFeatures,
          home_companies_slider: HomeCompaniesSlider,
          home_app_screens: HomeAppScreens,
          mention_dm: MentionDm,
          insurances_features: InsurancesFeatures,
          insurances_offer: InsurancesOffer,
          video: Video,
        }}
        context={{ tag: docTags[0] }}
      />
      <footer style={{ padding: 30 }}>
        <center>
          <Logo />
          <p style={{ marginTop: 16 }}>
            {" "}
            &copy; Goodmed 2022 - {year}. {data.copyright}{" "}
          </p>
        </center>
      </footer>
    </>
  )
}

export default withPrismicPreview(InsuranceRegister)

export const query = graphql`
  query InsuranceRegister($id: String, $lang: String) {
    langParams: prismicInsuranceRegister(id: { eq: $id }, lang: { eq: $lang }) {
      uid
      lang
      tags
      url
      type
      alternate_languages {
        uid
        type
        lang
      }
    }
    prismicInsuranceRegister(id: { eq: $id }, lang: { eq: $lang }) {
      lang
      url
      tags
      type
      _previewable
      data {
        insurance_logo {
          alt
          url
        }
        body {
          ... on PrismicInsuranceRegisterHeaderDefault {
            ...InsuranceRegisterHeader
          }
          ... on PrismicHiwFeaturesDefault {
            ...SolutionFeatures
          }
          ... on PrismicInsurancesFeaturesDefault {
            ...InsurancesFeatures
          }
          ... on PrismicHomeCompaniesSliderDefault {
            ...HomeCompaniesSlider
          }
          ... on PrismicHomeAppScreensDefault {
            ...HomeAppScreens
          }
          ... on PrismicMentionDmDefault {
            ...MentionDm
          }
          ... on PrismicInsurancesOfferDefault {
            ...InsurancesOffer
          }
          ... on PrismicInsurancesOfferStore {
            ...InsurancesOfferStore
          }
          ... on PrismicVideoDefault {
            ...Video
          }
        }
      }
    }
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
  }
`
