import { graphql } from "gatsby"
import React from "react"
import Subscribe from "@components/Subscribe"
import { Link as GatsbyLink } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { ButtonBlue } from "@styles"

import { Card, CardImage, CardTitle, CardGridItem, Stores } from "./style"

const CardHeader = ({ image, alt }) => (
  <CardImage>
    {image && (
      <GatsbyImage image={image} alt={alt} sizes={`${image.width}px`} />
    )}
  </CardImage>
)

const Newsletter = ({ slice }) => {
  const primary = slice.primary
  const title = primary.card_title
  const image = getImage(primary.card_image)

  return (
    <CardGridItem col={{ md: 6, lg: 6 }}>
      <Card>
        <CardHeader image={image} alt={title} />

        <CardTitle as="h3">{title}</CardTitle>

        <Subscribe
          placeholder={primary.placeholder}
          submitButton={primary.card_cta}
          successMessage={primary.success_message}
          errorMessage={primary.error_message}
        />
      </Card>
    </CardGridItem>
  )
}

const AppStores = ({ slice }) => {
  const primary = slice.primary
  const items = slice.items
  const title = primary.card_title
  const image = getImage(primary.card_image)
  return (
    <CardGridItem col={{ md: 6, lg: 6 }}>
      <Card>
        <CardHeader image={image} alt={title} />
        <CardTitle as="h3">{title}</CardTitle>

        <Stores>
          {items.map(({ link, logo }, key) => {
            const img = getImage(logo)
            return (
              <GatsbyLink key={`link-${key}`} to={link.url}>
                <GatsbyImage
                  image={img}
                  alt={"store"}
                  sizes={`${img.width}px`}
                />
              </GatsbyLink>
            )
          })}
        </Stores>
      </Card>
    </CardGridItem>
  )
}

const Cta = ({ slice }) => {
  const primary = slice.primary
  const title = primary.card_title
  const image = getImage(primary.card_image)
  const link = primary.link || {}
  return (
    <CardGridItem col={{ md: 6, lg: 6 }}>
      <Card>
        <CardHeader image={image} alt={title} />
        <CardTitle as="h3">{title}</CardTitle>

        <ButtonBlue as="a" href={link.url}>
          <span>{primary.card_cta}</span>
        </ButtonBlue>
      </Card>
    </CardGridItem>
  )
}

export { Newsletter, AppStores, Cta }

export const query = graphql`
  fragment CardNewsletter on PrismicBlogPostDataBody1CardNewsletter {
    id
    slice_type
    primary {
      card_cta
      card_title
      error_message
      placeholder
      success_message
      card_image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
    }
  }

  fragment CardStores on PrismicBlogPostDataBody1CardStores {
    id
    slice_type
    primary {
      card_title
      card_image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
    }
    items {
      link {
        url
      }
      logo {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
    }
  }

  fragment CardCta on PrismicBlogPostDataBody1CardCallToAction {
    id
    slice_type
    primary {
      card_cta
      card_title
      link {
        url
      }
      card_image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
    }
  }
`
