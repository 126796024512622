import styled from "styled-components"

import { T2, P0, ButtonBlue, mediaQueries, Arrow, Controls } from "@styles"

const base64Header =
  "PHN2ZyB3aWR0aD0iMTkyMCIgaGVpZ2h0PSI3NjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE4ODcuMDctMzE3TC01Ny0xODEuMDU4IDkuODI1IDQ3MS4zNDljMTcuNzQzIDE3My4yMjUgMTcyLjk0NCAzMDMuODA0IDM0Ni42NSAyOTEuNjU3bDEzMTUuMDE1LTkxLjk1NGMxNzMuNzEtMTIuMTQ3IDMwMC4xNC0xNjIuNDIgMjgyLjQtMzM1LjY0NEwxODg3LjA3LTMxN3oiIGZpbGw9IiNGRUY4RUMiLz48L3N2Zz4="

/***
 * Slider controls
 */
const CustomControls = styled(Controls)`
  display: none;
  @media ${mediaQueries.medium} {
    display: block;
  }
`

const ArrowNext = styled(Arrow)`
  right: 8px;
  @media ${mediaQueries.siteWidth} {
    right: -${62 + 24}px;
  }
`

const ArrowPrev = styled(Arrow)`
  left: 8px;
  transform: rotate(180deg);
  &:hover {
    transform: rotate(180deg) scale(0.95);
  }
  @media ${mediaQueries.siteWidth} {
    left: -${62 + 24}px;
  }
`

/**
 * Main frame
 */
const MainSlider = styled.div`
  position: relative;

  width: 100%;
  overflow: hidden;

  background-size: 1440px;
  background-image: url(data:image/svg+xml;base64,${base64Header});
  background-position: top left -56px;

  @media ${mediaQueries.large} {
    background-size: 1920px;
    background-position: top center;
  }
`

const Track = styled.div`
  transition: 1.2s transform ease-in-out;
  transform: ${({ currentSlide }) => `translateX(-${currentSlide * 100}vw)`};

  flex-flow: row nowrap;
  display: flex;
  > div {
    flex: 0 0 auto;
    width: 100vw;
  }

  @media ${mediaQueries.medium} {
    height: auto !important;
  }
`

const SlideDiv = styled.div`
  padding: 88px 0 0;
  @media ${mediaQueries.medium} {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media ${mediaQueries.siteWidth} {
    padding: 148px 0 100px;
  }
`

/**
 * Content
 */
const Title = styled(T2)`
  margin-bottom: 16px;
`

const Content = styled(P0)`
  margin-bottom: 24px;
`

const Button = styled(ButtonBlue)`
  margin-bottom: 32px;
  @media ${mediaQueries.medium} {
    margin-bottom: 0;
  }
`

const Image = styled.div`
  display: none;
  position: relative;

  clip-path: url(#slider-shape);

  max-width: 420px;
  margin: 0 auto;

  > span {
    display: block;
    padding-bottom: 100%;
  }

  .gatsby-image-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media ${mediaQueries.large} {
    display: block;
  }
`

const Header = styled.div`
  margin-bottom: 16px;
`

const Date = styled.time`
  font-size: 16px;
  line-height: 18px;
  vertical-align: middle;
  margin-left: 24px;
`

export {
  MainSlider,
  SlideDiv,
  Title,
  Content,
  Button,
  CustomControls,
  Arrow,
  Track,
  ArrowNext,
  ArrowPrev,
  Image,
  Header,
  Date,
}
