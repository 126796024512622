import React from "react"
import { P1 } from "@styles"
import { Platform } from "./style"

const Platforms = ({ platforms = [], available_on }) => {
  return platforms.length > 0 ? (
    <P1 as="p">
      {available_on} :
      {platforms.map(({ name, link }, key) => (
        <Platform key={`platform-${key}`} href={link.url} target="_blank">
          {name}
        </Platform>
      ))}
    </P1>
  ) : null
}

export default Platforms
