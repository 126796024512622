import styled from "styled-components"

import { mediaQueries, Container } from "@styles"

const ThisContainer = styled(Container)`
  @media ${mediaQueries.preMedium} {
    padding: 0;
    width: 100%;
  }
`

const Section = styled.section`
  position: relative;
  z-index: 2;
  transform: translateY(0%);
  padding-bottom: 20px;
  padding-top: 124px;

  /* @media ${mediaQueries.medium} {
    transform: translateY(-50%);
  } */

  &:last-child {
    margin-bottom: -224px;
  }
`

const Block = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 60px 60px;
  padding: 0 20px 48px;

  @media ${mediaQueries.medium} {
    border-radius: 60px;
  }
`

const Image = styled.div`
  text-align: center;
  max-width: 180px;
  margin-left: auto;
  margin-right: auto;

  transform: translateY(-50%);
  margin-bottom: -24px;

  @media ${mediaQueries.medium} {
    max-width: 100%;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;

  @media ${mediaQueries.medium} {
    width: ${(100 * 10) / 12}%;
  }

  @media ${mediaQueries.large} {
    width: ${(100 * 8) / 12}%;
  }

  h2 {
    text-align: center;
    margin-bottom: 48px;
  }
`

const StoresLogo = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: flex-flow row wrap;
  justify-content: center;

  > div,
  > a {
    flex: 0 0 auto;
    margin: 0 6px 8px;
  }

  a {
    transition: 0.3s transform;
  }

  a:hover {
    transform: scale(0.95);
  }
`

export { Section, Block, Image, Wrapper, StoresLogo, ThisContainer }
