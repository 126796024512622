import styled from "styled-components"
import { mediaQueries } from "@styles"
import { T5 } from "@styles"

const Title = styled(T5)`
  span {
    font-size: 16px;
  }
`

const Section = styled.section`
  padding: 80px 0;
  @media ${mediaQueries.preMedium} {
    h2 {
      text-align: left;
    }
  }
`

const Offers = styled.div`
  display: flex;
  flex-flow: column-reverse;

  @media ${mediaQueries.medium} {
    flex-flow: row;
    align-items: center;

    article {
      margin-left: ${(100 * 1) / 12}%;
    }

    article:first-child {
      width: ${(100 * 5) / 12}%;
      max-width: ${(100 * 5) / 12}%;
      padding-right: ${(100 * 1) / 12}%;
      margin-left: ${(100 * 1) / 12}%;
    }

    article:last-child {
      width: ${(100 * 6) / 12}%;
      max-width: ${(100 * 6) / 12}%;
      margin-left: -${(100 * 1) / 12}%;
    }
  }

  article:first-child {
    ul li::before {
      background-color: ${props => props.theme.colors.primaryLight};
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0Ljg3NSAxLjYyNWwtOC43NSA4Ljc1TDEuNzUgNiIgc3Ryb2tlPSIjMkQ4MUZGIiBzdHJva2Utd2lkdGg9IjIuMzUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);
    }

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }

  article:last-child {
    ul li::before {
      background-color: ${props => props.theme.colors.orangeLight};
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0Ljg3NSAxLjYyNWwtOC43NSA4Ljc1TDEuNzUgNiIgc3Ryb2tlPSIjMTcxNzE3IiBzdHJva2Utd2lkdGg9IjIuMzUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);
    }

    position: relative;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -3;
      bottom: 0;
      right: 0;

      filter: blur(54px);
      background: linear-gradient(
        114.77deg,
        #ff9edb 14.19%,
        #ff7d4f 29.5%,
        #ffe286 44.44%,
        #ffe286 53.78%,
        #ff885d 69.09%,
        #ff9edb 84.48%
      );
      opacity: 0.35;
    }

    @media ${mediaQueries.medium} {
      ul {
        columns: 2;
      }
    }

    > div {
      position: relative;
      z-index: 1;

      &::after,
      &::before {
        content: "";
        position: absolute;
      }

      &::before {
        z-index: -1;
        background: linear-gradient(
            114.77deg,
            rgba(255, 158, 219, 0.15) 14.19%,
            rgba(255, 125, 79, 0.15) 29.5%,
            rgba(255, 226, 134, 0.15) 44.44%,
            rgba(255, 226, 134, 0.15) 53.78%,
            rgba(255, 136, 93, 0.15) 69.09%,
            rgba(255, 158, 219, 0.15) 84.48%
          ),
          #ffffff;
        left: 6px;
        right: 6px;
        top: 5px;
        bottom: 5px;
        border-radius: 30px;
      }

      &::after {
        z-index: -2;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          114.77deg,
          #ff9edb 14.19%,
          #ff7d4f 29.5%,
          #ffe286 44.44%,
          #ffe286 53.78%,
          #ff885d 69.09%,
          #ff9edb 84.48%
        );
        border-radius: 34px;
      }
    }
  }
`

const Offer = styled.article`
  border-radius: 30px;
  margin-bottom: 32px;

  > div {
    padding: 24px 32px;
  }
  @media ${mediaQueries.medium} {
    margin: 0;
    > div {
      padding: 48px;
    }
  }
`

const Content = styled.div`
  p + ul {
    margin-top: 24px;
  }

  ul li {
    margin: 0 0 28px;

    font-size: 15px;
    line-height: 17px;

    font-family: "stolz";
    em {
      font-family: "inter";
    }

    padding-left: ${12 + 30}px;
    position: relative;
    &::before {
      content: "";
      position: absolute;

      width: 30px;
      height: 30px;
      border-radius: 50%;

      left: 0;
      top: -6px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`

const Button = styled.div`
  text-align: center;
  @media ${mediaQueries.medium} {
    margin-top: 80px;
  }
`

export { Section, Offers, Offer, Content, Button, Title }
