import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"
import { SliceZone } from "@prismicio/react"

import { Stores } from "./about-us-components"
import { Header, Podcasts, TitlePodcast } from "./podcasts-components"
import { Video } from "./home-components"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

/**
 * Pass document data to slice
 * @param {React.Component} Component
 * @param {*} props
 * @returns
 */
function withDocData(Component, props) {
  return function HOC() {
    return <Component {...props} />
  }
}

const PodcastTemplate = ({ data }) => {
  const langParams = data.langParams || {}
  const navigation = data.prismicNavigation || {}
  const doc = data.page || {}
  const page = doc.data || {}
  const slices = page.body || []
  const podcastsNodes = data.podcasts || {}
  const podcasts = podcastsNodes.nodes || []

  return (
    <Layout navigation={navigation} languages={langParams}>
      <Seo
        title={page.meta_title}
        description={page.meta_description}
        openGraphImg={page.open_graph && page.open_graph.url}
      />

      <SliceZone
        slices={slices}
        components={{
          podcast_header: Header,
          title_podcast: TitlePodcast,
          about_us_app_store: Stores,
          video: Video,
          podcasts: withDocData(Podcasts, { page, podcasts }),
        }}
      />
    </Layout>
  )
}

export default withPrismicPreview(PodcastTemplate)

export const query = graphql`
  query ($lang: String) {
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
    podcasts: allPrismicPodcastType(filter: { lang: { eq: $lang } }) {
      nodes {
        ...DocPodcast
      }
    }
    langParams: prismicPodcastPage(lang: { eq: $lang }) {
      uid
      lang
      url
      type
      alternate_languages {
        uid
        type
        lang
      }
    }
    page: prismicPodcastPage(lang: { eq: $lang }) {
      uid
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      data {
        animated_by
        all
        available_on
        guests
        monthly_podcast
        meta_title
        meta_description
        open_graph {
          url
        }
        featured_podcast {
          document {
            ... on PrismicPodcastType {
              ...DocPodcast
            }
          }
        }
        body {
          ... on PrismicAboutUsAppStoreDefault {
            ...AboutUsStores
          }
          ... on PrismicPodcastHeaderDefault {
            ...PodcastHeader
          }
          ... on PrismicPodcastsDefault {
            ...PodcastPodcasts
          }
          ... on PrismicVideoDefault {
            ...Video
          }
          ... on PrismicTitlePodcastDefault {
            ...TitlePodcast
          }
        }
      }
    }
  }
`
