import styled from "styled-components"
import { mediaQueries, Dots, Container } from "../../../styles"

const gutter = 24
const slideWidth = 300

const CustomContainer = styled(Container)`
  position: relative;
`

const Wrapper = styled.div`
  @media ${mediaQueries.large} {
    max-width: ${(100 * 10) / 12}%;
    margin: 0 auto;
  }
`

const Section = styled.section`
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    width: 1400px;
    max-width: calc(100% - 20px);
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 0;

    height: 100%;

    background-color: ${props => props.theme.colors.primaryLight};
    border-radius: 0 0 60px 60px;
  }

  padding: 48px 0 80px;
  @media ${mediaQueries.medium} {
    padding: 0 0 154px;
  }
`

/**
 * Responsive Grid
 */
const Grid = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: ${slideWidth}px;

  > div {
    flex: 0 0 auto;
    width: ${slideWidth + gutter}px;
    padding-left: ${gutter}px;

    &:first-child {
      margin-left: -24px;
    }
  }

  @media ${mediaQueries.large} {
    flex-flow: row wrap;
    margin: 0 auto;
    width: calc(100% + ${gutter}px);

    margin-left: -${gutter}px;
    margin-right: 0;

    > div {
      flex: 1 1 auto;
      width: 33.33%;
      max-width: 33.33%;

      &:first-child {
        margin-left: 0;
      }
    }
  }
`
/**
 * Track
 */
const Slider = styled.div`
  position: relative;
`

const Track = styled(Grid)`
  transition: 0.3s transform;
  transform: translateX(-${props => 108 * props.active}%);
  @media ${mediaQueries.large} {
    transform: translateX(-${props => 50 * props.active}%);
  }
`

/**
 * Dots
 */
const CustomDots = styled(Dots)`
  @media ${mediaQueries.large} {
    display: none;
  }
`

export { CustomContainer, Section, Grid, Slider, CustomDots, Wrapper, Track }
