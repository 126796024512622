import React from "react"
import { graphql } from "gatsby"

import { AppLink } from "@components/CustomLink"
import { Title, Submenu, MenuItem } from "./style"

const ListItem = ({ link = {}, title }) => {
  return (
    <li>
      <AppLink link={link} activeClassName="active">
        {title}
      </AppLink>
    </li>
  )
}

const RegularMenu = ({ slice }) => {
  const primary = slice.primary
  const items = slice.items
  return (
    <MenuItem>
      <Title>{primary.name}</Title>
      <Submenu>
        {items.map((item, key) => (
          <ListItem key={`item${key}`} {...item} />
        ))}
      </Submenu>
    </MenuItem>
  )
}

export default RegularMenu

export const query = graphql`
  fragment RegularMenu on PrismicNavigationDataBody1RegularMenu {
    id
    slice_type
    primary {
      name
    }
    items {
      link {
        url
        link_type
        target
      }
      title
    }
  }
`
