import React from "react"
import { graphql } from "gatsby"

import { Container, T6, P0, ButtonBlueBig } from "@styles"
import { Section, Offers, Offer, Content, Button, Title } from "./style"

const Item = ({ name, price, month, contentsub }) => {
  return (
    <Offer>
      <div>
        {price && (
          <Title as="h2" mb={2}>
            {price}
            <span>{month}</span>
          </Title>
        )}
        <P0 as="h3" mb={0.5}>
          {name}
        </P0>
        <Content
          dangerouslySetInnerHTML={{ __html: contentsub && contentsub.html }}
        />
      </div>
    </Offer>
  )
}

const PremiumSubscribe = ({ slice }) => {
  const primary = slice.primary || {}
  const items = slice.items || []
  const link = primary.link || {}

  return (
    <Section>
      <Container>
        <T6 as="h2" pre center mb={7} mbmd={8}>
          {primary.title}
        </T6>
        <Offers>
          {items.map((item, key) => (
            <Item key={`offer-${key}`} {...item} />
          ))}
        </Offers>

        <Button>
          <ButtonBlueBig as="a" target="_blank" href={link.url}>
            {primary.button}
          </ButtonBlueBig>
        </Button>
      </Container>
    </Section>
  )
}

export default PremiumSubscribe

export const query = graphql`
  fragment PremiumSubscribe on PrismicPremiumSubscribeDefault {
    id
    primary {
      title
      button
      link {
        url
      }
    }
    slice_type
    variation
    items {
      contentsub: content {
        html
      }
      name
      price
      month
    }
  }
`
