import styled from "styled-components"
import { mediaQueries } from "@styles"

export const Section = styled.section`
  padding: 0px 0 80px;
  @media ${mediaQueries.medium} {
    padding: 80px 0 154px;
  }
`
export const SectionNew = styled.section`
  padding: 80px 0;
  @media ${mediaQueries.medium} {
    padding: 154px 0;
  }
`

export const SectionSimple = styled.section`
  padding: 40px 0;
  @media ${mediaQueries.medium} {
    padding: 154px 0;
  }
`

export const Gradient = styled.span`
  div:nth-child(2) > h3 & {
    display: inline-block;
    background: linear-gradient(
      114.77deg,
      #ffe286 14.19%,
      #ff885d 50.04%,
      #ff9edb 84.48%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
