import styled from "styled-components"
import { mediaQueries } from "@styles"

const Section = styled.section`
  padding: 80px 0;

  @media ${mediaQueries.medium} {
    display: block;
  }
`
const Companies = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    zoom: 1;
    padding: 32px;
  }
`

export { Section, Companies }
