import styled from "styled-components"

import { mediaQueries, P0 } from "@styles"

const Content = styled(P0)`
  margin-bottom: 48px;
`

const Section = styled.section`
  position: relative;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTI0MyIgaGVpZ2h0PSI4MDYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTExOTcuNzMgMEgwbDQzLjkzMiA2MjAuNDY1YzkuNDA2IDExMS4xNDQgMTA3LjEzMiAxOTMuNjE5IDIxOC4yNzYgMTg0LjIxM2w3OTUuMjQxLTY3LjMwMmMxMTEuMTQ0LTkuNDA2IDE5My42MTktMTA3LjEzMiAxODQuMjEzLTIxOC4yNzZMMTE5Ny43MyAweiIgZmlsbD0iI0VBRjJGRiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+);
  background-position: bottom right -64px;
  background-size: 1242px;
  width: 100%;
  overflow: hidden;

  padding: 88px 0 64px;
  @media ${mediaQueries.medium} {
    padding: 188px 0 220px;
    background-position: top -260px center;
    background-size: 2013px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAxMyIgaGVpZ2h0PSIxMDgxIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xOTQ0LjA3IDBMMCAxMzUuOTQybDY2LjgyNSA2NTIuNDA3YzE3Ljc0MyAxNzMuMjI1IDE3Mi45NDQgMzAzLjgwMSAzNDYuNjUgMjkxLjY2MWwxMzE1LjAxNS05MS45NThjMTczLjcxLTEyLjE0NyAzMDAuMTQtMTYyLjQyIDI4Mi40LTMzNS42NDRMMTk0NC4wNyAweiIgZmlsbD0iI0VBRjJGRiIvPjwvc3ZnPg==);
    min-height: 900px;
  }

  h1 {
    margin-bottom: 24px;
  }
`

const Image = styled.div`
  display: none;
  position: absolute;
  // top: -163px;
  top: 0;
  left: 60%;
  width: 889px;

  clip-path: url(#podcast-header);

  @media ${mediaQueries.large} {
    display: block;
  }

  @media (min-width: 2245px) {
    left: auto;
    right: 0;
  }
`

const Wrapper = styled.div`
  @media ${mediaQueries.large} {
    width: ${(100 * 7) / 12}%;
  }
`

export { Section, Image, Wrapper, Content }
