import React from "react"
import { PrismicRichText } from "@prismicio/react"
import slugify from "slugify"
import { graphql } from "gatsby"
import { GatsbyLink } from "../components/CustomLink"

export const Text = ({ slice }) => {
  const primary = slice.primary
  const id = slice.id
  return (
    <div className="post-text container" id={id}>
      <div>
        <PrismicRichText
          serializeHyperlink={GatsbyLink}
          field={primary.text.richText || []}
          components={{
            heading2: ({ children, text }) => (
              <h2 id={`slice-${slugify(text, { strict: true })}`}>
                {children}
              </h2>
            ),
          }}
        />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment BlogPostDataBodyText on PrismicBlogPostDataBodyText {
    primary {
      text {
        richText
      }
    }
  }
`
