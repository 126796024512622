import React from "react"
import { graphql } from "gatsby"

import { Container, T6, T4, GridJustified as Grid, GridItem } from "@styles"
import { SectionSimple as Section, Gradient } from "./style"

const FeatureSimple = ({ title, content_solution = {} }) => {
  return (
    <GridItem mb={4} col={{ md: 6, lg: 4 }}>
      <T6 as="h3" center mb={1}>
        <Gradient>{title}</Gradient>
      </T6>
      <T4
        as="div"
        center
        dangerouslySetInnerHTML={{ __html: content_solution.html }}
      />
    </GridItem>
  )
}

const Simple = ({ items = [] }) => {
  return (
    <Section>
      <Container>
        <Grid>
          {items.map((feature, key) => (
            <FeatureSimple key={`feature${key}`} {...feature} />
          ))}
        </Grid>
      </Container>
    </Section>
  )
}

export default Simple

export const query = graphql`
  fragment SolutionFeaturesSimple on PrismicHiwFeaturesSimpleFeatures {
    id
    variation
    slice_type
    items {
      title
      content_solution: content {
        html
      }
    }
  }
`
