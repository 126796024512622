import styled from "styled-components"

import { mediaQueries, Container, Dots } from "@styles"

import {
  Block,
  Image as CardImage,
  Wrapper as CardWrapper,
  ThisContainer as BlockContainer,
  Title as BlockTitle,
  ThisGridItem as CardGridItem,
  Stores,
} from "@templates/blog-components/slice-cta-blocks/style"

const gutter = 24
const slideWidth = 300

/**
 * Card
 */

const CardContainer = styled(BlockContainer)`
  #RelatedArticles + & {
    margin-top: -120px;
    margin-bottom: 120px;
  }
`
const Slider = styled.div`
  position: relative;
  width: 100%;
`

const Card = styled(Block)`
  width: 100%;
  max-width: 600px;
  z-index: 1;
`

/**
 * Article grid
 */
const ArticleWrapper = styled.div`
  padding: 96px 0 280px;
  margin: 0 auto;

  h2 {
    margin-bottom: 48px;
  }
  @media ${mediaQueries.large} {
    padding: 96px 0 385px;
    width: ${(100 * 10) / 12}%;

    h2 {
      margin-bottom: 64px;
    }
  }
`

/**
 * Responsive Grid
 */
const Grid = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: ${slideWidth}px;

  > div {
    flex: 0 0 auto;
    width: ${slideWidth + gutter}px;
    padding-left: ${gutter}px;

    &:first-child {
      margin-left: -24px;
    }
  }

  @media ${mediaQueries.large} {
    flex-flow: row wrap;
    margin: 0 auto;
    width: calc(100% + ${gutter}px);

    margin-left: -${gutter}px;
    margin-right: 0;

    > div {
      flex: 1 1 auto;
      width: 33.33%;
      max-width: 33.33%;

      &:first-child {
        margin-left: 0;
      }
    }
  }
`

const Track = styled(Grid)`
  transition: 0.3s transform;
  transform: translateX(-${props => 108 * props.active}%);
  @media ${mediaQueries.large} {
    transform: translateX(-${props => 50 * props.active}%);
  }
`

const CardTitle = styled(BlockTitle)`
  margin-bottom: 24px;
`

/**
 * Dots
 */
const CustomDots = styled(Dots)`
  @media ${mediaQueries.large} {
    display: none;
  }
`

/**
 * Sticky list
 */
const List = styled.div`
  display: block;

  ul {
    position: relative;
    padding-left: 1rem;
    &::before {
      transition: all 0.2s ease-in-out;
      content: "   ";
      background-color: ${props => props.theme.colors.grey25};
      width: 5px;
      position: absolute;
      left: 0px;
      top: 0;
      height: 100%;
      border-radius: 20px;
    }
  }

  @media all and (max-width: 1200px) {
    display: none;
  }

  a {
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    line-height: 1.25;
    &:hover {
      transition: all 0.2s;
      cursor: pointer;
    }
  }
`

const ListItem = styled.li`
  position: relative;
  margin-bottom: 14px;
  transition: all 0.2s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    &:hover {
      color: ${props => props.theme.colors.orange};
    }
  }
  a.active {
    transition: all 0.2s ease-in-out;
    &::before {
      content: "   ";
      background-color: ${props => props.theme.colors.orange};
      width: 5px;
      position: absolute;
      left: -16px;
      top: 0;
      height: 100%;
      border-radius: 20px;
    }
  }
`

/**
 * Container
 */
const ThisContainer = styled(Container)`
  position: relative;
  display: flex;
  max-width: 1220px;

  @media ${mediaQueries.preMedium} {
    width: 100%;
  }

  margin: 10rem auto 0px;

  padding-top: 10vh;
  padding-left: 0;
  padding-right: 0;

  em {
    font-style: italic;
  }

  .active {
    color: ${props => props.theme.colors.orange};
  }
`
/**
 * Divider
 */
const Divider = styled.hr`
  background-color: ${props => props.theme.colors.grey25};
  height: 1px;
  margin: 0;
  border: 0;
`

/**
 * Blogpost content
 */
const Intro = styled.div`
  margin-bottom: 48px;
  font-family: "stolz";
  font-size: 1.35rem;
  font-weight: 400;
  p {
    line-height: 1.4;
  }
`

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  @media all and (max-width: 980px) {
    font-size: 2rem;
  }
`
const Wrapper = styled.section`
  padding: 24px 0;
  z-index: 1;

  @media ${mediaQueries.medium} {
    padding: 48px 0;
  }

  ul,
  ol {
    padding-left: 16px;
    margin-bottom: 1rem;
    list-style-type: initial;
  }

  li {
    margin-bottom: 0.75rem;
    font-size: 1.1rem;
    line-height: 1.33;
  }

  h2 {
    font-size: 2.25rem;
    margin-bottom: 1.5rem;

    @media all and (max-width: 1439px) {
      font-size: 2rem;
    }
  }

  @media all and (max-width: 1439px) {
    padding: 2.8rem 0;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.33;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }

  strong {
    font-weight: 700;
  }

  p a {
    color: ${props => props.theme.colors.primaryMedium};
    font-weight: 500;
    text-decoration: none;
    &:hover {
      font-weight: 700;
    }
  }
`

/**
 * Content wrapper
 */
const Section = styled.section`
  position: relative;
  display: inline-flex;
  flex-direction: column;

  max-width: 955px;
  width: 100%;

  border-radius: 50px;
  background-color: white;

  padding: 0 24px;
  margin: 0 auto;

  div[data-oembed-type="video"] {
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media ${mediaQueries.medium} {
    padding: 0 ${100 / 12}%;
    margin-right: 24px;
  }

  @media ${mediaQueries.large} {
    margin-right: 0;
  }
`

/**
 * Header
 */
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`

const Shadow = styled.div`
  height: 400px;
  box-shadow: 0px -24px 30px rgb(0 0 0 / 8%);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50px;
  right: 0;
  width: 100%;
`

const Infos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  grid-gap: 8px;

  padding: 24px 0;
  @media ${mediaQueries.medium} {
    padding: 48px 0 56px;
  }
`

const DateText = styled.div`
  font-size: 14px;
  margin-left: 20px;
  color: ${props => props.theme.colors.grey100};
  white-space: nowrap;

  @media ${mediaQueries.medium} {
    font-size: 16px;
  }
`

const Author = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    flex: 0 0 auto;
    width: 36px;
  }

  > div:last-child {
    flex: 1 1 auto;
    padding-left: 8px;
  }

  img {
    border-radius: 14px;
  }

  .name {
    font-weight: 700;
    font-family: "stolz";
    color: ${props => props.theme.colors.grey100};
    font-size: 16px;
    margin-bottom: 2px;
  }

  .job {
    display: none;
    font-size: 14px;
    color: ${props => props.theme.colors.grey75};
    @media ${mediaQueries.medium} {
      display: block;
    }
  }
`

/**
 * Image
 */
const BackgroundLayout = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 320px;

  @media ${mediaQueries.medium} {
    left: 50%;
    width: 1963px;
    transform: translateX(-50%);
    clip-path: url(#header-image);

    &::after {
      content: "";
      display: block;
      padding-bottom: ${(100 * 471) / 1963}%;
    }

    > .gatsby-image-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;

      transform: translate(-50%, -50%);
    }
  }
`

export {
  Image,
  BackgroundLayout,
  Infos,
  DateText,
  Author,
  HeaderLeft,
  Shadow,
  Section,
  Wrapper,
  ThisContainer,
  Divider,
  List,
  ListItem,
  ArticleWrapper,
  Card,
  Intro,
  Title,
  CardImage,
  CardWrapper,
  CardContainer,
  CardTitle,
  CardGridItem,
  Stores,
  Slider,
  Track,
  CustomDots,
}
