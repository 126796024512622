import React from "react"
import paginationAlgo from "@utils/functions/paginationAlgo"
import { linkResolver } from "@utils/linkResolver"

import { Pagination as Nav, Dot } from "./style"

const Pagination = ({ pages, page, doc }) => {
  if (pages < 2) return null

  // Page list
  const arrPages = React.useMemo(
    () => paginationAlgo(page, pages),
    [page, pages]
  )

  return (
    <Nav>
      <ul>
        {arrPages.map((i, key) => (
          <Dot key={`page-${key}`} current={i === page}>
            {typeof i === "number" ? (
              <a
                href={`${linkResolver({
                  ...doc,
                  page: i,
                })}#ArticleGrid`}
              >
                {i}
              </a>
            ) : (
              <span>{i}</span>
            )}
          </Dot>
        ))}
      </ul>
    </Nav>
  )
}

export default Pagination
