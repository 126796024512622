import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Stores from "@components/stores"

import { Container, T2 } from "@styles"
import { Header, Wrapper, Image, Content, Icon } from "./style"

const HomeHeader = ({ slice }) => {
  const primary = slice.primary || {}
  const items = slice.items || []

  const content = primary.content || {}
  const image = getImage(primary.image)
  const image2 = getImage(primary.image_2)
  const image3 = getImage(primary.image_3)
  const icon = getImage(primary.icon)

  return (
    <Header>
      <Container>
        <Wrapper>
          <Icon>
            <GatsbyImage image={icon} alt="icon" sizes={`136px`} />
          </Icon>
          <T2 as="h1">{primary.title}</T2>
          <Content
            as="div"
            dangerouslySetInnerHTML={{
              __html: content.html,
            }}
          />

          <Stores stores={items} />
        </Wrapper>
      </Container>
      <Image>
        <GatsbyImage
          image={image}
          className="img img-1"
          alt={primary.title || ""}
          sizes="779px"
        />
        <GatsbyImage
          image={image2}
          className="img img-2"
          alt={primary.title || ""}
          sizes="779px"
        />
        <GatsbyImage
          image={image3}
          className="img img-3"
          alt={primary.title || ""}
          sizes="779px"
        />
      </Image>
      <svg width="0" height="0">
        <defs>
          <clipPath
            clipPathUnits="objectBoundingBox"
            transform="scale(.0013158 .001287)"
            id="home-header"
          >
            <path
              d="M408.329-43.587c-70.61.01-141.241-1.071-211.819.276C99.028-41.454 22.164 19.784 4.334 107.508c-19.367 95.227 26.947 182.282 123.347 219.231 38.257 14.665 27.795 24.279 7.018 43.719-27.063 25.287-53.352 51.55-78.613 78.651-74.543 79.957-73.589 197.711 1.441 271.8 74.119 73.176 189.95 76.126 267.196.753 147.823-144.24 293.24-290.963 438.499-437.793 59.32-59.943 72.878-132.79 41.066-210.083C772.136-4.272 708.894-41.73 625.447-43.311c-72.348-1.369-144.738-.276-217.118-.276z"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
    </Header>
  )
}

export default HomeHeader

export const query = graphql`
  fragment HomeHeader on PrismicHomeHeaderDefault {
    id
    slice_type
    variation
    primary {
      title
      image {
        gatsbyImageData(
          layout: FIXED
          width: 779
          height: 779
          imgixParams: { q: 100 }
        )
      }
      image_2 {
        gatsbyImageData(
          layout: FIXED
          width: 779
          height: 779
          imgixParams: { q: 100 }
        )
      }
      image_3 {
        gatsbyImageData(
          layout: FIXED
          width: 779
          height: 779
          imgixParams: { q: 100 }
        )
      }
      icon {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
      content {
        html
      }
    }
    items {
      link {
        url
      }
      logo {
        gatsbyImageData(layout: FIXED, imgixParams: { q: 100 }, height: 52)
      }
    }
  }
`
