import styled from "styled-components"

const Image = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;

  > .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::after {
    content: "";
    display: block;
  }
`
export default Image
