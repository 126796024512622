import React from "react"

import { Container } from "@styles"

import { Title, Section } from "./style"

const BlogTitle = ({ title }) => {
  return (
    <Section>
      <Container>
        <Title as="h2">
          <span>{title}</span>
        </Title>
      </Container>
    </Section>
  )
}

export default BlogTitle
