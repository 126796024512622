import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { linkResolver } from "@utils/linkResolver"
import { P1 } from "@styles"

import { Card, Image, Content, Title, Date, ThisTag as Tag } from "./style"

const MAX_LENGTH = 120

const Article = ({ data, ...article }) => {
  const title = data.title || {}
  const intro = data.intro || {}
  const image = data.image || {}
  const categoryData = data.category || {}
  const categoryDoc = categoryData.document || {}
  const categoryDocData = categoryDoc.data || {}
  const category = categoryDocData.category_name || ""
  const date = data.date

  const isBrowser = typeof window !== "undefined"

  const formattedDate =
    isBrowser &&
    new Intl.DateTimeFormat(categoryData.lang, {
      year: "numeric",
      month: "long",
      day: "2-digit",
    }).format(new window.Date(date))

  const shortText = React.useMemo(() => {
    const textLength = intro.text ? intro.text.length : 0
    if (textLength > MAX_LENGTH)
      return `${intro.text.substring(0, MAX_LENGTH)}...`
    return intro.text
  }, [intro.text])

  return (
    <Card>
      <a href={linkResolver(article)}>
        <Image>
          <span />
          <GatsbyImage
            image={getImage(image)}
            alt={title.text}
            sizes="100vw, (min-width: 769px) 33vw, (min-width: 1200px) 400px"
          />
        </Image>
        <Content>
          <Tag type={categoryData.uid}>{category}</Tag>
          <Date>{formattedDate}</Date>
          <Title as="h3">{title.text}</Title>
          <P1>{shortText}</P1>
        </Content>
      </a>
    </Card>
  )
}

export default Article

export const query = graphql`
  fragment CategoryData on PrismicBlogCategory {
    uid
    data {
      category_name
    }
  }

  fragment BlogpostCard on PrismicBlogPost {
    id
    uid
    type
    data {
      title {
        text
      }
      intro {
        text
      }
      category {
        lang
        uid
        document {
          ... on PrismicBlogCategory {
            data {
              category_name
            }
          }
        }
      }
      image: image_in_card {
        gatsbyImageData(layout: FULL_WIDTH, imgixParams: { q: 100 })
      }
      date
    }
  }
`
