import React from "react"
import { linkResolver } from "@utils/linkResolver"
import { LangList, Selector, Icon } from "./style"

function langToString(lang) {
  switch (lang) {
    case "en-gb":
    case "en-us":
      return "English"
    case "es-es":
      return "Español"
    default:
      return "Français"
  }
}

const LangSelector = ({ languages = {} }) => {
  const [state, setState] = React.useState(false)
  const alternate_languages = languages.alternate_languages || []
  const lang = languages.lang || "fr-fr"

  const toggle = React.useCallback(
    evt => {
      evt.preventDefault()
      setState(a => !a)
    },
    [setState]
  )

  return (
    <Selector>
      <div onClick={toggle}>
        <Icon>
          <svg viewBox="0 0 10 7" fill="none">
            <path d="M1 1L5 5L9 1" stroke="#171717" strokeWidth="1.5" />
          </svg>
        </Icon>
        <span>{langToString(lang)}</span>
      </div>
      <LangList active={state}>
        <li>
          <span onClick={toggle}>{langToString(lang)}</span>
        </li>
        {alternate_languages.map(language => (
          <li key={language.id}>
            <a href={linkResolver(language)}>{langToString(language.lang)}</a>
          </li>
        ))}
      </LangList>
    </Selector>
  )
}

export default LangSelector
