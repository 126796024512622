import styled from "styled-components"

import { Grid, T3, P1, variables, mediaQueries, Container, Tag } from "@styles"

const ThisContainer = styled(Container)`
  margin-bottom: 166px;
  padding-bottom: 80px;
  @media ${mediaQueries.medium} {
    margin-bottom: 310px;
    padding-bottom: 160px;
  }
`

const ThisGrid = styled(Grid)`
  > div {
    margin-bottom: 24px;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  @media ${mediaQueries.large} {
    width: ${(100 * 10) / 12}%;
  }
`

const Card = styled.article`
  height: 100%;
  background-color: #fff;
  border-radius: 24px;
  position: relative;

  transition: 0.3s box-shadow;
  &:hover {
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
  }

  a {
    color: inherit;
  }
`

const Image = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;

  background: #f8f8f8;
  border-radius: 24px 24px 0 0;

  > span {
    display: block;
    padding-bottom: ${(100 * 206) / 322}%;
  }

  > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

const Content = styled.div`
  position: relative;
  padding: 32px;

  background: #fff;
  border-radius: 0 0 24px 24px;
`

const Title = styled(T3)`
  margin-bottom: 8px;
`

const Date = styled(P1)`
  color: ${variables.grey};
  margin-bottom: 4px;
`

const Pagination = styled.nav`
  display: block;
  text-align: center;
  margin-top: 56px;
  @media ${mediaQueries.medium} {
    margin-top: 72px;
  }
`

const Dot = styled.li`
  display: inline-block;
  vertical-align: middle;
  margin: 0 6px;

  span,
  a {
    font-size: 16px;
    line-height: 16px;

    display: block;
    width: 38px;
    height: 34px;
    padding: 8px 12px;
    border-radius: 16px;
    font-family: "stolz";

    ${({ current }) =>
      current
        ? `
      background-color: ${variables.blue};
      color: #fff;
      border: 1px solid transparent;
    `
        : `
      color: ${variables.blue};
      border: 1px solid #E5E5E5;
      background-color: #fff;
      `}
    &:hover {
      background-color: ${variables.blue};
      color: #fff;
      border: 1px solid transparent;
    }
  }
`

const ThisTag = styled(Tag)`
  position: absolute;
  z-index: 1;
  top: -14px;
  left: 32px;
`

export {
  Card,
  Image,
  ThisGrid,
  Content,
  Title,
  Date,
  ThisTag,
  Pagination,
  Dot,
  ThisContainer,
  Wrapper,
}
