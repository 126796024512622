import { graphql } from "gatsby"
import React from "react"

import { Container, T2, P0 } from "@styles"

import { Section, Wrapper, Title } from "./style"

const Content = ({ slice }) => {
  const primary = slice.primary
  const title = primary.title || ""
  const subtitle = primary.subtitle || ""
  const content = primary.content || {}
  return (
    <Section>
      <Container>
        <Wrapper>
          <T2 as="h2">{title}</T2>
          <Title as="h3">{subtitle}</Title>
          <P0
            as="div"
            dangerouslySetInnerHTML={{
              __html: content.html,
            }}
          />
        </Wrapper>
      </Container>
    </Section>
  )
}

export default Content

export const query = graphql`
  fragment AboutUsContent on PrismicAboutUsContentDefault {
    id
    slice_type
    primary {
      title
      subtitle
      content {
        html
      }
    }
  }
`
