import styled from "styled-components"
import variables from "./variables"
import mediaQueries from "./media-helper"

const Container = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 20px;

  @media ${mediaQueries.medium} {
    max-width: ${variables.siteWidth};
    width: 100%;
  }

  * {
    box-sizing: border-box;
  }
`
export default Container
