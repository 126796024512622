import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Logo from "@components/Logo"

import { SliceZone } from "@prismicio/react"

import MenuLink from "./menu-link"

import { AppLink } from "@components/CustomLink"

import { Wrapper, Container, Menu, Hmbg } from "./style"

const affixDelta = 40

const Header = ({ data = {}, backgroundColor, backgroundHeader }) => {
  const home = data.home_page || {}
  const slices = data.body2 || []

  const [bgColor, setBackground] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [affix, setState] = useState(false)

  const toggleMenu = React.useCallback(
    evt => {
      evt.preventDefault()
      setMenuOpen(open => !open)
    },
    [setMenuOpen]
  )

  const handleScroll = React.useCallback(() => {
    if (!affix && window.scrollY > affixDelta) setState(true)
    if (affix && window.scrollY <= affixDelta)
      setState(backgroundHeader ? true : false)
  }, [setState, affix])

  useEffect(() => {
    if (affix) {
      setBackground(backgroundColor)
    } else {
      setBackground(backgroundHeader ? backgroundColor : null)
    }
  }, [affix, backgroundColor])

  // Affix
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    window.addEventListener("load", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [handleScroll])

  return (
    <Wrapper bgColor={bgColor}>
      <Hmbg onClick={toggleMenu}>
        {menuOpen ? data.close_menu : data.mobile_title}
      </Hmbg>
      <Container>
        <div>
          <AppLink link={home}>
            <Logo />
          </AppLink>
        </div>

        <Menu active={menuOpen}>
          <ul>
            <SliceZone
              slices={slices}
              components={{
                menu_link: MenuLink,
              }}
            />
          </ul>
        </Menu>
      </Container>
    </Wrapper>
  )
}

export const query = graphql`
  fragment HeaderFragment on PrismicHeader {
    data {
      link_logo {
        url
        link_type
      }
      logo {
        alt
        url
      }
    }
  }
`

export default Header
