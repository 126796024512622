import styled from "styled-components"
import { T2, mediaQueries } from "@styles"

const Section = styled.section`
  @media ${mediaQueries.preLarge} {
    padding-top: 48px;
  }
`

const Title = styled(T2)`
  margin: 0 auto;
  @media ${mediaQueries.medium} {
    text-align: center;
    max-width: 800px;
  }

  span {
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 4px;
      left: -12px;
      width: 48px;
      height: 48px;

      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjYiIGhlaWdodD0iNjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yOS4yODQgNTAuMjI4Yy0xLjExIDEuMDQ4LTIuMjggMS45OTEtMy4yNTUgMy4xMDMtMi4zNzcgMi43MTEtMi4yOTMgNi42OS4wOTUgOS4zMTIgMi4zNiAyLjU5MyA2LjU2OCAzLjIzNiA5LjI5NS45NDQgOS4zNzMtNy44ODIgMTguNjM3LTE1Ljg5MiAyNy44NjEtMjMuOTQ1IDIuMjE0LTEuOTMzIDIuNjk0LTUuNTc2IDEuNDUtNy45MS0xLjYyLTMuMDM3LTQuNTM0LTQuNDgyLTguMDMtMy44MjMtMi40NjQuNDY0LTQuODkzIDEuMTEtNy41MDkgMS43MTQuMTYzLTEuNzQ1IDEuNDMtMi4xMzQgMi4xODQtMi45MjUgMi4zNzEtMi40OTIgMy4wMzctNS44NzggMS42MTctOC40NzgtMS43MDYtMy4xMjUtNC4zNDItNC4zMi04LjEwNS0zLjYxLTEuMjU2LjIzNi0yLjQ3OC42NTItMy43MzQuODg2LTEuMjI3LjIyOC0yLjM4My45MTktMy45OTguNTkyLjUxNy0xLjMxMyAxLjY5NS0xLjg3NCAyLjUyMy0yLjcwNSAyLjk2LTIuOTcyIDMuMTkyLTYuOTkuNTUzLTEwLjAwMS0yLjUxMy0yLjg2OC02LjYzOS0zLjM4Ni05Ljc2LS43NjlDMjEuNDE3IDEwLjIxIDEyLjQ2IDE3LjkyNSAzLjU2MyAyNS43MDcuNjcgMjguMjM4LjIzIDMxLjY2IDEuOTMgMzQuNjQ5YzEuNjc1IDIuOTQ0IDQuNzQ3IDQuMDk0IDguNTggMy4yMiAxLjQ1LS4zMyAyLjg5OS0uNjY4IDQuMzU4LS45NDcuMjc4LS4wNTMuNjA2LjE0OC45MTguMjM0LS4wNyAxLjEzNS0uNzA2IDEuOTItMS4zNjQgMi43NDgtMi4wNTMgMi41NzctMi40ODYgNS4zNzgtLjcyIDguMjY2IDEuODA5IDIuOTU2IDQuNTE0IDMuOTA1IDguMzM1IDMuMDQgMS44NzMtLjQyNiAzLjcyNy0uOTMyIDUuNTk2LTEuMzc4LjU3MS0uMTM2IDEuMjMtLjQ2MyAxLjY1LjM5NnoiIGZpbGw9IiM3QkU4QzMiLz48L3N2Zz4=);
      background-repeat: no-repeat;
      background-size: 100%;

      @media ${mediaQueries.medium} {
        top: 12px;
        left: -24px;
        width: 66px;
        height: 66px;
      }
    }
  }
`
export { Title, Section }
