import React, { useRef, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { addListMember } from "../../config/mailchimp"
import { Title } from "./style"
const Newsletter = ({ slice }) => {
  // Create a reference to the input so we can fetch/clear it's value.
  const inputEl = useRef(null)
  // Hold a message in state to handle the response from our API.
  const [message, setMessage] = useState("")
  const [loader, setLoader] = useState(false)

  const subscribe = async e => {
    e.preventDefault()
    setLoader(true)

    let email = inputEl.current.value

    addListMember(email)

    // Clear the input value and show a success message.
    inputEl.current.value = ""
    setMessage("Inscription confirmée")
    setLoader(false)
  }
  return (
    <div>
      <Title>{slice.primary.newsletter_title}</Title>
      <Form onSubmit={subscribe}>
        <Input
          id="email-input"
          name="email"
          placeholder={slice.primary.newsletter_placeholder ?? "_"}
          ref={inputEl}
          required
          type="email"
        />
        <SubmitButton type="submit">
          {loader
            ? slice.primary.newsletter_loading_message
            : slice.primary.newsletter_submit_button}
        </SubmitButton>
        <ValidationMessage>{message ? message : ""}</ValidationMessage>
      </Form>
    </div>
  )
}

export default Newsletter

export const query = graphql`
  fragment Newsletter on PrismicNavigationDataBody1Newsletter {
    id
    slice_type
    primary {
      newsletter_title
      newsletter_success_message
      newsletter_loading_message
      newsletter_submit_button
      newsletter_placeholder
    }
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const Input = styled.input`
  height: 48px;
  border: 1px solid #e5e5e5;
  border-radius: 14px;
  margin-right: 14px;
  padding-left: 10px;
  width: 100%;
`

const SubmitButton = styled.button`
  background-color: #2d81ff;
  height: 48px;
  border-radius: 14px;
  margin-top: 16px;
  border-width: 0;
  color: #fff;
  min-width: 130px;
  padding: 0 25px;
  font-family: "stolz";
  @media all and (max-width: 768px) {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
`

const ValidationMessage = styled.div`
  color: #68daa0;
  font-weight: 700;
  font-size: 0.9rem;
  margin-top: 1rem;
`
