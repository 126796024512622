import styled from "styled-components"
import variables from "./variables"
import mediaQueries from "./media-helper"

const Filters = styled.ul`
  @media ${mediaQueries.medium} {
    text-align: center;
  }

  > li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 12px 24px;

    input {
      display: block;
      height: 0;
      width: 0;
      overflow: hidden;
      margin: 0;
      appearance: none;
    }

    label {
      cursor: pointer;
      display: block;
      height: 40px;
      line-height: 38px;

      font-size: 16px;
      font-family: "stolz";
      font-weight: 400;
      color: ${variables.blue};

      padding: 0 20px;
      border-radius: 20px;
      border: 1px solid ${props => props.theme.colors.grey25};
      background-color: #fff;
      transition: 0.3s transform;
    }

    input:checked + label,
    label:hover {
      background-color: ${variables.blue};
      color: #fff;
      border-color: rgba(0, 0, 0, 0);
    }

    label:hover {
      transform: scale(0.95);
    }
  }
`
export default Filters
