import React from "react"
import Default from "./default"
import New from "./new"
import Simple from "./simple"

const SolutionFeatures = ({ slice }) => {
  switch (slice.variation) {
    case "simple_features":
      return <Simple {...slice} />
    case "new_features":
      return <New {...slice} />
    default:
      return <Default {...slice} />
  }
}

export default SolutionFeatures
