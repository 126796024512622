import { graphql } from "gatsby"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Store from "./store"

import { T6 } from "@styles"

import {
  Section,
  Block,
  Image,
  Wrapper,
  StoresLogo,
  ThisContainer as Container,
} from "./style"

const Stores = ({ slice }) => {
  const primary = slice.primary
  const items = slice.items
  const title = primary.title || ""
  const image = getImage(primary.image)

  return (
    <Section>
      <Container>
        <Block>
          <Image>
            <GatsbyImage image={image} alt={title} sizes={`${image.width}px`} />
          </Image>
          <Wrapper>
            <T6 as="h2">{title}</T6>

            <StoresLogo>
              {items.map((store, key) => (
                <Store {...store} key={`store-${key}`} />
              ))}
            </StoresLogo>
          </Wrapper>
        </Block>
      </Container>
    </Section>
  )
}

export default Stores

export const query = graphql`
  fragment AboutUsStores on PrismicAboutUsAppStoreDefault {
    id
    slice_type
    primary {
      title
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
    }
    items {
      logo {
        gatsbyImageData(layout: FIXED, imgixParams: { q: 100 }, height: 52)
      }
      link {
        url
        target
      }
    }
  }
`
