import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useLottieAnimation from "@utils/hooks/useLottieAnimation"

import { T6, ButtonBlueBig } from "@styles"
import { Image, Premium, Shadow, Gradient } from "./style"

const Default = props => {
  const primary = props.primary || {}
  const link = primary.link || {}
  const json = primary.json || {}
  const image = getImage(primary.image)

  const title = primary.structTitle || primary.title

  const animation = useLottieAnimation(json)
  return (
    <Shadow>
      <Gradient>
        <div>
          <Premium ref={animation} />
          <T6 as="h2" mb={3} dangerouslySetInnerHTML={{ __html: title.html }} />

          <ButtonBlueBig as="a" href={link.url}>
            {primary.button}
          </ButtonBlueBig>
        </div>

        <Image>
          <GatsbyImage
            image={image}
            alt={primary.title}
            sizes={`60vw, (min-width: 1200px) 658px`}
          />
        </Image>
      </Gradient>
    </Shadow>
  )
}

export default Default

export const query = graphql`
  fragment HomeAppPremium on PrismicHomeAppPremiumDefault {
    id
    primary {
      button
      gradient
      structTitle: title {
        html
      }
      link {
        url
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
      json {
        url
      }
    }
    slice_type
    variation
  }
`
