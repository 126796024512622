import { graphql } from "gatsby"
import React from "react"
import Block from "./block"

import { Grid } from "@styles"

import { Slice, ThisContainer } from "./style"

const CtaBlocks = ({ slice }) => {
  const items = slice.items
  return (
    <Slice>
      <ThisContainer>
        <Grid>
          {items.map((item, key) => (
            <Block {...item} key={`cta-block-${key}`} />
          ))}
        </Grid>
      </ThisContainer>
    </Slice>
  )
}

export default CtaBlocks

export const query = graphql`
  fragment CoolCtaSlice on PrismicBlogDataBodyCoolCta {
    id
    slice_type
    items {
      button
      item_title
      image {
        gatsbyImageData(layout: CONSTRAINED)
      }
      link {
        url
      }
      link_appstore {
        url
      }
      link_playstore {
        url
      }
      logo_appstore {
        url
        gatsbyImageData(
          layout: CONSTRAINED
          imgixParams: { q: 100 }
          height: 52
        )
      }
      logo_playstore {
        url
        gatsbyImageData(
          layout: CONSTRAINED
          imgixParams: { q: 100 }
          height: 52
        )
      }
    }
  }
`
