import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Image } from "./style"
import { GridCentered, GridItem, T6, P00, ButtonBlue } from "@styles"

const Right = props => {
  const primary = props.primary || {}
  const title = primary.title || ""
  const content = primary.structContent || primary.content || {}
  const image = getImage(primary.image)
  const link = primary.link || {}

  return (
    <GridCentered>
      <GridItem col={{ md: 6, lg: 6 }}>
        <T6 as="h2" mb={3}>
          {title}
        </T6>
        <P00
          as="div"
          mb={6}
          dangerouslySetInnerHTML={{ __html: content.html }}
        />
        <ButtonBlue as="a" href={link.url}>
          <span>{primary.button}</span>
        </ButtonBlue>
      </GridItem>
      <GridItem col={{ md: 5, lg: 5 }} push={{ md: 1, lg: 1 }}>
        <Image>
          <GatsbyImage
            image={image}
            sizes={`50vw, (min-width: 1200px) 600px`}
            alt={title}
          />
        </Image>
      </GridItem>
    </GridCentered>
  )
}

export default Right

export const query = graphql`
  fragment InsurancesSectionRight on PrismicInsurancesSectionRight {
    id
    variation
    slice_type
    version
    primary {
      title
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
      structContent: content {
        html
      }
      button
      link {
        url
      }
    }
  }
`
