import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"
import { SliceZone } from "@prismicio/react"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { Content, Header, Stores, Team } from "./about-us-components"

const AboutUs = ({ data }) => {
  const langParams = data.langParams || {}
  const navigation = data.prismicNavigation || {}
  const doc = data.about || {}
  const about = doc.data || {}
  const slices = about.body || []

  return (
    <Layout navigation={navigation} languages={langParams}>
      <Seo
        title={about.meta_title}
        description={about.meta_description}
        openGraphImg={about.open_graph && about.open_graph.url}
      />

      <SliceZone
        slices={slices}
        components={{
          about_us_content: Content,
          about_us_header: Header,
          about_us_app_store: Stores,
          about_us_team: Team,
        }}
      />
    </Layout>
  )
}

export default withPrismicPreview(AboutUs)

export const query = graphql`
  query ($lang: String) {
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
    langParams: prismicAboutUs(lang: { eq: $lang }) {
      uid
      lang
      url
      type
      alternate_languages {
        uid
        type
        lang
      }
    }
    about: prismicAboutUs(lang: { eq: $lang }) {
      uid
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      data {
        meta_description
        meta_title
        open_graph {
          url
        }
        body {
          ... on PrismicAboutUsTeamDefault {
            ...AboutUsTeam
          }
          ... on PrismicAboutUsContentDefault {
            ...AboutUsContent
          }
          ... on PrismicAboutUsHeaderDefault {
            ...AboutUsHeader
          }
          ... on PrismicAboutUsAppStoreDefault {
            ...AboutUsStores
          }
        }
      }
    }
  }
`
