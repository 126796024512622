import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"

import { T6, P00 } from "../../../styles"

import { Section, Block } from "./style"

const ContentBackground = ({ slice }) => {
  const primary = slice.primary
  const title = primary.title || ""
  const content = primary.content_background.richText || ""
  const image = primary.icon_content

  return (
    <Section>
      <Block>
        <img src={image.url} alt={image.alt} style={{ marginTop: -100 }} />
        <T6 max="735" mb="3" mt="5" as="h2" styl>
          {title}
        </T6>
        <div style={{ maxWidth: 840, margin: "0px auto" }}>
          <PrismicRichText
            field={content}
            components={{
              paragraph: ({ children }) => <P00>{children}</P00>,
            }}
          />
        </div>
      </Block>
    </Section>
  )
}

export default ContentBackground

export const query = graphql`
  fragment ContentBackground on PrismicContentBackgroundDefault {
    id
    slice_type
    primary {
      title
      content_background {
        richText
        text
      }
      icon_content {
        url
        alt
      }
    }
  }
`
