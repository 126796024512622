import styled from "styled-components"

import { variables, mediaQueries } from "@styles"

const Blog = styled.div`
  padding-bottom: 120px;
`

const Background = styled.div`
  position: absolute;
  z-index: -1;
  top: calc(${206 / 322} * (100vw - 40px));
  left: 0;
  right: 0;

  background-color: ${variables.lightBlue};
  border-radius: 54px;

  height: 100%;

  @media ${mediaQueries.medium} {
    top: calc(${206 / 322} * (100vw - 48px) / 3);
  }

  @media ${mediaQueries.large} {
    top: calc(${206 / 322} * (${(100 * 10) / 12}vw - 48px) / 3);
  }

  @media ${mediaQueries.siteWidthWithSpace} {
    top: 206.56px;
  }
`

const BlogPosts = styled.div`
  padding-top: 0;
`

export { Background, BlogPosts, Blog }
