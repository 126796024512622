import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"

import { ButtonBlue } from "@styles"

import { Block as CtaBlock, Title, Image, ThisGridItem, Stores } from "./style"

const Block = ({
  item_title,
  button,
  image,
  logo_appstore,
  logo_playstore,
  link,
  link_appstore,
  link_playstore,
}) => {
  const imageData = getImage(image)
  const logoPlayStore = logo_playstore.url ? getImage(logo_playstore) : null
  const logoAppStore = logo_appstore.url ? getImage(logo_appstore) : null

  return (
    <ThisGridItem col={{ md: 6, lg: 6 }}>
      <CtaBlock>
        <Image>
          <GatsbyImage
            image={imageData}
            alt={item_title}
            sizes={`${imageData.width}px`}
          />
        </Image>
        <Title as="h3">{item_title}</Title>

        {button && (
          <ButtonBlue as="a" href={link.url}>
            <span>{button}</span>
          </ButtonBlue>
        )}

        {(logoPlayStore || logoAppStore) && (
          <Stores>
            {logoAppStore && (
              <GatsbyLink to={link_appstore.url || ""}>
                <GatsbyImage
                  image={logoAppStore}
                  alt={"app store"}
                  sizes={`${logoAppStore.width}px`}
                />
              </GatsbyLink>
            )}
            {logoPlayStore && (
              <GatsbyLink to={link_playstore.url || ""}>
                <GatsbyImage
                  image={logoPlayStore}
                  alt={"play store"}
                  sizes={`${logoPlayStore.width}px`}
                />
              </GatsbyLink>
            )}
          </Stores>
        )}
      </CtaBlock>
    </ThisGridItem>
  )
}

export default Block
