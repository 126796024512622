import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Stores from "@components/stores"

import { P0, T6 } from "@styles"
import { Section, Block, Wrapper, Image } from "./style"

const HomeApp = ({ slice }) => {
  const primary = slice.primary || {}
  const items = slice.items || []

  const content = primary.content || {}
  const image = getImage(primary.image)

  return (
    <Section>
      <Wrapper>
        <Block>
          <div>
            <svg width="137" height="184" viewBox="0 0 137 185">
              <path
                d="M68.258 37.243c10.284 0 18.621-8.337 18.621-18.622C86.879 8.337 78.542 0 68.258 0 57.973 0 49.636 8.337 49.636 18.621c0 10.285 8.337 18.622 18.622 18.622z"
                fill="#FF546D"
              />
              <path
                d="M1.919 62.986c-.375-1.421-.735-2.853-.977-4.302-2.025-12.092-1.703-12.38 10.398-12.37 38.838.044 77.679.281 116.511-.182 8.53-.1 9.981 2.96 8.728 10.286-3.868 22.624-15.556 39.69-35.581 50.889-4.088 2.768-7.89 4.844-13.408 7.504 13.828 0 27.673.369 41.481-.156 6.909-.262 8.459 1.923 7.786 8.551-3.417 33.653-33.541 60.612-68.215 60.59C33.836 183.77 3.89 157.043.391 123.297c-.69-6.625 1.012-8.847 7.855-8.607 13.732.481 27.491.144 41.238.144C25.04 104.631 8.634 88.378 1.92 62.986z"
                fill="#FFDC6D"
              />
            </svg>
            <T6 as="h2" mb={2} mbmd={4}>
              {primary.title}
            </T6>

            <P0
              as="div"
              dangerouslySetInnerHTML={{ __html: content.html }}
              mb={4}
              mbmd={6}
            />

            <Stores stores={items} />
          </div>
          <div>
            <Image>
              <GatsbyImage
                image={image}
                alt={primary.title}
                sizes={`30vw, (min-width: 1200px) 332px`}
              />
            </Image>
          </div>
        </Block>
      </Wrapper>
    </Section>
  )
}

export default HomeApp

export const query = graphql`
  fragment HomeApp on PrismicHomeAppDefault {
    id
    slice_type
    variation
    items {
      link {
        url
      }
      logo {
        gatsbyImageData(layout: FIXED, imgixParams: { q: 100 }, height: 52)
      }
    }
    primary {
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
      title
      content {
        html
      }
    }
  }
`
