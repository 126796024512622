import styled, { keyframes } from "styled-components"
import { mediaQueries, P0 } from "@styles"

const fadeIn = keyframes`
    0% {opacity: 0;}
    20% {opacity: 1;}
    33% {opacity: 1;}
    44% {opacity: 0;}
    100% {opacity: 0;}

`

const Content = styled(P0)`
  margin: 0 0 56px;
`

const Icon = styled.div`
  margin: 0 0 16px;
  max-width: 135px;
  @media ${mediaQueries.medium} {
    max-width: 208px;
  }
`

const Header = styled.header`
  position: relative;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAxMyIgaGVpZ2h0PSIxMDgxIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xOTQ0LjA3IDBMMCAxMzUuOTRsNjYuODI1IDY1Mi40MDhjMTcuNzQzIDE3My4yMjQgMTcyLjk0NCAzMDMuODAxIDM0Ni42NSAyOTEuNjYxbDEzMTUuMDE1LTkxLjk1OGMxNzMuNzEtMTIuMTQ3IDMwMC4xNC0xNjIuNDIgMjgyLjQtMzM1LjY0NUwxOTQ0LjA3IDB6IiBmaWxsPSIjRkVGOEVDIi8+PC9zdmc+);
  background-position: bottom -80px right;
  background-size: 2003px;
  width: 100%;
  overflow: hidden;

  padding: 88px 0 64px;
  @media ${mediaQueries.medium} {
    padding: 157px 0 200px;
    background-position: top -260px center;
  }

  h1 {
    margin-bottom: 24px;
  }

  p + p {
    margin-top: 24px;
  }

  strong {
    font-weight: 700;
  }
`

const Image = styled.div`
  display: none;

  @media ${mediaQueries.large} {
    display: block;

    .img {
      position: absolute;
      right: 0;
      top: 0;
      left: 65%;
      bottom: 0;
      width: 779px;
      height: 779px;

      clip-path: url(#home-header);
    }

    .img-1 {
      animation: ${fadeIn} 15s -50s infinite;
      animation-timing-function: ease-in-out;
    }

    .img-2 {
      animation: ${fadeIn} 15s -10s infinite;
      animation-timing-function: ease-in-out;
    }

    .img-3 {
      animation: ${fadeIn} 15s -15s infinite;
      animation-timing-function: ease-in-out;
    }
  }
`

const Wrapper = styled.div`
  @media ${mediaQueries.large} {
    width: 62%;
  }
`

export { Header, Image, Wrapper, Content, Icon }
