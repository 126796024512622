import * as React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import { SliceZone } from "@prismicio/react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import { MentionDm } from "@slices"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import {
  HomeHeader,
  HomeFeatures,
  HomeCompaniesSlider,
  HomeApp,
  HomeAppScreens,
  HomeAppPremium,
  HomeAppDownload,
  HomeArticles,
  HomeTestimonialsSlider,
  Video,
} from "./home-components"

import { InsurancesSection } from "../templates/insurances-components"

const BackgroundLayout = styled.div`
  section:nth-child(4),
  section:nth-child(5),
  section:nth-child(6),
  section:nth-child(7) {
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      z-index: -1;
      position: absolute;
      width: 1400px;
      max-width: calc(100% - 20px);

      left: 50%;
      transform: translateX(-50%);
    }
  }

  section:nth-child(6) {
    z-index: 10;
  }

  section:nth-child(4),
  section:nth-child(5) {
    &::before {
      background-color: ${props => props.theme.colors.redLight};
    }
  }

  section:nth-child(5) {
    &::before {
      border-radius: 0;
    }
  }

  section:nth-child(7)::before {
    background-color: ${props => props.theme.colors.primaryLight};
    border-radius: 0 0 60px 60px;
  }

  section:nth-child(5)::before,
  section:nth-child(6)::before,
  section:nth-child(7)::before {
    height: 100%;
    top: 0;
  }

  section:nth-child(6)::before {
    background-image: linear-gradient(
      180deg,
      ${props => props.theme.colors.redLight} 50%,
      ${props => props.theme.colors.primaryLight} 50%
    );
  }

  section:nth-child(4)::before {
    top: 320px;
    height: calc(100% - 320px);
    border-radius: 60px 60px 0 0;
  }
`

const Home = ({ data = {} }) => {
  const langParams = data.langParams || {}
  const navigation = data.prismicNavigation || {}
  const doc = data.prismicHome || {}
  const docData = doc.data || {}
  const slices = docData.body || []

  return (
    <Layout navigation={navigation} languages={langParams}>
      <Seo
        title={docData.meta_title}
        description={docData.meta_description}
        openGraphImg={docData.open_graph && docData.open_graph.url}
      />
      <BackgroundLayout>
        <SliceZone
          slices={slices}
          components={{
            home_header: HomeHeader,
            home_features: HomeFeatures,
            home_companies_slider: HomeCompaniesSlider,
            home_app: HomeApp,
            home_app_screens: HomeAppScreens,
            home_app_premium: HomeAppPremium,
            home_app_download: HomeAppDownload,
            insurances_section: InsurancesSection,
            home_articles: HomeArticles,
            home_testimonials_slider: HomeTestimonialsSlider,
            mention_dm: MentionDm,
            video: Video,
          }}
        />
      </BackgroundLayout>
    </Layout>
  )
}

export default withPrismicPreview(Home)

export const query = graphql`
  query Homepage($lang: String) {
    langParams: prismicHome(lang: { eq: $lang }) {
      lang
      url
      type
      alternate_languages {
        uid
        type
        lang
      }
    }
    prismicHome(lang: { eq: $lang }) {
      lang
      url
      type
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      data {
        meta_description
        meta_title
        open_graph {
          url
        }
        body {
          ... on PrismicHomeHeaderDefault {
            ...HomeHeader
          }
          ... on PrismicHomeFeaturesDefault {
            ...HomeFeatures
          }
          ... on PrismicHomeCompaniesSliderDefault {
            ...HomeCompaniesSlider
          }
          ... on PrismicHomeAppDefault {
            ...HomeApp
          }
          ... on PrismicHomeAppScreensDefault {
            ...HomeAppScreens
          }
          ... on PrismicHomeAppPremiumDefault {
            ...HomeAppPremium
          }
          ... on PrismicHomeAppDownloadDefault {
            ...HomeAppDownload
          }
          ... on PrismicInsurancesSectionLeft {
            ...InsurancesSectionLeft
          }
          ... on PrismicInsurancesSectionRight {
            ...InsurancesSectionRight
          }
          ... on PrismicHomeArticlesDefault {
            ...HomeArticles
          }
          ... on PrismicHomeTestimonialsSliderDefault {
            ...HomeTestimonialsSlider
          }
          ... on PrismicMentionDmDefault {
            ...MentionDm
          }
          ... on PrismicVideoDefault {
            ...Video
          }
        }
      }
    }
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
  }
`
