import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Container, T2 } from "@styles"
import { Section, Block, Grid, Content } from "./style"

const Image = ({ image, alt }) => {
  const imageData = getImage(image)

  return (
    <Block>
      <GatsbyImage
        image={imageData}
        alt={alt}
        sizes="(min-width: 769px) 520px, 250px"
      />
    </Block>
  )
}
const SolutionHeader = ({ slice }) => {
  const items = slice.items
  const primary = slice.primary
  return (
    <Section>
      <div>
        <Content>
          <Container>
            <T2 as="h1" mb={6} mbmd={10} center>
              {primary.title}
            </T2>

            <Grid>
              {items.map((image, key) => (
                <Image key={`image${key}`} alt={primary.title} {...image} />
              ))}
            </Grid>
          </Container>
        </Content>
      </div>
      <svg width="0" height="0">
        <defs>
          <clipPath
            clipPathUnits="objectBoundingBox"
            transform="scale(.0005013 .0007645)"
            id="solution-header"
          >
            <path
              d="M1944.05 0L-.018 164.529l72.363 855.04c14.685 173.51 167.247 302.264 340.758 287.58l1315.727-111.352c173.51-14.684 302.27-167.246 287.58-340.757L1944.05 0z"
              fill="#EAF2FF"
              fillRule="nonzero"
            />
          </clipPath>
          <clipPath
            clipPathUnits="objectBoundingBox"
            transform="scale(.002667 .001718)"
            id="solution-header-mobile"
          >
            <path
              d="M369.328-63L-479 8.795l31.116 367.668c6.662 78.721 75.879 137.136 154.601 130.474l563.253-47.669c78.721-6.662 137.136-75.879 130.474-154.6L369.328-63z"
              fill="#EAF2FF"
              fillRule="nonzero"
            />
          </clipPath>
        </defs>
      </svg>
    </Section>
  )
}

export default SolutionHeader

export const query = graphql`
  fragment SolutionHeader on PrismicHiwHeaderDefault {
    id
    slice_type
    variation
    primary {
      title
    }
    items {
      image {
        gatsbyImageData(layout: CONSTRAINED, imgixParams: { q: 100 })
      }
    }
  }
`
