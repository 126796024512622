/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { linkResolver } from "../utils/linkResolver"
import * as prismicH from "@prismicio/helpers"

const SEO = ({
  title,
  description,
  openGraphImg,
  article,
  lang,
  altLangs,
  isHome,
  noIndex,
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const { defaultTitle, defaultDescription, siteUrl, defaultImage } =
    site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: openGraphImg || `${siteUrl}${defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: lang.length > 2 ? lang.substr(0, 2) : lang,
      }}
      title={seo.title}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <link rel="canonical" href={seo.url} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />

      {noIndex && <meta name="robots" content="noindex, nofollow" />}

      {!noIndex && <meta name="apple-itunes-app" content="app-id=1608397784" />}

      {altLangs &&
        altLangs.map(altLang => {
          const url = prismicH.asLink(
            { _linkType: "Link.document", ...altLang },
            linkResolver
          )
          const lang = altLang.lang.substr(0, 2)
          return (
            <link
              rel="alternate"
              href={`${siteUrl}${url}`}
              hrefLang={lang}
              key={altLang.id}
            />
          )
        })}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {isHome && (
        <link rel="alternate" href={`${siteUrl}`} hrefLang="x-default" />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      <meta name="theme-color" content={noIndex ? `#FFF2F4` : `#FEFAF1`} />

      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: "fr",
  title: null,
  description: null,
  image: null,
  article: false,
  isHome: false,
  noIndex: false,
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  isHome: PropTypes.bool,
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        defaultImage: image
      }
    }
  }
`
