import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Title, MenuItem, Stores } from "./style"

const Store = ({ logo, link }) => {
  const image = getImage(logo)

  return (
    <li>
      <a href={link.url} target="_blank" rel="noreferrer">
        <GatsbyImage image={image} alt="app store" sizes={`${image.width}px`} />
      </a>
    </li>
  )
}

const AppStores = ({ slice }) => {
  const primary = slice.primary
  const items = slice.items
  return (
    <MenuItem>
      <Title>{primary.name}</Title>
      <Stores>
        {items.map((store, key) => (
          <Store key={`store${key}`} {...store} />
        ))}
      </Stores>
    </MenuItem>
  )
}

export default AppStores

export const query = graphql`
  fragment AppStores on PrismicNavigationDataBody1AppStores {
    id
    slice_type
    primary {
      name
    }
    items {
      link {
        url
      }
      logo {
        gatsbyImageData(layout: FIXED, height: 40)
      }
    }
  }
`
